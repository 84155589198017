import { FORM_TYPES } from "../../ActionOthers/actionOther.constants";

import {
  RestrictionType,
  SubtractionResponse,
} from "../../../../generated/api/agroevidence";
import { isParcelSown } from "../../ActionOthers/helpers/others.helpers";

import { InitialParcelToAdd } from "../../ActionOthers/actionOther.types";

export const getTotalArea = (
  parcels: InitialParcelToAdd[],
  formType?: string,
  isExisting?: boolean,
) => {
  let parcelsArea = 0;
  const isHarvestForm = formType === FORM_TYPES.HARVEST;
  const isMowingForm = formType === FORM_TYPES.MOWING;
  const mustBeSown = isHarvestForm || isMowingForm;

  if (parcels && parcels.length) {
    parcels.forEach((parcel: InitialParcelToAdd) => {
      const isNewNotSownParcel = !isParcelSown(parcel) && !isExisting;
      // if Harvest form and parcel is not sown then not add Area to Total activity area
      if (mustBeSown && isNewNotSownParcel) return;
      const areaToAdd = parcel.actionParcelTotalArea;
      parcelsArea += areaToAdd;
    });
  }
  return parcelsArea;
};

export const initialWaterProtectionZones = (
  restrictionType: RestrictionType,
): SubtractionResponse[] => [
  {
    type: restrictionType,
    value: 1,
    area: 0,
    isMaterial: false,
    isUsed: false,
  },
  {
    type: restrictionType,
    value: 2,
    area: 0,
    isMaterial: false,
    isUsed: false,
  },
  {
    type: restrictionType,
    value: 3,
    area: 0,
    isMaterial: false,
    isUsed: false,
  },
];
