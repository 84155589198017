import queryString from "query-string";
import { RSAA } from "redux-api-middleware";

import * as types from "./countries.constants";
import {
  API_MODULES as modules,
  API_METHODS as methods,
} from "../../api.constants";

export const getCountriesApi = (search?: string) => {
  const p = {
    search,
  };
  return {
    [RSAA]: {
      endpoint: `countries/supported?${queryString.stringify(p)}&`,
      method: methods.GET,
      module: modules.AGROEVIDENCE,
      types: [
        types.GET_COUNTRIES_REQUEST,
        types.GET_COUNTRIES_SUCCESS,
        types.GET_COUNTRIES_ERROR,
      ],
    },
  };
};

export const resetCountriesApi = () => ({
  type: types.RESET_COUNTRIES,
});
