import React, { useEffect, useState } from "react";
import TableMUI from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { useQuery } from "@tanstack/react-query";
import { FormattedMessage } from "react-intl";
import { ConnectedProps, connect } from "react-redux";
import { bindActionCreators } from "redux";
import { makeStyles } from "@mui/styles";
import { setFocusedRow } from "../../../../core/precision/actions/precision.actions";

import CfTableHead from "../../../../common/components/CfTableHead/CfTableHead";
import { InternalCropTo, SeasonTo } from "../../../../generated/api/satellite";
import CfTableBodyEmpty from "../../../../shared/components/tables/CfTableBodyEmpty/CfTableBodyEmpty";
import CfTableBodyLoader from "../../../../shared/components/tables/CfTableBodyLoader/CfTableBodyLoader";
import { getColDesc } from "../../../../shared/misc/helper";
import { Thunk } from "../../../../types";

import { useTableFooter } from "../hooks/useTableFooter";
import { useTableHead } from "../hooks/useTableHead";
import TableRow from "./TableRow";
import { satelliteServicesKey, servicesQuery } from "../PrecisionServices.api";

import { ParcelsState } from "../../../../reducers/parcels.reducer.types";
import { SelectObject } from "../../../../shared/components/form/FormSelect/types";
import { AdvancedFilterType } from "../../../types";
import { TableFooter } from "../../../components/TableFooter";
import { CfTableWrapperV2 } from "../../../../shared/components/tables/CfTableWrapper/CfTableWrapperV2";

const columns = {
  name: getColDesc(
    true,
    <FormattedMessage id="PrecisionServices.columns.parcel" />,
    {
      paddingLeft: 21,
      background: "#fafafa",
    },
  ),
  blockNr: getColDesc(
    false,
    <FormattedMessage id="PrecisionServices.columns.lpisCode" />,
    {
      background: "#fafafa",
    },
  ),
  area: getColDesc(
    true,
    <span>
      <FormattedMessage id="PrecisionServices.columns.area" />
    </span>,
    {
      background: "#fafafa",
    },
  ),
  crop: getColDesc(
    true,
    <span>
      <FormattedMessage id="common.crop" />
    </span>,
    {
      background: "#fafafa",
    },
  ),
  yield: getColDesc(
    true,
    <span style={{ width: "100px" }}>
      <FormattedMessage id="PrecisionServices.columns.yield" />
    </span>,
    {
      background: "#fafafa",
    },
  ),
  services: getColDesc(
    false,
    <span>
      <FormattedMessage id="PrecisionServices.columns.activeServices" />
    </span>,
    {
      background: "#fafafa",
    },
  ),
  actions: getColDesc(
    false,
    <span>
      <FormattedMessage id="PrecisionServices.columns.actions" />
    </span>,
    {
      background: "#fafafa",
    },
  ),
};

type ReduxProps = ConnectedProps<typeof connector>;
type OwnProps = {
  farmId: string;
  setTotalArea: (val: number) => void;
  textFilter: string;
  season: SeasonTo;
  advancedFilter: AdvancedFilterType;
  cropsOptions: SelectObject<InternalCropTo>[];
};
type Props = ReduxProps & OwnProps;

export interface PrecisionTableProps {
  count: number;
  farmId: string;
  isFetching: boolean;
  langId: string;
  order: string;
  orderBy: string;
  page: number;
  rowsPerPage: number;
}

const Table = ({
  advancedFilter,
  farmId,
  season,
  setFocusedRow,
  setTotalArea,
  textFilter,
  cropsOptions,
}: Props) => {
  const classes = useStyles();
  const { onSort, order, orderBy } = useTableHead();
  const { setPage, handleRowsPerPageChange, page, rowsPerPage } =
    useTableFooter();

  const crops = advancedFilter?.crops?.map((c) => c.id);
  const servicesParams = {
    farmIds: farmId,
    search: textFilter,
    crops,
    "sort-dir": order,
    "sort-col": orderBy,
    "per-page": rowsPerPage,
    page: page + 1,
  };

  const servicesQueryKey = [
    ...satelliteServicesKey,
    ...Object.values(servicesParams),
    season.id,
  ];

  const { data: parcelsData, isPending } = useQuery(
    servicesQuery(season.id, servicesParams),
  );

  const tableData = parcelsData?.data?.length
    ? parcelsData?.data[0].parcels
    : [];
  const count = parcelsData?.count ?? 0;

  useEffect(() => {
    const totalArea = parcelsData?.data?.[0]?.totalAreaHa ?? 0;
    setTotalArea(totalArea);
  }, [parcelsData, setTotalArea]);

  useEffect(
    () => () => {
      setFocusedRow(undefined);
    },
    [setFocusedRow],
  );

  return (
    <CfTableWrapperV2>
      <TableMUI className={classes.table}>
        <CfTableHead
          columns={columns}
          items={tableData}
          onSort={onSort}
          order={order}
          orderBy={orderBy}
        />
        {isPending ? (
          <CfTableBodyLoader columns={columns} />
        ) : (
          <>
            {tableData.length ? (
              <TableBody className={classes.tableBody}>
                {tableData.map((parcel) => (
                  <TableRow
                    servicesQueryKey={servicesQueryKey}
                    cropsOptions={cropsOptions}
                    farmId={farmId}
                    isInit={!isPending && count > 0}
                    key={parcel.parcelId}
                    parcel={parcel}
                    season={season}
                  />
                ))}
              </TableBody>
            ) : (
              <CfTableBodyEmpty
                style={{ backgroundColor: "#FFFFFF" }}
                colLength={Object.keys(columns).length + 1}
              />
            )}
          </>
        )}
      </TableMUI>
      <div className={classes.footerContainer}>
        <TableFooter
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          setPage={setPage}
          setRowsPerPage={handleRowsPerPageChange}
        />
      </div>
    </CfTableWrapperV2>
  );
};

const mapDispatchToProps = (dispatch: Thunk<ParcelsState>) =>
  bindActionCreators(
    {
      setFocusedRow,
    },
    dispatch,
  );

const useStyles = makeStyles(() => ({
  table: {
    "& td, & th": {
      borderBottom: "2px solid #FAFAFA",
    },
  },
  tableBody: {
    "&:has(input, select) tr": {
      pointerEvents: "none",
    },
    "& input": {
      pointerEvents: "all",
    },
    "& tr:first-child td:first-child": {
      borderTopLeftRadius: 8,
    },
    "& tr:first-child td:last-child": {
      borderTopRightRadius: 8,
    },
    "& tr:last-child td:first-child": {
      borderBottomLeftRadius: 8,
    },
    "& tr:last-child td:last-child": {
      borderBottomRightRadius: 8,
    },
  },
  footerContainer: {
    flexShrink: 0,
    display: "flex",
    justifyContent: "center",
  },
}));

const connector = connect(null, mapDispatchToProps);

export default connector(Table);
