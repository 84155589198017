import { makeStyles } from "@mui/styles";
import { isNil } from "lodash";
import { DashboardParcelTo } from "../../../../../generated/api/satellite";
import React from "react";

type Props = {
  parcel: DashboardParcelTo;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
};

const YieldPlaceholder = ({ parcel, onClick }: Props) => {
  const classes = useStyles();
  return (
    <button className={classes.wrapper} onClick={onClick}>
      {isNil(parcel.yieldInTonnes) ? "-" : `${parcel.yieldInTonnes} t/ha`}
    </button>
  );
};

const useStyles = makeStyles(() => ({
  wrapper: {
    display: "flex",
    alignItems: "flex-start",
    cursor: "pointer",
    height: 26,
    background: "none",
    border: "none",
    padding: 0,
    margin: 0,
    outline: "none",
    color: "inherit",
    font: "inherit",
    textAlign: "left",
    maxWidth: 228,
    minWidth: "25%",
    borderRadius: 8,
    "&:hover": {
      backgroundColor: "#0000000a",
    },
    "&:focus": {
      backgroundColor: "#0000000a",
    },
    fontSize: 12,
  },
}));

export { YieldPlaceholder };
