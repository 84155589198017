import React, { Suspense } from "react";

import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";

import { LANG_KEYS } from "../../../../shared/lang/lang.constants";

import CfLoader from "../../../../shared/components/common/CfLoader/CfLoader";
import BaseContainer from "../../../../shared/containers/BaseContainer/BaseContainer";
import { Stores } from "../../list/containers/Stores";
import { Store } from "../../store/containers/Store";
import translationsCZ from "../lang/locale-cs-CZ.json";
import translationsEL from "../lang/locale-el-GR.json";
import translationsEN from "../lang/locale-en-US.json";
import translationsES from "../lang/locale-es-ES.json";
import translationsHU from "../lang/locale-hu-HU.json";
import translationsPL from "../lang/locale-pl-PL.json";
import translationsRO from "../lang/locale-ro-RO.json";

const translations = {
  [LANG_KEYS.CZ]: translationsCZ,
  [LANG_KEYS.EN]: translationsEN,
  [LANG_KEYS.ES]: translationsES,
  [LANG_KEYS.EL]: translationsEL,
  [LANG_KEYS.RO]: translationsRO,
  [LANG_KEYS.HU]: translationsHU,
  [LANG_KEYS.PL]: translationsPL,
};

const StoresWrapper = (props) => (
  <BaseContainer langId={props.langId} translations={translations}>
    <Suspense fallback={<CfLoader />}>
      <Switch>
        <Route
          path="/farm/:farmId/stores"
          render={(routerProps) => <Stores {...props} {...routerProps} />}
        />
        <Route
          exact
          path="/farm/:farmId/store/:storeId"
          render={(routerProps) => <Store {...props} {...routerProps} />}
        />
      </Switch>
    </Suspense>
  </BaseContainer>
);

StoresWrapper.propTypes = {
  langId: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
};

export { StoresWrapper };
