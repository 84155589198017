import { useEffect, useState } from "react";
import { CropPlaceholder } from "../../components/crops/CropPlaceholder";
import { CropsDropdown } from "../../components/crops/CropsDropdown";
import React from "react";
import {
  SELECT_EMPTY_OBJ,
  SelectObject,
} from "../../../../../shared/components/form/FormSelect/types";
import {
  DashboardParcelTo,
  DashboardSeasonTo,
  InternalCropTo,
} from "../../../../../generated/api/satellite";
import { UseMutationResult } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

type Props = {
  cropsOptions: SelectObject<InternalCropTo>[];
  parcel: DashboardParcelTo;
  season: DashboardSeasonTo;
  seasonParcelMutation: UseMutationResult<
    AxiosResponse<DashboardSeasonTo, unknown>,
    Error,
    unknown
  >;
};

const CropCell = ({
  cropsOptions,
  parcel,
  season,
  seasonParcelMutation,
}: Props) => {
  const [edit, setEdit] = useState(false);
  const [value, setValue] = useState<
    SelectObject<InternalCropTo> | undefined
  >();

  useEffect(() => {
    const selectedCrop =
      parcel && cropsOptions.find((c) => c.obj?.id === parcel.cropInternalId);
    const selectedDropdownValue = selectedCrop
      ? ({
          key: selectedCrop.obj?.id,
          value: selectedCrop.obj?.name,
          obj: selectedCrop.obj,
        } as SelectObject<InternalCropTo>)
      : undefined;

    setValue(selectedDropdownValue);
  }, [cropsOptions, parcel]);

  const handleChange = (
    e: React.SyntheticEvent,
    selected: SelectObject<InternalCropTo>,
  ) => {
    setValue(selected ?? SELECT_EMPTY_OBJ);

    seasonParcelMutation.mutate({
      cropInternalId: selected.obj?.id,
      parcelId: parcel.parcelId,
      seasonId: season.seasonId,
      yieldInTonnes: parcel.yieldInTonnes,
      cropName: selected.obj?.name,
    });
  };

  let Jsx = null;

  if (edit) {
    Jsx = (
      <CropsDropdown
        cropsOptions={cropsOptions}
        setEdit={setEdit}
        value={value}
        handleChange={handleChange}
      />
    );
  } else if (!edit) {
    Jsx = (
      <CropPlaceholder onClick={() => setEdit(true)} selectedOption={value} />
    );
  }
  return Jsx;
};

export { CropCell };
