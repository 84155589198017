import React from "react";

import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { Theme, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Field, Form, Formik } from "formik";
import moment from "moment";
import { FormattedMessage, useIntl } from "react-intl";

import {
  ParcelsConfigUpdateRequest,
  PrecisionFarmingProduct,
} from "../../../../../generated/api/satellite";
import CfDialog from "../../../../../shared/components/common/CfDialog/CfDialog";
import CfFormControl from "../../../../../shared/components/form/CfFormControl/CfFormControl";
import CfFormikDatePicker from "../../../../../shared/components/form/CfFormikDatePicker/CfFormikDatePicker";
import { COLOR_GREY } from "../../../../../theme";
import { ParcelWithServices } from "../SentinelAdmin/useSentinelAdmin";
import { getParcelProduct } from "../SentinelAdminTable/SentinelAdminTable";

import { FormControl } from "./FormControl";

export type FormInitValuesType = {
  biomonitoringEnabled?: boolean;
  internalBiomonitoringEnabled?: boolean;
  managementZonesEnabled?: boolean;
  yieldMapsEnabled?: boolean;
  planetEnabledDate: moment.Moment;
};

export type FormType = "multi" | "single";

type Props = {
  title: React.ReactNode;
  mode: FormType;
  onClose: () => void;
  onSubmit: (config: Omit<ParcelsConfigUpdateRequest, "parcelId">) => void;
  initData?: Partial<ParcelWithServices>;
};

const SentinelAdminParcelsDialog = ({
  initData,
  mode,
  onClose,
  onSubmit,
  title,
}: Props) => {
  const intl = useIntl();
  const classes = useStyles();

  const getPlanetEnabledDate = () => {
    if (initData) {
      const product = getParcelProduct(
        initData,
        PrecisionFarmingProduct.PLANET_BIOMONITORING_SAVI,
      );
      if (product?.enabledDate) {
        return moment(product?.enabledDate);
      }
    }
    return moment().utc().subtract(1, "days");
  };

  const [initialValues] = React.useState({
    biomonitoringEnabled: initData?.biomonitoringEnabled,
    internalBiomonitoringEnabled: initData?.internalBiomonitoringEnabled,
    managementZonesEnabled: initData?.managementZonesEnabled,
    yieldMapsEnabled: initData?.yieldMapsEnabled,
    planetEnabledDate: getPlanetEnabledDate(),
  });
  const formValues = initialValues;

  const handleSubmit = (values: FormInitValuesType) => {
    const { planetEnabledDate, ...restValues } = values;
    const resultValues: Omit<ParcelsConfigUpdateRequest, "parcelId"> = {
      ...restValues,
      productDates: {
        PLANET_BIOMONITORING_SAVI: {
          enabledDate: values.internalBiomonitoringEnabled
            ? planetEnabledDate
                .hours(0)
                .minutes(0)
                .seconds(0)
                .format("YYYY-MM-DDTHH:mm:ss")
            : undefined,
          stoppedDate: undefined,
        },
      },
    };
    onSubmit(resultValues);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formValues}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, resetForm, submitForm, values }) => {
        const closeForm = () => {
          resetForm();
          onClose();
        };

        return (
          <CfDialog
            cancelText={<FormattedMessage id="common.cancel" />}
            disabled={isSubmitting}
            onAccept={submitForm}
            onCancel={closeForm}
            onClose={closeForm}
            opened
            title={title}
            acceptText={
              mode === "single" ? (
                <FormattedMessage id="common.edit" />
              ) : (
                <FormattedMessage id="common.confirmChanges" />
              )
            }
          >
            <Form>
              <FormControl
                mode={mode}
                name="biomonitoringEnabled"
                label={
                  <div className={classes.formControlLabel}>
                    <FormattedMessage id="SentinelAdmin.monitoringGisat" />
                    <Tooltip
                      title={
                        <FormattedMessage id="SentinelAdmin.monitoringGisat.tooltip" />
                      }
                    >
                      <InfoOutlined htmlColor={COLOR_GREY[500]} />
                    </Tooltip>
                  </div>
                }
              />
              <div className={classes.divider} />
              <FormControl
                mode={mode}
                name="internalBiomonitoringEnabled"
                label={
                  <div className={classes.formControlLabel}>
                    <FormattedMessage id="SentinelAdmin.internalBiomonitoringEnabled" />
                    <Tooltip
                      title={
                        <FormattedMessage id="SentinelAdmin.internalBiomonitoringEnabled.tooltip" />
                      }
                    >
                      <InfoOutlined htmlColor={COLOR_GREY[500]} />
                    </Tooltip>
                  </div>
                }
              />
              {values?.internalBiomonitoringEnabled && (
                <div style={{ width: 150 }}>
                  <CfFormControl>
                    <Field
                      component={CfFormikDatePicker}
                      disableFuture
                      format="DD/MM/YYYY"
                      name="planetEnabledDate"
                      shouldDisableDate={(date: moment.Moment) => {
                        const today = moment().utc().startOf("day");
                        return date.isSame(today, "day");
                      }}
                    />
                  </CfFormControl>
                </div>
              )}
              <div className={classes.divider} />
              <FormControl
                mode={mode}
                name="managementZonesEnabled"
                label={intl.formatMessage({
                  id: "SentinelAdmin.managementZonesEnabled",
                })}
              />
              <div className={classes.divider} />
              <FormControl
                mode={mode}
                name="yieldMapsEnabled"
                label={intl.formatMessage({
                  id: "SentinelAdmin.yieldMapsEnabled",
                })}
              />
              <div className={classes.divider} />
            </Form>
          </CfDialog>
        );
      }}
    </Formik>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  formControlLabel: {
    display: "flex",
    alignItems: "center",
    gap: 6,
  },
  divider: {
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    marginTop: 15,
    marginBottom: 15,
  },
}));

export default SentinelAdminParcelsDialog;
