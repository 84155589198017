const PREFIX = "FARM_LIST_ADMIN";

export const UPDATE_ACCOUNT_FARMS_SETTINGS_REQUEST = `${PREFIX}/UPDATE_ACCOUNT_FARMS_SETTINGS_REQUEST`;
export const UPDATE_ACCOUNT_FARMS_SETTINGS_SUCCESS = `${PREFIX}/UPDATE_ACCOUNT_FARMS_SETTINGS_SUCCESS `;
export const UPDATE_ACCOUNT_FARMS_SETTINGS_ERROR = `${PREFIX}/UPDATE_ACCOUNT_FARMS_SETTINGS_ERROR`;

export const GET_USERS_BY_FARM_REQUEST = `${PREFIX}/GET_USERS_BY_FARM_REQUEST`;
export const GET_USERS_BY_FARM_SUCCESS = `${PREFIX}/GET_USERS_BY_FARM_SUCCESS`;
export const GET_USERS_BY_FARM_ERROR = `${PREFIX}/GET_USERS_BY_FARM_ERROR`;

export const API_IDENTIFIERS = ["GET_FARMS", "FORCE_GEOSERVER_RESET"] as const;

export type API_IDENTIFIER = (typeof API_IDENTIFIERS)[number];
