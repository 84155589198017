import React, { Component, Fragment, ReactNode } from "react";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { withStyles } from "@mui/styles";
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps,
} from "react-intl";

import ImportHelp from "../ImportHelp/ImportHelp";
import SubmitError from "../SubmitError/SubmitError";

import "../../../../assets/files/szr_id.png";
import "../../../../assets/files/ws_key.png";
import { ApiError } from "../../../../shared/api/agroevidence/countries/countriesTypes.types";

const styles = () => ({
  inputRowGap: {
    marginBottom: 12,
  },
  helperWrapper: {
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "flex-start",
    marginBottom: 12,
  },
  input: {
    fontWeight: 400,
    zIndex: 1,
    pointerEvents: "none" as const,
  },
  button: {
    width: "100%",
    marginTop: 32,
    marginBottom: 5,
  },
});

interface Props extends WrappedComponentProps {
  classes: Record<string, string>;
  country: string;
  eagriAuthenticated: boolean;
  farmname: string;
  onCreateEagriFarm: (farmName: string) => Promise<unknown>;
  onSetEagriAuthData: (
    username: string,
    wsKey: string,
    szrid: string,
  ) => Promise<unknown>;
}

interface State {
  farmname: string;
  farmnameErrorText: string;
  submitError: ReactNode;
  szrid: string;
  szridErrorText: string;
  username: string;
  usernameErrorText: string;
  wsKey: string;
  wsKeyErrorText: string;
}

enum ERRORS {
  FARM = "farmnameRequiredError",
  SZRID = "szridRequiredError",
  USER = "usernameRequiredError",
  WSKEY = "wsKeyRequiredError",
}

export class EAgriImport extends Component<Props, State> {
  farmnameRequiredError: boolean;
  usernameRequiredError: boolean;
  wsKeyRequiredError: boolean;
  szridRequiredError: boolean;

  constructor(props: Props) {
    super(props);

    this.state = {
      farmname: props.farmname,
      farmnameErrorText: "",
      username: "",
      usernameErrorText: "",
      wsKey: "",
      wsKeyErrorText: "",
      szrid: "",
      szridErrorText: "",
      submitError: null,
    };
    this.farmnameRequiredError = false;
    this.usernameRequiredError = false;
    this.wsKeyRequiredError = false;
    this.szridRequiredError = false;
  }

  componentDidUpdate(prevProps: Props) {
    const { farmname: prevFarmname } = prevProps;
    const { farmname } = this.props;
    if (prevFarmname !== farmname) {
      this.setState({
        ...this.state,
        farmname,
      });
    }
  }

  onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    this.setState({
      ...this.state,
      [name]: e.target.value,
    });
  };

  onInputFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const name = `${e.target.name}ErrorText`;
    this.setState({
      ...this.state,
      [name]: "",
    });
  };

  onInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    this.validateInput(e.target.value, e.target.name);
  };

  onSetEagriAuthData = () => {
    this.setState((prevState) => ({
      ...prevState,
      submitError: null,
    }));

    this.validateInput(this.state.username, "username");
    this.validateInput(this.state.wsKey, "wsKey");
    this.validateInput(this.state.szrid, "szrid");

    if (
      this.usernameRequiredError ||
      this.wsKeyRequiredError ||
      this.szridRequiredError
    ) {
      return;
    }

    this.props
      .onSetEagriAuthData(
        this.state.username,
        this.state.wsKey,
        this.state.szrid,
      )
      .catch(this.errorHandler("validation.submitError"));
  };

  onCreateEagriFarm = () => {
    this.validateInput(this.state.farmname, "farmname");

    if (this.farmnameRequiredError) {
      return;
    }

    this.props.onCreateEagriFarm(this.state.farmname);
  };

  setValidationMsg = (name: string) => {
    const stateProp = `${name}ErrorText` as
      | "farmnameErrorText"
      | "usernameErrorText"
      | "wsKeyErrorText"
      | "szridErrorText";
    const validationProp = `${name}RequiredError`;
    const { formatMessage } = this.props.intl;

    this.setState((prevState: State) => ({
      ...prevState,
      [stateProp]: this[validationProp as ERRORS]
        ? formatMessage({ id: "validation.required" })
        : "",
    }));
  };

  errorHandler(defaultMsg: string) {
    return (e: ApiError) => {
      const msg = (e.handled && e.msgCode) || defaultMsg;
      this.setState({
        ...this.state,
        submitError: <FormattedMessage id={msg} />,
      });
    };
  }

  validateInput(val: string, name: string) {
    const validationProp = `${name}RequiredError` as ERRORS;
    this[validationProp] = !(val && val.trim().length > 0);
    this.setValidationMsg(name);
  }

  showHelp = () =>
    this.props.intl.locale === "cs-CZ" && this.props.country === "CZ";

  render() {
    const { formatMessage } = this.props.intl;
    const classes = this.props.classes;
    const showHelp = this.showHelp();

    return (
      <Fragment>
        {this.props.eagriAuthenticated && (
          <Fragment>
            <TextField
              autoComplete="new-password"
              error={this.state.farmnameErrorText !== ""}
              fullWidth
              id="eagri-farm-name"
              InputLabelProps={{ className: classes.input }}
              label={<FormattedMessage id="common.farmName" />}
              margin="dense"
              name="farmname"
              onBlur={this.onInputBlur}
              onChange={this.onInputChange}
              onFocus={this.onInputFocus}
              value={this.state.farmname}
              helperText={
                this.state.farmnameErrorText !== ""
                  ? this.state.farmnameErrorText
                  : " "
              }
            />
            <Button
              className={classes.button}
              color="primary"
              id="eagri-create-farm"
              onClick={this.onCreateEagriFarm}
              variant="contained"
            >
              <FormattedMessage id="EAgriImport.button.create" />
            </Button>
            <SubmitError msg={this.state.submitError} />
          </Fragment>
        )}
        {!this.props.eagriAuthenticated && (
          <Fragment>
            <TextField
              autoComplete="new-password"
              className={classes.inputRowGap}
              error={this.state.usernameErrorText !== ""}
              fullWidth
              helperText={this.state.usernameErrorText ?? null}
              id="eagri-username"
              InputLabelProps={{ className: classes.input }}
              label={<FormattedMessage id="EAgriImport.userName" />}
              margin="dense"
              name="username"
              onBlur={this.onInputBlur}
              onChange={this.onInputChange}
              onFocus={this.onInputFocus}
              value={this.state.username}
            />
            <div className={classes.helperWrapper}>
              <TextField
                autoComplete="new-password"
                error={this.state.wsKeyErrorText !== ""}
                fullWidth
                helperText={this.state.wsKeyErrorText ?? null}
                id="eagri-ws-key"
                InputLabelProps={{ className: classes.input }}
                label={<FormattedMessage id="EAgriImport.wsKey" />}
                name="wsKey"
                onBlur={this.onInputBlur}
                onChange={this.onInputChange}
                onFocus={this.onInputFocus}
                type="password"
                value={this.state.wsKey}
              />
              {showHelp && (
                <ImportHelp
                  fileUrl="assets/files/ws_key.png"
                  copy={formatMessage({
                    id: "EAgriImport.howTo.copy1",
                  })}
                  linkCopy={formatMessage({
                    id: "EAgriImport.howTo.WSKEY",
                  })}
                  title={formatMessage({
                    id: "EAgriImport.howToGetWSKEY",
                  })}
                />
              )}
            </div>
            <div className={classes.helperWrapper}>
              <TextField
                autoComplete="new-password"
                error={this.state.szridErrorText !== ""}
                fullWidth
                helperText={this.state.szridErrorText ?? null}
                id="eagri-szrid"
                InputLabelProps={{ className: classes.input }}
                label={<FormattedMessage id="EAgriImport.szrid" />}
                name="szrid"
                onBlur={this.onInputBlur}
                onChange={this.onInputChange}
                onFocus={this.onInputFocus}
                value={this.state.szrid}
              />
              {showHelp && (
                <ImportHelp
                  fileUrl="assets/files/szr_id.png"
                  copy={formatMessage({
                    id: "EAgriImport.howTo.copy1",
                  })}
                  linkCopy={formatMessage({
                    id: "EAgriImport.howTo.SZRID",
                  })}
                  title={formatMessage({
                    id: "EAgriImport.howToGetSZRID",
                  })}
                />
              )}
            </div>
            <Button
              className={classes.button}
              color="primary"
              id="eagri-set-auth-data"
              onClick={this.onSetEagriAuthData}
              variant="contained"
            >
              <FormattedMessage id="EAgriImport.button.loadData" />
            </Button>
            <SubmitError msg={this.state.submitError} />
          </Fragment>
        )}
      </Fragment>
    );
  }
}

export default injectIntl(withStyles(styles)(EAgriImport));
