import React, { useEffect } from "react";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import { Theme } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import moment from "moment";
import { FormattedDate, FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  getIsFetching,
  getTotalCount,
} from "../../../../shared/api/stores/stores/stores.selectors";
import {
  getStoreItems,
  getStoresMaterialType,
  getStoresShowNullValues,
  getStoresTextFilter,
  getStoresPage,
  getStoresOrder,
  getStoresRowsPerPage,
} from "../selectors/stores.selectors";

import { fetchStores } from "../actions/stores.actions";

import { NAMESPACE as namespace } from "../reducer/stores.reducer";

import CfTableFooter from "../../../../common/components/CfTableFooter/CfTableFooter";
import { useCfTableFooter } from "../../../../common/components/CfTableFooter/useCfTableFooter";
import CfTableHead from "../../../../common/components/CfTableHead/CfTableHead";
import { useCfTableHead } from "../../../../common/components/CfTableHead/useCfTableHead";
import CfFormattedNumber from "../../../../shared/components/common/CfFormattedNumber/CfFormattedNumber";
import CfTableBodyEmpty from "../../../../shared/components/tables/CfTableBodyEmpty/CfTableBodyEmpty";
import CfTableBodyLoader from "../../../../shared/components/tables/CfTableBodyLoader/CfTableBodyLoader";
import CfTableCell from "../../../../shared/components/tables/CfTableCell/CfTableCell";
import { CfTableIconButton } from "../../../../shared/components/tables/CfTableIconButton/CfTableIconButton";
import CfTableWrapper from "../../../../shared/components/tables/CfTableWrapper/CfTableWrapper";
import { getColDesc } from "../../../../shared/misc/helper";
import { AnyTodo } from "../../../../types";

const columns = {
  material: getColDesc(true, <FormattedMessage id="Stores.material-name" />, {
    paddingLeft: 15,
    width: "34%",
  }),
  validTo: getColDesc(false, <FormattedMessage id="Stores.validTo" />, {
    width: "10%",
  }),
  availableCount: getColDesc(
    false,
    <FormattedMessage id="Stores.current-amount" />,
    { width: "16%" },
  ),
  unit: getColDesc(false, <FormattedMessage id="common.unit" />, {
    width: "8%",
  }),
  planned: getColDesc(
    false,
    <FormattedMessage id="Stores.planned-to-apply" />,
    { width: "16%" },
  ),
  change: getColDesc(
    false,
    <FormattedMessage id="Stores.add-remove-amount" />,
    { padding: "0 5px", textAlign: "center", width: "8%" },
  ),
  delete: getColDesc(false, <FormattedMessage id="common.delete" />, {
    padding: "0 5px",
    textAlign: "center",
    width: "8%",
  }),
};

type Props = {
  farmId: string;
  onUpdateStoreClick: (storeItem: unknown) => void;
  onDeleteStoreClick: (storeItem: unknown) => void;
};

const StoresTable = ({
  farmId,
  onDeleteStoreClick,
  onUpdateStoreClick,
}: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const items: AnyTodo[] = useSelector(getStoreItems);
  const materialTypeId = useSelector(getStoresMaterialType);
  const showNullValues = useSelector(getStoresShowNullValues);
  const textFilter = useSelector(getStoresTextFilter);
  const isFetching = useSelector(getIsFetching);
  const count = useSelector(getTotalCount);
  const page = useSelector(getStoresPage);
  const order = useSelector(getStoresOrder);
  const rowsPerPage = useSelector(getStoresRowsPerPage);

  const history = useHistory();

  const { onPageChange, onRowsPerPageChange } = useCfTableFooter(namespace);

  const { onSelect, onSort } = useCfTableHead(namespace);

  useEffect(() => {
    dispatch(fetchStores());
  }, [
    page,
    rowsPerPage,
    materialTypeId,
    showNullValues,
    order,
    textFilter,
    dispatch,
  ]);

  return (
    <CfTableWrapper>
      <CfTableHead
        columns={columns}
        onSelect={onSelect}
        onSort={onSort}
        order={order}
        orderBy="material"
      />
      {isFetching && <CfTableBodyLoader columns={columns} />}

      {items.length ? (
        <TableBody>
          {items.map((item) => {
            const isValid = moment(item.material.validTo).isAfter(
              moment().startOf("day"),
            );

            return (
              <TableRow
                className={classes.pointer}
                hover
                key={item.id}
                onClick={() => history.push(`/farm/${farmId}/store/${item.id}`)}
              >
                <CfTableCell name="material">
                  <span className={classes.name}>{item.material.name}</span>
                </CfTableCell>
                <CfTableCell name="validTo">
                  <div
                    className={classNames({
                      [classes.dataIsHistorical]:
                        item.material.validTo && !isValid,
                    })}
                  >
                    {item.material.validTo ? (
                      <FormattedDate value={item.material.validTo} />
                    ) : (
                      "-"
                    )}
                  </div>
                </CfTableCell>
                <CfTableCell name="availableCount">
                  <div
                    className={classNames({
                      [classes.dataIsHistorical]: item.currentBalance < 0,
                    })}
                  >
                    <CfFormattedNumber
                      decimalDigits={4}
                      value={item.currentBalance}
                    />
                  </div>
                </CfTableCell>
                <CfTableCell name="unit">
                  <FormattedMessage id={`unit.${item.unitId}`} />
                </CfTableCell>
                <CfTableCell name="planned">
                  <div
                    className={classNames({
                      [classes.dataIsHistorical]: item.plannedConsumption < 0,
                    })}
                  >
                    <CfFormattedNumber value={item.plannedConsumption} />
                  </div>
                </CfTableCell>
                <CfTableIconButton
                  onClick={(evt) => {
                    onUpdateStoreClick(item);
                    evt.stopPropagation();
                  }}
                >
                  <AddCircleIcon className={classes.updateIcon} />
                </CfTableIconButton>
                <CfTableIconButton
                  onClick={(evt) => {
                    onDeleteStoreClick(item);
                    evt.stopPropagation();
                  }}
                >
                  <DeleteIcon className={classes.deleteIcon} />
                </CfTableIconButton>
              </TableRow>
            );
          })}
        </TableBody>
      ) : (
        <CfTableBodyEmpty colLength={Object.keys(columns).length + 1} />
      )}
      <CfTableFooter
        count={count}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
      />
    </CfTableWrapper>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  deleteIcon: {
    color: theme.palette.action.active,
  },
  updateIcon: {
    color: theme.palette.action.active,
  },
  name: {
    fontWeight: 500,
    paddingLeft: 15,
  },
  pointer: {
    cursor: "pointer",
  },
  dataIsHistorical: {
    color: theme.palette.error.main,
  },
}));

export { StoresTable };
