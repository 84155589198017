import React from "react";

import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

interface Props {
  showNullValues: boolean;
  setStoreShowNullValues: (checked: boolean) => void;
}

const StoreDisplayNull = ({
  setStoreShowNullValues,
  showNullValues,
}: Props) => {
  const classes = useStyles();
  return (
    <div className={classes.alignRight}>
      <FormControlLabel
        label={<FormattedMessage id="Stores.show-zero-items" />}
        labelPlacement="start"
        classes={{
          label: classes.label,
        }}
        control={
          <Switch
            checked={showNullValues}
            color="primary"
            id="display-null-checkbox"
            onChange={(e) => setStoreShowNullValues(e.target.checked)}
          />
        }
      />
    </div>
  );
};

const useStyles = makeStyles({
  alignRight: {
    display: "flex",
    justifyContent: "flex-end",
    marginRight: 8,
    marginTop: 5,
  },
  label: {
    fontSize: 14,
  },
});

export { StoreDisplayNull };
