import React, { useContext, useEffect, useMemo, useState } from "react";

import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Formik, FormikErrors, FormikHelpers } from "formik";
import moment from "moment";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RSAAResultAction } from "redux-api-middleware";
import { toFormikValidationSchema } from "zod-formik-adapter";

import { getParcelsToAdd } from "../shared/selectors/actions.selectors";

import {
  addInitParcelsToAdd,
  clearParcelsToAdd,
} from "../shared/actions/actions.actions";

import {
  ActionEPHCreateTo,
  ActionEPHDetailTo,
  ParcelTo,
  RestrictionType,
} from "../../../generated/api/agroevidence";
import {
  createEPHActionApi,
  updateEPHActionApi,
} from "../../../shared/api/agroevidence/actions/actions.api";
import { SnackbarContext } from "../../../shared/containers/SnackbarProvider/SnackbarProvider";
import { useToggle } from "../../../shared/hooks/useToggle";
import { useTypedIntl } from "../../../shared/hooks/useTypedIntl";
import LocalStorage from "../../../shared/services/LocalStorage.service";
import { ActionDetailHeader } from "../shared/components/ActionDetailHeader/ActionDetailHeader";
import { ActionDetailContext } from "../shared/containers/ActionDetail/ActionDetail";
import { initialWaterProtectionZones } from "../shared/services/Actions.services";

import {
  initialEphFormEmptyValues,
  mapEphFormInitialValues,
  mapRequestBodyEphActionTo,
} from "./actionEph.services";
import { ActionEphForm } from "./ActionEphForm";
import { validationSchema } from "./actionEphFormValidationSchema";

import { ActionEphFormErrors, ActionEphFormValues } from "./actionEph.types";
import { ActionsState } from "../../../reducers/actions.reducer.types";
import { InitialParcelToAdd } from "../ActionOthers/actionOther.types";

type Props = {
  existingAction?: ActionEPHDetailTo;
  initParcelIds?: string[];
  isNew?: boolean;
  ngGoToActions: () => void;
};

const ActionEphDetail = ({
  existingAction,
  initParcelIds,
  isNew = false,
  ngGoToActions,
}: Props) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { locale } = useTypedIntl();
  const dispatch = useDispatch();
  const initialParcelsToAdd = useSelector((state: ActionsState) =>
    getParcelsToAdd("eph", state),
  );

  const showSnackbar = useContext(SnackbarContext);

  const { handleStartIsSplitting, isSplitting } =
    useContext(ActionDetailContext);
  const { farmId } = useParams<{ farmId: string }>();

  const {
    on: isEditing,
    setOff: onEditingEnd,
    setOn: onEditingStart,
  } = useToggle(isNew);

  const [formState, setFormState] = useState({
    initialValues: initialEphFormEmptyValues,
    lsName: "",
    hasAddedInitialParcels: false,
  });

  const hasInitParcels = !!initParcelIds?.[0];

  useEffect(() => {
    const lsName = `form_state_EPH_${farmId}`;
    const savedValuesFromPreviousInteraction =
      LocalStorage.loadFromLocalStorage(lsName);

    const newState: {
      lsName: string;
      initialValues?: ActionEphFormValues;
    } = {
      lsName,
    };

    if (hasInitParcels) {
      dispatch(addInitParcelsToAdd(initParcelIds));
    }

    if (savedValuesFromPreviousInteraction && !hasInitParcels) {
      const cleanedValues = {
        ...savedValuesFromPreviousInteraction,
        date: moment(savedValuesFromPreviousInteraction.date),
      };
      newState.initialValues = cleanedValues;
    }

    setFormState((prevState) => ({
      ...prevState,
      ...newState,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!hasInitParcels) return;
    if (!initialParcelsToAdd.length) return;
    if (formState.hasAddedInitialParcels) return;

    setFormState((prevState) => ({
      ...prevState,
      hasAddedInitialParcels: true,
      initialValues: {
        ...prevState.initialValues,
        parcels: [
          ...prevState.initialValues.parcels,
          ...initialParcelsToAdd.map((p: ParcelTo) =>
            mapInitialParcelsToAdd(p),
          ),
        ],
      },
    }));
    dispatch(clearParcelsToAdd());
  }, [
    dispatch,
    formState.hasAddedInitialParcels,
    hasInitParcels,
    initialParcelsToAdd,
  ]);

  const mapInitialParcelsToAdd = (parcel: ParcelTo): InitialParcelToAdd => ({
    ...parcel,
    subtractableAreas: {
      absolute: [],
      boundary: [],
      water: [],
      surfaceWaterProtectionZones: initialWaterProtectionZones(
        RestrictionType.SurfaceWaterProtectionZones,
      ),
      groundWaterProtectionZones: initialWaterProtectionZones(
        RestrictionType.GroundWaterProtectionZones,
      ),
      populationProtectionZones: [],
      boundaryChecked: 0,
      waterChecked: 0,
      surfaceWaterProtectionZonesChecked: 0,
      groundWaterProtectionZonesChecked: 0,
      populationProtectionZonesChecked: 0,
    },
    restrictedArea: 0,
    actionParcelTotalArea: parcel.area,
  });

  const handleResetForm = () => {
    setFormState((prevState) => ({
      ...prevState,
      initialValues: initialEphFormEmptyValues,
    }));
  };

  const onLsReset = () => LocalStorage.removeFromLocalStorage(formState.lsName);

  const onLsSave = (values: ActionEphFormValues) => {
    LocalStorage.saveToLocalStorage(values, formState.lsName);
  };

  const handleSubmit = (
    values: ActionEphFormValues,
    formikHelpers: FormikHelpers<ActionEphFormValues>,
  ) => {
    const data = mapRequestBodyEphActionTo(values, locale, existingAction?.id);

    if (isNew) {
      (dispatch(createEPHActionApi(data)) as unknown as Promise<unknown>)
        .then((res: RSAAResultAction<ActionEPHCreateTo>) =>
          responseProcessing(res, true),
        )
        .then(() => {
          formikHelpers.setSubmitting(false);
        });
      handleResetForm();
      onLsReset();
    } else {
      (dispatch(updateEPHActionApi(data)) as unknown as Promise<unknown>)
        .then((res: RSAAResultAction<ActionEPHCreateTo>) =>
          responseProcessing(res),
        )
        .then(() => {
          formikHelpers.setSubmitting(false);
        });
      handleResetForm();
      onLsReset();
    }
  };

  const responseProcessing = (
    res: RSAAResultAction<ActionEPHCreateTo>,
    newAction = false,
  ) => {
    if (newAction) LocalStorage.removeFromLocalStorage(formState.lsName);
    if (!res.error) {
      handleResetForm();
      if (newAction) onEditingEnd();
      ngGoToActions();
    } else {
      showSnackbar({
        message: formatMessage({ id: "action.other.error" }),
        isError: true,
      });
    }
  };

  const formikInitialValues = useMemo(() => {
    if (!existingAction) return formState.initialValues;

    return mapEphFormInitialValues(existingAction, locale);
  }, [existingAction, formState.initialValues, locale]);

  return (
    <div className={classes.wrapperStyle}>
      <div className={classes.container}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ActionDetailHeader
              handleStartIsSplitting={handleStartIsSplitting}
              isDisabled={isEditing || isSplitting}
              isDraft={!!existingAction?.isDraft}
              isExisting={!isNew}
              ngGoToActions={ngGoToActions}
              onClick={onEditingStart}
              title="Eph.title"
            />
            <Grid container justifyContent="center" spacing={2}>
              <Grid item lg={8} md={10} xs={12}>
                <Formik
                  enableReinitialize
                  initialValues={formikInitialValues}
                  onSubmit={handleSubmit}
                  validateOnBlur={false}
                  validateOnChange={false}
                  validationSchema={toFormikValidationSchema(validationSchema)}
                >
                  {(formikProps) => (
                    <ActionEphForm
                      existingActionId={existingAction?.id}
                      handleLsReset={onLsReset}
                      handleResetForm={handleResetForm}
                      handleSaveToLs={onLsSave}
                      isDraft={!!existingAction?.isDraft}
                      isEditing={isEditing}
                      isExisting={!isNew}
                      isSubmitting={formikProps.isSubmitting}
                      onEditingEnd={onEditingEnd}
                      values={formikProps.values as ActionEphFormValues}
                      errors={
                        formikProps.errors as FormikErrors<
                          Partial<ActionEphFormErrors>
                        >
                      }
                    />
                  )}
                </Formik>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export { ActionEphDetail };

const useStyles = makeStyles({
  wrapperStyle: {
    position: "absolute",
    top: 56,
    left: 0,
    right: 0,
    bottom: 0,
  },
  container: {
    margin: 15,
    paddingBottom: 30,
  },
});
