import React from "react";

import regionPinIcon from "../../../../../assets/img/icons/satellite/precision/dashboard/region-pin.svg";
import { generatePath, Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useTypedIntl } from "../../../../../shared/hooks/useTypedIntl";
import { getShortDateString } from "../../../../../shared/misc/timeHelpers";
import { DashboardSeasonTo } from "../../../../../generated/api/satellite";
import { PRECISION_URLS } from "../../../../../core/precision/precision.constants";
import { useFarmIds } from "../../../../../shared/api/client.utils";
import { COLOR_PRIMARY } from "../../../../../theme";

type Props = {
  season: DashboardSeasonTo;
};

const SeasonsTableHeaderCell = ({ season }: Props) => {
  const intl = useTypedIntl();
  const classes = useStyles();
  const { farmIds } = useFarmIds();

  return (
    <div className={classes.headerWrapper}>
      <div className={classes.headerMain}>
        <Link
          className={classes.icon}
          to={generatePath(
            `/farm/${farmIds}/${PRECISION_URLS.seasonDetail()}`,
            {
              seasonId: season.seasonId,
            },
          )}
        >
          {season.name}
        </Link>
      </div>
      <div
        className={classes.headerSub}
      >{`${intl.formatMessage({ id: `PrecisionFarming.seasons.addSeason.start` })}: ${getShortDateString(season.dateFrom, "DD.MM.YYYY")}`}</div>
      <div className={classes.headerRegion}>
        <span>
          <img alt="combine" src={regionPinIcon} style={{ display: "flex" }} />
        </span>
        <span
          className={classes.headerArea}
        >{`${intl.formatMessage({ id: "PrecisionFarming.seasons.totalArea" })} ${season.totalAreaHa} ha`}</span>
      </div>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  headerWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 2,
  },
  headerMain: {
    color: COLOR_PRIMARY.cleverfarm.main,
    fontSize: 16,
  },
  headerSub: {
    color: COLOR_PRIMARY.cleverfarm.main,
    fontSize: 12,
  },
  headerRegion: {
    display: "flex",
    alignItems: "center",
    gap: 2,
  },
  headerArea: {
    fontWeight: 400,
  },
  icon: {
    color: COLOR_PRIMARY.cleverfarm.main,
  },
}));

export { SeasonsTableHeaderCell as TableHeaderCell };
