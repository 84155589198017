import React from "react";

import { Field, FieldArray } from "formik";

import { RestrictionType } from "../../../../../generated/api/agroevidence";
import { ActionParcelSubtractableAreasSection } from "../ActionParcelSubtractableAreasSection/ActionParcelSubtractableAreasSection";
import { SubtractableAreaWithRadioButton } from "../SubtractableAreaWithRadioButton/SubtractableAreaWithRadioButton";

type Props = {
  isEditing: boolean;
  maxValue: number;
  parcelIndex: number;
};

const PopulationProtectionZones = ({
  isEditing,
  maxValue,
  parcelIndex,
}: Props) => (
  <FieldArray
    name={`parcels.${parcelIndex}.subtractableAreas.populationProtectionZones`}
  >
    {({ form }) => (
      <ActionParcelSubtractableAreasSection
        isEditing={isEditing}
        maxValue={maxValue}
        subtractableAreaType={RestrictionType.PopulationProtectionZones}
        resetSubtractableAreasWarning={() => {
          form.setFieldError(
            `parcels.${parcelIndex}.subtractableAreas.populationProtectionZonesChecked`,
            undefined,
          );
        }}
        setSubtractableAreasWarning={() => {
          form.setFieldError(
            `parcels.${parcelIndex}.subtractableAreas.populationProtectionZonesChecked`,
            "Selected value is lower than the maximum value added from POR",
          );
        }}
        subtractableAreas={
          form.values.parcels[parcelIndex].subtractableAreas
            .populationProtectionZones
        }
        subtractableChecked={
          form.values.parcels[parcelIndex].subtractableAreas
            .populationProtectionZonesChecked
        }
      >
        <Field
          component={SubtractableAreaWithRadioButton}
          isEditing={isEditing}
          name={`parcels.${parcelIndex}.subtractableAreas.populationProtectionZonesChecked`}
          subtractableAreaType={RestrictionType.PopulationProtectionZones}
          options={
            form.values.parcels[parcelIndex].subtractableAreas
              .populationProtectionZones
          }
        />
      </ActionParcelSubtractableAreasSection>
    )}
  </FieldArray>
);

export { PopulationProtectionZones };
