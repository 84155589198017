import React, { useEffect, useRef } from "react";

import RefreshIcon from "@mui/icons-material/Refresh";
import { Fab, Paper, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import isEqual from "lodash/isEqual";
import pick from "lodash/pick";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import {
  getDevices,
  getIsFetchingDevices,
  getDevicesTotalCount,
} from "../../../../shared/api/irrigation/devices/devices.selectors";
import { getDevicesListTextFilter } from "../../../selectors/devicesList.selectors";
import { getMapExtent } from "../../../selectors/map.selectors";

import {
  editorSetHoveredId,
  editorSetSelected,
} from "../../../../core/map/actions/editor/editor.actions";
import { zoomToExtent } from "../../../../core/map/actions/map/map.actions";
import {
  fetchDevices,
  fetchIrrigationPoints,
} from "../../../actions/devices.actions";
import { setEnlargedVariant } from "../../../actions/map.actions";

import { NAMESPACE } from "../../../reducer/devicesList.reducer";

import CfTextFilter from "../../../../common/components/CfTextFilter/CfTextFilter";
import { useCfTextFilter } from "../../../../common/components/CfTextFilter/useCfTextFilter";
import { setActivePage, setPrevPage } from "../../../actions/commonActions";
import IrrigationDevicesTable from "../../../components/IrrigationDevicesTable/IrrigationDevicesTable";

const MAP_ZOOM_SCALE_FACTOR = 0.75;

const IrrigationDevicesList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { onChange } = useCfTextFilter(NAMESPACE);

  const devices = useSelector(getDevices);
  const isFetching = useSelector(getIsFetchingDevices);
  const totalCount = useSelector(getDevicesTotalCount);
  const mapExtent = useSelector(getMapExtent);
  const textFilter = useSelector(getDevicesListTextFilter);

  const prevTextFilterRef = useRef("");
  const path = window.location.pathname;

  useEffect(() => {
    dispatch(setEnlargedVariant(false));
    dispatch(fetchDevices(NAMESPACE));
    dispatch(editorSetHoveredId(null));
    dispatch(editorSetSelected([]));
    dispatch(setActivePage(path));

    if (mapExtent.length > 0) {
      dispatch(zoomToExtent(mapExtent, MAP_ZOOM_SCALE_FACTOR));
    }

    return () => {
      dispatch(setPrevPage(path));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, path]);

  useEffect(() => {
    const newPropsToRefreshWithPoint = pick({ textFilter }, ["textFilter"]);

    if (
      !isEqual(
        newPropsToRefreshWithPoint,
        pick({ textFilter: prevTextFilterRef.current }, ["textFilter"]),
      )
    ) {
      dispatch(fetchDevices(NAMESPACE));
      dispatch(fetchIrrigationPoints(NAMESPACE));
    }

    prevTextFilterRef.current = textFilter;
  }, [textFilter, dispatch]);

  useEffect(() => {
    if (mapExtent.length) {
      dispatch(zoomToExtent(mapExtent, MAP_ZOOM_SCALE_FACTOR));
    }
  }, [dispatch, mapExtent]);

  const reloadDevices = () => {
    dispatch(fetchDevices(NAMESPACE));
  };

  const reloadValvesStatus = () => {
    dispatch(fetchDevices(NAMESPACE, null, true));
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.textFilter}>
          <CfTextFilter
            initialValue={textFilter}
            name="irrigation-devices-text-filter"
            onChange={onChange}
            translId="IrrigationList.deviceNameOrID"
          />
        </div>
        <div>
          <Fab
            className={classes.fab}
            onClick={reloadValvesStatus}
            size="small"
            variant="extended"
          >
            <RefreshIcon sx={{ mr: 1 }} />
            <FormattedMessage id="Irrigation.reloadValvesStatus" />
          </Fab>
          <Fab
            className={classes.fabMobile}
            onClick={reloadValvesStatus}
            size="small"
          >
            <RefreshIcon />
          </Fab>
        </div>
      </div>
      <Paper elevation={3} style={{ marginRight: 10 }}>
        <IrrigationDevicesTable
          devices={devices}
          handleContentRefresh={reloadDevices}
          handleHover={(val) => dispatch(editorSetHoveredId(val))}
          isFetching={isFetching}
          namespace={NAMESPACE}
          totalCount={totalCount}
        />
      </Paper>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    margin: 15,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    marginRight: 10,
  },
  textFilter: {
    marginBottom: 8,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      width: 450,
    },
  },
  fab: {
    height: 28,
    backgroundColor: theme.palette.grey[200],
    [theme.breakpoints.down(700)]: {
      display: "none",
    },
  },
  fabMobile: {
    height: 28,
    backgroundColor: theme.palette.grey[200],
    [theme.breakpoints.up(700)]: {
      display: "none",
    },
  },
}));

export { IrrigationDevicesList, MAP_ZOOM_SCALE_FACTOR };
