import { createSelector } from "reselect";

export const getPoints = (state) => state.api.devices.points;
export const getIsFetchingPoints = (state) =>
  state.api.devices.isFetchingPoints;

export const getIrrigationAdminDevices = (state) =>
  state.api.devices.itemsAdmin;
export const getIrrigationAdminTotalCount = (state) =>
  state.api.devices.totalCountAdmin;
export const getIrrigationAdminIsFetching = (state) =>
  state.api.devices.isFetchingAdmin;
export const patchIrrigationAdminIsPatchingAdminDevice = (state) =>
  state.api.devices.isPatchingAdminDevice;

export const getDevices = (state) => state.api.devices.items;
export const getIsFetchingDevices = (state) => state.api.devices.isFetching;
export const getDevicesTotalCount = (state) => state.api.devices.totalCount;

export const getDeviceTypes = (state) => state.api.devices.types;

export const getInactiveDevices = (state) => state.api.devices.itemsInactive;
export const getIsFetchingInactiveDevices = (state) =>
  state.api.devices.isFetchingInactive;
export const getInactiveDevicesTotalCount = (state) =>
  state.api.devices.totalCountInactive;

export const getDevice = (state) => state.api.devices.item;
export const getIsFetchingDevice = (state) =>
  state.api.devices.isFetchingDevice;

export const getDeviceValves = (state) => state.api.devices.itemValves;
export const getIsFetchingDeviceValves = (state) =>
  state.api.devices.isFetchingDeviceValves;

export const getDeviceIntervals = (state) => state.api.devices.intervals;
export const getIsFetchingIntervals = (state) =>
  state.api.devices.isFetchingIntervals;

export const getAssignedPoints = createSelector(getPoints, (points) =>
  points.filter((p) => p.areaId),
);
export const getDevicesWithCoordinates = createSelector(getDevices, (points) =>
  points.filter((p) => p.geometry),
);
export const setDeviceValvesForManualAction = createSelector(
  getDeviceValves,
  (valves) => valves.map((valve) => ({ ...valve, durationSeconds: 0 })),
);

export const getDeviceConfig = (state) => state.api.devices.itemConfig;
export const getIsFetchingDeviceConfig = (state) =>
  state.api.devices.isFetchingDeviceConfig;
