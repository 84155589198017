import React, { ChangeEvent } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Switch, Theme } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@mui/styles";

type Props = {
  children: React.ReactNode;
  header: React.ReactNode;
  checked: boolean;
  onChangeSwitch: (checked: boolean) => void;
};

const ValveConfigAccordion = ({
  checked,
  children,
  header,
  onChangeSwitch,
}: Props) => {
  const classes = useStyles({ checked });
  return (
    <Accordion classes={{ root: classes.accordion }}>
      <AccordionSummary
        classes={{
          root: classes.root,
          content: classes.content,
        }}
        expandIcon={
          <IconButton className={classes.expandIcon}>
            <ExpandMoreIcon />
          </IconButton>
        }
      >
        <span className={classes.header}>
          <Switch
            checked={checked}
            color="primary"
            onClick={(e) => e.stopPropagation()} // Предотвращаем всплытие клика
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              onChangeSwitch(e.target.checked);
            }}
          />
          <span className={classes.label}>{header}</span>
        </span>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export { ValveConfigAccordion };

export const useStyles = makeStyles((theme: Theme) => ({
  accordion: {
    marginBottom: 12,
    "&$expanded": {
      marginBottom: 12,
    },
  },
  root: {
    backgroundColor: (props: { checked: boolean }) =>
      props.checked ? "#E5F8E9" : theme.palette.common.white,
    minHeight: 48,
    fontSize: 16,
    "&$expanded": {
      minHeight: 48,
    },
  },
  content: {
    margin: "8px 0px",
    justifyContent: "left",
    "&$expanded": {
      margin: "8px 0px",
    },
  },
  expandIcon: {
    padding: 6,
    color: theme.palette.primary.main,
  },
  header: {
    width: "100%",
    display: "flex",
  },
  label: {
    display: "flex",
    alignItems: "center",
    textAlign: "left",
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
  details: {
    display: "block",
    padding: 16,
  },
}));
