import { AnyAction } from "redux";

import * as commonFilterTypes from "../../../../shared/actions/filter.constants";
import * as commonTableTypes from "../../../../shared/actions/table.constants";
import * as catalogueActions from "../../../../shared/api/agroevidence/catalogues/crops/crops.constants";
import {
  GET_ADMIN_CROPS_ERROR,
  GET_ADMIN_CROPS_RESET,
  GET_ADMIN_CROPS_SUCCESS,
} from "../../../../shared/api/sentinel/admin/admin.constants";
import { ROWS_PER_PAGE } from "../../../../shared/constants/rowsPerPage.constants";
import * as sortOrder from "../../../../shared/constants/sortOrder.constants";
import * as types from "../actions/sentinel.constants";

import { BooleanSelectorType } from "../containers/SentinelAdmin/header/BooleanSelector";

import { CropTo } from "../../../../shared/api/agroevidence/agroevidence.types";
import { SortOrderType } from "../../../../shared/api/api.types";
import { AdminFarm } from "../../sensors/admin.sensors.types";

export const NAMESPACE = "sentinel_admin";

export interface SentinelAdminAdvancedFilter {
  "monitoring-gisat"?: BooleanSelectorType;
  "monitoring-planet"?: BooleanSelectorType;
  "management-zones"?: BooleanSelectorType;
  "yield-maps"?: BooleanSelectorType;
  crops?: CropTo[];
}

export interface SentinelAdminUIState {
  farm?: AdminFarm | null;
  order: SortOrderType;
  orderBy: string;
  page: number;
  rowsPerPage: number;
  textFilter: string;
  selected: string[];

  advancedFilter: SentinelAdminAdvancedFilter;
  crops: CropTo[];
  cropsIsFetching: boolean;
}

const initialState: SentinelAdminUIState = {
  page: 0,
  rowsPerPage: ROWS_PER_PAGE,
  order: sortOrder.ASC,
  orderBy: "localName",
  textFilter: "",
  farm: null,
  selected: [],
  advancedFilter: {},
  crops: [],
  cropsIsFetching: false,
};

export default (reducerNamespace: string = NAMESPACE) =>
  (state = initialState, action: AnyAction) => {
    const { namespace } = action;
    if (namespace && namespace !== reducerNamespace) return state;

    switch (action.type) {
      case commonTableTypes.SET_TABLE_SELECTED:
        return {
          ...state,
          selected: action.selected,
        };
      case commonTableTypes.SET_TABLE_PAGE:
        return {
          ...state,
          page: action.page,
        };
      case commonTableTypes.SET_TABLE_ROWS_PER_PAGE:
        return {
          ...state,
          rowsPerPage: action.rowsPerPage,
          page: 0,
        };
      case commonTableTypes.SET_TABLE_ORDER:
        return {
          ...state,
          order: action.order,
          page: 0,
        };
      case commonTableTypes.SET_TABLE_ORDER_BY:
        return {
          ...state,
          orderBy: action.orderBy,
          page: 0,
        };
      case commonFilterTypes.SET_TEXT_FILTER:
        return {
          ...state,
          textFilter: action.textFilter,
          page: 0,
        };
      case types.SET_FARM_FILTER:
        return {
          ...state,
          farm: action.farm,
          page: 0,
        };
      case commonFilterTypes.SET_ADVANCED_FILTER:
        return {
          ...state,
          advancedFilter: action.advancedFilter,
          page: 0,
        };
      case catalogueActions.GET_CROPS_REQUEST:
        return {
          ...state,
          cropsIsFetching: true,
        };
      case GET_ADMIN_CROPS_SUCCESS:
        return {
          ...state,
          cropsIsFetching: false,
          crops: action.payload,
        };
      case GET_ADMIN_CROPS_ERROR:
        return {
          ...state,
          cropsIsFetching: false,
        };
      case GET_ADMIN_CROPS_RESET:
        return {
          ...state,
          cropsIsFetching: false,
          crops: [],
        };
      default:
        return state;
    }
  };
