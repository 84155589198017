import React, { Fragment, useCallback, useEffect, useState } from "react";

import { Grid, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

import {
  getAreaDetail,
  getIsFetchingAreaDetail,
} from "../../../shared/api/irrigation/areas/areas.selectors";
import {
  getDevice,
  getIsFetchingDevice,
  getIsFetchingDeviceValves,
  setDeviceValvesForManualAction,
} from "../../../shared/api/irrigation/devices/devices.selectors";

import { editorSetSelected } from "../../../core/map/actions/editor/editor.actions";
import { fetchArea, resetArea } from "../../actions/areasList.actions";
import {
  updateDeviceName,
  fetchDeviceValves,
  valvesManualControl,
  fetchDevice,
} from "../../actions/devices.actions";
import { setEnlargedVariant } from "../../actions/map.actions";

import CfLoader from "../../../shared/components/common/CfLoader/CfLoader";
import CfStatusPanel from "../../../shared/components/common/CfStatusPanel/CfStatusPanel";
import IrrigationOffIcon from "../../../shared/icons/IrrigationOffIcon";
import { setActivePage, setPrevPage } from "../../actions/commonActions";
import { IrrigationDetailHeader } from "../../components/IrrigationDetailHeader/IrrigationDetailHeader";
import { payloadPrepareStopManualAction } from "../../components/IrrigationDevicesTable/components/IrrigationManualAction.helper";
import { IrrigationManualActionModal } from "../../components/IrrigationDevicesTable/components/IrrigationManualActionModal";
import ValvesStatus from "../../components/ValvesStatus/ValvesStatus";
import IrrigationService from "../../services/Irrigation.service";
import ValvesHistory from "../ValvesHistory/ValvesHistory";
import ValvesPlan from "../ValvesPlan/ValvesPlan";

import { DeviceConfigForm } from "./components/DeviceConfigForm";

import { ValveManualAction } from "../../../shared/api/irrigation/devices/devices.types";

type Props = {
  isMapDisplayed: boolean;
};

const IrrigationDetail = ({ isMapDisplayed }: Props) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const { deviceId, farmId } = useParams<{
    farmId: string;
    deviceId: string;
  }>();
  const dispatch = useDispatch();

  const device = useSelector(getDevice);
  const isFetchingDevice = useSelector(getIsFetchingDevice);
  const area = useSelector(getAreaDetail);
  const isFetchingArea = useSelector(getIsFetchingAreaDetail);
  const deviceValves = useSelector(setDeviceValvesForManualAction);
  const isFetchingDeviceValves = useSelector(getIsFetchingDeviceValves);

  const [showManualActionModal, setShowManualActionModal] = useState(false);
  const [hasClickedToStopValves, setHasClickedToStopValves] = useState(false);
  const [showDeviceConfigForm, setShowDeviceConfigForm] = useState(false);

  useEffect(() => {
    dispatch(setEnlargedVariant(false));
    dispatch(setActivePage(pathname));

    dispatch(editorSetSelected({ id: deviceId }));

    return () => {
      dispatch(setPrevPage(pathname));
      dispatch(resetArea());
    };
  }, [deviceId, dispatch, pathname]);

  const handleContentRefresh = useCallback(() => {
    dispatch(fetchDevice(deviceId));
  }, [deviceId, dispatch]);

  useEffect(() => {
    if (device?.areaId) {
      dispatch(fetchArea(device.areaId));
    }
  }, [device, dispatch]);

  useEffect(() => {
    if (hasClickedToStopValves) {
      const stopManualAction = payloadPrepareStopManualAction(deviceValves);
      dispatch(
        valvesManualControl(deviceId, stopManualAction, handleContentRefresh),
      );
      setHasClickedToStopValves(false);
    }
  }, [
    deviceId,
    deviceValves,
    dispatch,
    handleContentRefresh,
    hasClickedToStopValves,
  ]);

  const handleStopManualAction = (evt: React.SyntheticEvent) => {
    evt.stopPropagation();
    dispatch(fetchDeviceValves(deviceId));
    setHasClickedToStopValves(true);
  };

  const handleOpenManualActionModal = (evt: React.SyntheticEvent) => {
    evt.stopPropagation();
    dispatch(fetchDeviceValves(deviceId));
    setShowManualActionModal(true);
  };

  const handleCloseManualActionModal = () => {
    setShowManualActionModal(false);
  };

  const handleSubmitManualActionModal = (
    startManualAction: ValveManualAction[],
  ) => {
    dispatch(
      valvesManualControl(deviceId, startManualAction, handleContentRefresh),
    );
    setShowManualActionModal(false);
  };

  const toggleEditForm =
    (open: boolean) => (event?: React.KeyboardEvent | React.MouseEvent) => {
      const isKeyboardEvent =
        event &&
        event.type === "keydown" &&
        ["Tab", "Shift"].includes((event as React.KeyboardEvent).key);
      if (isKeyboardEvent) return;

      setShowDeviceConfigForm(open);
    };

  const isActive = IrrigationService.isActive(device);
  const isDataLoading = isFetchingDevice || isFetchingArea;

  return isDataLoading ? (
    <CfLoader />
  ) : (
    <div>
      <IrrigationDetailHeader
        area={area}
        device={device}
        farmId={farmId}
        handleOpenEditForm={toggleEditForm(true)}
        handleOpenManualActionModal={handleOpenManualActionModal}
        handleStopManualAction={handleStopManualAction}
        isActive={isActive}
        updateDeviceName={(deviceId: string, deviceName: string) =>
          dispatch(updateDeviceName(deviceId, deviceName))
        }
      />
      <Grid className={classes.valveStatusWrapper} container>
        <Grid className={classes.valveStatusContent} item xs={12}>
          <ValvesStatus installed={isActive} valvesStatus={device.valves} />
        </Grid>
      </Grid>

      <Grid container>
        <Grid
          item
          lg={isMapDisplayed ? "auto" : 1}
          xl={isMapDisplayed ? "auto" : 2}
        />
        <Grid
          className={classes.content}
          item
          lg={isMapDisplayed ? 12 : 10}
          xl={isMapDisplayed ? 12 : 8}
          xs={12}
        >
          {isActive ? (
            <Fragment>
              <ValvesPlan />
              <ValvesHistory
                activationDate={device.activationDate}
                deviceId={deviceId}
              />
            </Fragment>
          ) : (
            <CfStatusPanel
              icon={IrrigationOffIcon}
              title={<FormattedMessage id="IrrigationDetail.inactiveHeading" />}
              content={
                <FormattedMessage id="IrrigationDetail.notActivatedExplanation" />
              }
            />
          )}
        </Grid>
        <Grid
          item
          lg={isMapDisplayed ? "auto" : 1}
          xl={isMapDisplayed ? "auto" : 2}
        />
      </Grid>
      {showManualActionModal && !isFetchingDeviceValves && (
        <IrrigationManualActionModal
          deviceName={device.name}
          deviceValves={deviceValves}
          onClose={handleCloseManualActionModal}
          onSubmit={handleSubmitManualActionModal}
          showDialog={showManualActionModal}
        />
      )}
      {showDeviceConfigForm && (
        <DeviceConfigForm
          deviceId={deviceId}
          deviceName={device.name}
          onCloseClick={toggleEditForm(false)}
          open={showDeviceConfigForm}
        />
      )}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  valveStatusWrapper: {
    backgroundColor: theme.palette.common.white,
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    padding: "8px 12px",
    justifyContent: "center",
  },
  valveStatusContent: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    fontSize: 13,
    color: theme.palette.grey[500],
  },
  content: {
    padding: "10px 15px",
  },
}));

export { IrrigationDetail };
