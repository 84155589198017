import React from "react";

import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

import { AnyTodo } from "../../../../types";

interface Props {
  defaultValues: AnyTodo | null;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const OutputVoltageRadioGroup = ({ defaultValues, onChange }: Props) => {
  const classes = useStyles();

  const voltageOptions = [6, 9, 12, 24];

  return (
    <FormControl>
      <FormLabel className={classes.radioGroupLabel}>
        <FormattedMessage id="Irrigation.deviceDetail.config.outputVoltage" />
      </FormLabel>
      <RadioGroup
        name="outputVoltage"
        onChange={onChange}
        row
        value={defaultValues}
      >
        {voltageOptions.map((voltage) => (
          <FormControlLabel
            control={<Radio size="small" />}
            key={voltage}
            label={`${voltage} V`}
            value={voltage}
            classes={{
              label: classes.radioGroupLabel,
            }}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export { OutputVoltageRadioGroup };

const useStyles = makeStyles({
  radioGroupLabel: {
    fontSize: 16,
    marginBottom: 0,
  },
});
