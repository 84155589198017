import angular from "angular";

import PrecisionController from "./precision.controller";

const precisionComponent = {
  bindings: {
    farmId: "<",
    farm: "<",
  },
  controller: PrecisionController,
};

export default angular
  .module("app.precision", [])
  .config(config)
  .component("precisionComponent", precisionComponent).name;

config.$inject = ["$stateProvider"];

function config($stateProvider) {
  $stateProvider
    .state("farm.active.precision", {
      url: "/precision-farming",
      views: {
        farm: {
          component: "precisionComponent",
        },
      },
    })
    .state("farm.active.precision.as-applied", {
      url: "/as-applied",
      views: {
        "farm@farm.active": {
          component: "precisionComponent",
        },
      },
    })
    .state("farm.active.precision.as-applied.detail", {
      url: "/:taskDataId/:taskId",
      views: {
        "farm@farm.active": {
          component: "precisionComponent",
        },
      },
    })
    .state("farm.active.precision.services", {
      url: "/services",
      views: {
        "farm@farm.active": {
          component: "precisionComponent",
        },
      },
    })
    .state("farm.active.precision.seasons", {
      url: "/seasons",
      views: {
        "farm@farm.active": {
          component: "precisionComponent",
        },
      },
    })
    .state("farm.active.precision.seasons.detail", {
      url: "/:seasonId",
      views: {
        "farm@farm.active": {
          component: "precisionComponent",
        },
      },
    })
    .state("farm.active.precision.seasons.dashboard", {
      url: "/dashboard",
      views: {
        "farm@farm.active": {
          component: "precisionComponent",
        },
      },
    })
    .state("farm.active.precision.services.detail", {
      url: "/:parcelId",
      abstract: true,
      views: {
        "farm@farm.active": {
          component: "precisionComponent",
        },
      },
    })
    .state("farm.active.precision.services.detail.bio-monitoring", {
      url: "/bio-monitoring",
      views: {
        "farm@farm.active": {
          component: "precisionComponent",
        },
      },
    })
    .state("farm.active.precision.services.detail.soil-samples", {
      url: "/soil-samples",
      views: {
        "farm@farm.active": {
          component: "precisionComponent",
        },
      },
    })
    .state("farm.active.precision.services.detail.yield-Maps", {
      url: "/yield-maps",
      views: {
        "farm@farm.active": {
          component: "precisionComponent",
        },
      },
    })
    .state("farm.active.precision.services.detail.zone-management", {
      url: "/zone-management",
      views: {
        "farm@farm.active": {
          component: "precisionComponent",
        },
      },
    });
}
