/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

type UtilRequiredKeys<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>;

export interface IntegrationTo {
  enabled: boolean;
  email: string;
}

export interface GeoJson {
  type: GeoJsonType;
  coordinates: object[];
}

export interface GeoJsonMultiPolygon {
  type: GeoJsonType;
  coordinates: number[][][][];
  geometries: GeoJson[];
}

export enum GeoJsonType {
  Point = "Point",
  MultiPoint = "MultiPoint",
  LinearRing = "LinearRing",
  LineString = "LineString",
  MultiLineString = "MultiLineString",
  Polygon = "Polygon",
  MultiPolygon = "MultiPolygon",
  GeometryCollection = "GeometryCollection",
}

export interface IndexResponse {
  type?: IndexType;
  /** @format date */
  dateFrom?: string;
  /** @format date */
  dateTo?: string;
  chemicalElement?: string;
  crop?: SatelliteCropListTo;
  zones?: ZoneResponse[];
}

export enum IndexType {
  CAB = "CAB",
  CW = "CW",
  LAI = "LAI",
  HIST = "HIST",
  SAVI = "SAVI",
  BSI = "BSI",
  SAMPLES = "SAMPLES",
}

export interface SatelliteCropListTo {
  name?: string;
  internalId?: string;
  /** @format int32 */
  legislativeCode: number;
}

export interface VariableApplicationPutRequestBody {
  id?: string;
  actionId?: string;
  /** @format date-time */
  createdDate?: string;
  /** @format int32 */
  cropLegCode: number;
  parcelId: string;
  farmId: string;
  /** @format double */
  doseHa: number;
  /** @format double */
  areaHa: number;
  materialId: string;
  /** @format double */
  mmu?: number;
  unit: string;
  type: VariableApplicationType;
  /** @format double */
  accountableN?: number;
  /** @format double */
  p?: number;
  /** @format double */
  k?: number;
  index: IndexResponse;
  zoneType?: VariableApplicationZoneType;
  applicationZones: VariableApplicationZoneExtendedResponse[];
  /** @format double */
  totalDose: number;
}

export enum VariableApplicationType {
  STRONG_ZONES_SEEDING = "STRONG_ZONES_SEEDING",
  WEAK_ZONES_SEEDING = "WEAK_ZONES_SEEDING",
  WEAK_ZONES_FERTILIZATION = "WEAK_ZONES_FERTILIZATION",
  STRONG_ZONES_FERTILIZATION = "STRONG_ZONES_FERTILIZATION",
  RECOMPUTE = "RECOMPUTE",
}

export interface VariableApplicationZoneExtendedResponse {
  variableApplicationId?: string;
  /** @format int32 */
  zoneId: number;
  color: string;
  /** @format double */
  quality: number;
  geometry: GeoJsonMultiPolygon;
  /** @format double */
  areaHa: number;
  /** @format double */
  doseHa: number;
  /** @format double */
  totalDose: number;
  /** @format double */
  accountableN?: number;
  /** @format double */
  p?: number;
  /** @format double */
  k?: number;
  id?: string;
}

export enum VariableApplicationZoneType {
  PLANET_BIOMONITORING_SAVI = "PLANET_BIOMONITORING_SAVI",
  SENTINEL_HUB_MANAGEMENT_ZONES_SAVI = "SENTINEL_HUB_MANAGEMENT_ZONES_SAVI",
}

export interface ZoneResponse {
  /** @format double */
  zoneValue?: number;
  /** @format int32 */
  zoneNumber?: number;
  geometry?: GeoJsonMultiPolygon;
  /** @format double */
  areaHa?: number;
  /** @format double */
  mmuHa?: number;
  color?: string;
}

export interface VariableApplicationResponse {
  id?: string;
  actionId?: string;
  /** @format date-time */
  createdDate?: string;
  /** @format int32 */
  cropLegCode: number;
  parcelId: string;
  farmId: string;
  /** @format double */
  doseHa: number;
  /** @format double */
  areaHa: number;
  materialId: string;
  /** @format double */
  mmu?: number;
  unit: string;
  type: VariableApplicationType;
  /** @format double */
  accountableN?: number;
  /** @format double */
  p?: number;
  /** @format double */
  k?: number;
  index: IndexResponse;
  zoneType?: VariableApplicationZoneType;
  applicationZones: VariableApplicationZoneExtendedResponse[];
  /** @format double */
  totalDose: number;
  /** @format double */
  averageQuality: number;
}

export enum IntegrationType {
  TASKDATA = "TASKDATA",
  AGINTEGRATED = "AGINTEGRATED",
  VARIODOC = "VARIODOC",
}

export interface ParcelsConfigUpdateRequest {
  parcelId: string[];
  historyPotentialEnabled?: boolean;
  managementZonesEnabled?: boolean;
  internalBiomonitoringEnabled?: boolean;
  biomonitoringEnabled?: boolean;
  variableApplicationEnabled?: boolean;
  soilSamplesEnabled?: boolean;
  yieldMapsEnabled?: boolean;
  productDates?: Record<string, ProductDates>;
}

export interface ProductDates {
  /** @format date-time */
  enabledDate?: string;
  /** @format date-time */
  stoppedDate?: string;
}

export interface ParcelConfigUpdateRequest {
  historyPotentialEnabled: boolean;
  biomonitoringEnabled: boolean;
  variableApplicationEnabled: boolean;
  soilSamplesEnabled?: boolean;
}

export interface IntegrationAdminPutTo {
  type: IntegrationType;
  enabled: boolean;
}

export enum CatalogueType {
  EAGRI = "EAGRI",
  GLOBAL = "GLOBAL",
  PPA_SK = "PPA_SK",
}

export interface UpdateRecordsStatusTo {
  status: UpdateStatus;
  /** @format int32 */
  updatedRecords: number;
}

export enum UpdateStatus {
  FINISHED = "FINISHED",
  ERROR = "ERROR",
}

export enum HttpStatus {
  Value100CONTINUE = "100 CONTINUE",
  Value101SWITCHINGPROTOCOLS = "101 SWITCHING_PROTOCOLS",
  Value102PROCESSING = "102 PROCESSING",
  Value103CHECKPOINT = "103 CHECKPOINT",
  Value200OK = "200 OK",
  Value201CREATED = "201 CREATED",
  Value202ACCEPTED = "202 ACCEPTED",
  Value203NONAUTHORITATIVEINFORMATION = "203 NON_AUTHORITATIVE_INFORMATION",
  Value204NOCONTENT = "204 NO_CONTENT",
  Value205RESETCONTENT = "205 RESET_CONTENT",
  Value206PARTIALCONTENT = "206 PARTIAL_CONTENT",
  Value207MULTISTATUS = "207 MULTI_STATUS",
  Value208ALREADYREPORTED = "208 ALREADY_REPORTED",
  Value226IMUSED = "226 IM_USED",
  Value300MULTIPLECHOICES = "300 MULTIPLE_CHOICES",
  Value301MOVEDPERMANENTLY = "301 MOVED_PERMANENTLY",
  Value302FOUND = "302 FOUND",
  Value302MOVEDTEMPORARILY = "302 MOVED_TEMPORARILY",
  Value303SEEOTHER = "303 SEE_OTHER",
  Value304NOTMODIFIED = "304 NOT_MODIFIED",
  Value305USEPROXY = "305 USE_PROXY",
  Value307TEMPORARYREDIRECT = "307 TEMPORARY_REDIRECT",
  Value308PERMANENTREDIRECT = "308 PERMANENT_REDIRECT",
  Value400BADREQUEST = "400 BAD_REQUEST",
  Value401UNAUTHORIZED = "401 UNAUTHORIZED",
  Value402PAYMENTREQUIRED = "402 PAYMENT_REQUIRED",
  Value403FORBIDDEN = "403 FORBIDDEN",
  Value404NOTFOUND = "404 NOT_FOUND",
  Value405METHODNOTALLOWED = "405 METHOD_NOT_ALLOWED",
  Value406NOTACCEPTABLE = "406 NOT_ACCEPTABLE",
  Value407PROXYAUTHENTICATIONREQUIRED = "407 PROXY_AUTHENTICATION_REQUIRED",
  Value408REQUESTTIMEOUT = "408 REQUEST_TIMEOUT",
  Value409CONFLICT = "409 CONFLICT",
  Value410GONE = "410 GONE",
  Value411LENGTHREQUIRED = "411 LENGTH_REQUIRED",
  Value412PRECONDITIONFAILED = "412 PRECONDITION_FAILED",
  Value413PAYLOADTOOLARGE = "413 PAYLOAD_TOO_LARGE",
  Value413REQUESTENTITYTOOLARGE = "413 REQUEST_ENTITY_TOO_LARGE",
  Value414URITOOLONG = "414 URI_TOO_LONG",
  Value414REQUESTURITOOLONG = "414 REQUEST_URI_TOO_LONG",
  Value415UNSUPPORTEDMEDIATYPE = "415 UNSUPPORTED_MEDIA_TYPE",
  Value416REQUESTEDRANGENOTSATISFIABLE = "416 REQUESTED_RANGE_NOT_SATISFIABLE",
  Value417EXPECTATIONFAILED = "417 EXPECTATION_FAILED",
  Value418IAMATEAPOT = "418 I_AM_A_TEAPOT",
  Value419INSUFFICIENTSPACEONRESOURCE = "419 INSUFFICIENT_SPACE_ON_RESOURCE",
  Value420METHODFAILURE = "420 METHOD_FAILURE",
  Value421DESTINATIONLOCKED = "421 DESTINATION_LOCKED",
  Value422UNPROCESSABLEENTITY = "422 UNPROCESSABLE_ENTITY",
  Value423LOCKED = "423 LOCKED",
  Value424FAILEDDEPENDENCY = "424 FAILED_DEPENDENCY",
  Value425TOOEARLY = "425 TOO_EARLY",
  Value426UPGRADEREQUIRED = "426 UPGRADE_REQUIRED",
  Value428PRECONDITIONREQUIRED = "428 PRECONDITION_REQUIRED",
  Value429TOOMANYREQUESTS = "429 TOO_MANY_REQUESTS",
  Value431REQUESTHEADERFIELDSTOOLARGE = "431 REQUEST_HEADER_FIELDS_TOO_LARGE",
  Value451UNAVAILABLEFORLEGALREASONS = "451 UNAVAILABLE_FOR_LEGAL_REASONS",
  Value500INTERNALSERVERERROR = "500 INTERNAL_SERVER_ERROR",
  Value501NOTIMPLEMENTED = "501 NOT_IMPLEMENTED",
  Value502BADGATEWAY = "502 BAD_GATEWAY",
  Value503SERVICEUNAVAILABLE = "503 SERVICE_UNAVAILABLE",
  Value504GATEWAYTIMEOUT = "504 GATEWAY_TIMEOUT",
  Value505HTTPVERSIONNOTSUPPORTED = "505 HTTP_VERSION_NOT_SUPPORTED",
  Value506VARIANTALSONEGOTIATES = "506 VARIANT_ALSO_NEGOTIATES",
  Value507INSUFFICIENTSTORAGE = "507 INSUFFICIENT_STORAGE",
  Value508LOOPDETECTED = "508 LOOP_DETECTED",
  Value509BANDWIDTHLIMITEXCEEDED = "509 BANDWIDTH_LIMIT_EXCEEDED",
  Value510NOTEXTENDED = "510 NOT_EXTENDED",
  Value511NETWORKAUTHENTICATIONREQUIRED = "511 NETWORK_AUTHENTICATION_REQUIRED",
}

export enum MeasurementKind {
  FULL = "FULL",
  BACK_CAMERA = "BACK_CAMERA",
}

export interface MeasurementRequestTo {
  measurementGroupId: string;
  measurementName: string;
  measurementKind: MeasurementKind;
  /** @format double */
  latitude: number;
  /** @format double */
  longitude: number;
  /** Note for measurement. Only for BACK_CAMERA measurement kind. */
  note?: string;
  filename: string;
}

export interface BackCamera {
  measurementId: string;
  highResImageUrl: string;
}

export interface Full {
  measurementId: string;
  highResImageUrl: string;
  spectrometerUrl: string;
  calibrationImageUrl: string;
  colorImage1Url: string;
  colorImage2Url: string;
  colorImage3Url: string;
  colorImage4Url: string;
  colorImage5Url: string;
  colorImage6Url: string;
  colorImage7Url: string;
  colorImage8Url: string;
  colorImage9Url: string;
  colorImage10Url: string;
  colorImage11Url: string;
  colorImage12Url: string;
}

export type PresignedUrlResponseTo = (
  | UtilRequiredKeys<Full, "measurementId" | "highResImageUrl">
  | UtilRequiredKeys<BackCamera, "measurementId" | "highResImageUrl">
) & {
  measurementId: string;
  highResImageUrl: string;
};

export type Unit = object;

export interface MeasurementGroupRequestTo {
  name: string;
}

export interface MeasurementGroupResponseTo {
  id: string;
  name: string;
  /** @format date */
  lastMeasurementCreated?: string;
  /** @format int32 */
  countMeasurements: number;
}

export interface FarmUpdateResultTo {
  permanentlyRetired: RetiredParcelTo[];
  historizations: ParcelHistorizationSatelliteTo[];
}

export interface GeoJsonPolygon {
  type: GeoJsonType;
  coordinates: number[][][];
  geometries: GeoJson[];
}

export interface NewParcelTo {
  parcelId: string;
  geometry: GeoJsonPolygon;
}

export interface ParcelHistorizationSatelliteTo {
  retiredParcels: RetiredParcelTo[];
  newParcels: NewParcelTo[];
}

export interface RetiredParcelTo {
  parcelId: string;
}

export interface GeoJsonPoint {
  type: GeoJsonType;
  coordinates: number[];
}

export interface SoilSamplesRequestBody {
  farmId: string;
  parcelId: string;
  samples: SoilSamplesSample[];
  zones: SoilSamplesZone[];
}

export interface SoilSamplesSample {
  sample: string;
  /** @format double */
  value: number;
  geometry: GeoJsonPoint;
}

export interface SoilSamplesZone {
  sample: string;
  /** @format int32 */
  zoneStrength: number;
  geometry: GeoJson;
}

export interface SampleUploadResult {
  element: string;
  /** @format double */
  value: number;
}

export interface SampleZoneUploadResult {
  element: string;
  /** @format int32 */
  zoneStrength: number;
}

export interface SamplesUploadResult {
  parcelId: string;
  /** @format int32 */
  soilSamplesInternalId: number;
  samples: SampleUploadResult[];
  zones: SampleZoneUploadResult[];
}

export enum MeasurementElement {
  CLAY = "CLAY",
  PH = "PH",
  COX = "COX",
  ORGANIC = "ORGANIC",
  N = "N",
  K = "K",
  CA = "CA",
  MG = "MG",
  S = "S",
  FE = "FE",
  P = "P",
  SUS = "SUS",
  DUST = "DUST",
  SAND = "SAND",
}

export interface MeasurementPredictionRequest {
  measurementId: string;
  source: MeasurementSource;
  predictions: MeasurementPredictionRequestPrediction[];
}

export interface MeasurementPredictionRequestPrediction {
  element: MeasurementElement;
  /** @format double */
  value: number;
}

export enum MeasurementSource {
  SPECTROMETER = "SPECTROMETER",
  CAMERA = "CAMERA",
}

export interface ReloadResultTo {
  ok: string[];
  failed: string[];
}

export interface CropGrowthVariabilityRequest {
  /** @format double */
  mmuHa?: number;
  /** @format double */
  averageQuality?: number;
  managementZonesImgPath?: string;
  managementZonesTiffPath?: string;
  data: GrowthVariabilityDataRequest[];
  zones: ZoneResponse[];
  crop: string;
}

export interface GrowthVariabilityDataRequest {
  /** @format date */
  date?: string;
  /** @format double */
  min?: number;
  /** @format double */
  q1?: number;
  /** @format double */
  median?: number;
  /** @format double */
  q3?: number;
  /** @format double */
  max?: number;
}

export interface HistoryPotentialRequest {
  parcelId?: string;
  farmId?: string;
  cropGrowthVariabilities: CropGrowthVariabilityRequest[];
  historySnaps: HistorySnapRequest[];
}

export interface HistorySnapRequest {
  crop: string;
  involvementImgPath?: string;
  managementZoneImgPath?: string;
  /** @format date */
  date: string;
}

export interface VariodocCreateTo {
  username: string;
  password: string;
}

export interface MachineUploadRequestTo {
  /** @format int32 */
  id: number;
  name: string;
}

export interface MachinesUploadRequestTo {
  machines: MachineUploadRequestTo[];
}

export interface IntegrationEnableTo {
  email: string;
  name: string;
}

export enum TaskDataImportError {
  INVALID_ISO_XML = "INVALID_ISO_XML",
  CONFLICT = "CONFLICT",
}

export interface TaskDataImportResponseFileTo {
  file: string;
  success: boolean;
  error?: TaskDataImportError;
}

export interface TaskDataImportResponseTo {
  file: TaskDataImportResponseFileTo[];
}

export interface TaskDataDownloadTo {
  tasks: number[];
}

export interface ParcelProduct {
  parcelId: string;
  productType: SatelliteProduct;
  /** @format date-time */
  createdDate?: string;
  /** @format date-time */
  receivedDate?: string;
  callbackUrl?: string;
  /** @format date-time */
  stoppedDate?: string;
  /** @format date-time */
  callbackExpiration?: string;
}

export enum SatelliteProduct {
  HISTORY_POTENTIAL = "HISTORY_POTENTIAL",
  BIOPHYSICAL_MONITORING = "BIOPHYSICAL_MONITORING",
  VARIABLE_APPLICATION = "VARIABLE_APPLICATION",
  SOIL_SAMPLES = "SOIL_SAMPLES",
}

export enum BiomonitoringResponseStatus {
  OK = "OK",
  NOT_FOUND = "NOT_FOUND",
  ERROR = "ERROR",
}

export interface ParcelConfigInsertRequest {
  parcelId: string;
  historyPotentialEnabled: boolean;
  biomonitoringEnabled: boolean;
  variableApplicationEnabled: boolean;
  soilSamplesEnabled?: boolean;
}

export enum PrecisionFarmingProduct {
  PLANET_BIOMONITORING_SAVI = "PLANET_BIOMONITORING_SAVI",
  SENTINEL_HUB_MANAGEMENT_ZONES_SAVI = "SENTINEL_HUB_MANAGEMENT_ZONES_SAVI",
  GISAT_HISTORY_POTENTIAL = "GISAT_HISTORY_POTENTIAL",
  GISAT_BIOPHYSICAL_MONITORING = "GISAT_BIOPHYSICAL_MONITORING",
  INTERNAL_SOIL_SAMPLES = "INTERNAL_SOIL_SAMPLES",
  VARIABLE_APPLICATION = "VARIABLE_APPLICATION",
}

export interface ManagementZoneAuditDto {
  parcelId: string;
  farmId: string;
  /** @format date-time */
  enabled: string;
  /** @format date-time */
  disabled?: string;
}

export interface InternalBiomonitoringAuditTo {
  parcelId: string;
  farmId: string;
  /** @format date-time */
  enabled: string;
  /** @format date-time */
  disabled?: string;
}

export interface TaskDataPatchTaskDataTo {
  name?: string;
  archived?: boolean;
}

export interface TaskDataPatchTo {
  tasks: Record<string, TaskDataPatchTaskDataTo>;
}

export interface MeasurementResponseTo {
  id: string;
  measurementGroupId: string;
  measurementName: string;
  measurementKind: MeasurementKind;
  /** @format date */
  created: string;
  /** @format double */
  latitude: number;
  /** @format double */
  longitude: number;
  note?: string;
  photoUrl: string;
  predictions: PredictionTo[];
}

export interface PredictionTo {
  element: MeasurementElement;
  source: MeasurementSource;
  /** @format double */
  value: number;
}

export enum ExportType {
  COMMON = "COMMON",
  TRIMBLE = "TRIMBLE",
}

export interface AgIntegratedJobsTo {
  /** @format int32 */
  jobId: number;
  /** @format int32 */
  parentId?: number;
  type: JobType;
  name?: string;
  mapType?: string;
  /** @format date-time */
  exportDate: string;
  status: string;
}

export enum JobType {
  WRITER = "WRITER",
  UPLOADER = "UPLOADER",
}

export interface VariableActionZonesTo {
  type: VariableApplicationZoneType;
  /** @format date */
  dateFrom?: string;
  /** @format date */
  dateTo?: string;
  zones: ZoneDto[];
}

export interface ZoneDto {
  index: string;
  /** @format double */
  areaHa: number;
  /** @format double */
  quality?: number;
  color: string;
  geometry: GeoJsonMultiPolygon;
}

export interface IntegrationSettingsTo {
  url: string;
}

export interface MachineTo {
  /** @format int32 */
  id: number;
  name: string;
}

export interface TaskDataFilterCriteriaTo {
  search?: string;
  showArchived: boolean;
}

export interface TaskDataListTaskTo {
  id: string;
  name?: string;
  /** @format int32 */
  duration: number;
  /** @format date-time */
  dateFrom?: string;
  /** @format date-time */
  dateTo?: string;
}

export interface TaskDataListTo {
  /** @format int32 */
  id: number;
  name: string;
  filename: string;
  /** @format date-time */
  dateFrom?: string;
  /** @format date-time */
  dateTo?: string;
  /** @format int32 */
  duration: number;
  tasks: TaskDataListTaskTo[];
  source: TaskSource;
  archived: boolean;
  /** @format date-time */
  created: string;
}

export enum TaskSource {
  MANUAL = "MANUAL",
  VARIODOC = "VARIODOC",
}

export interface TaskDataAttributeTo {
  attribute: string;
  name: string;
  codes: string[];
}

export interface TaskDataCulturalPracticeTo {
  id: string;
  name?: string;
}

export interface TaskDataDeviceTo {
  id: string;
  name?: string;
  clientName: string;
  serialNumber?: string;
}

export interface TaskDataPartfieldTo {
  id: string;
  name: string;
  /**
   * Size of the partfield in m2
   * @format int32
   */
  area: number;
}

export interface TaskDataProductTo {
  id: string;
  name?: string;
}

export interface TaskDataTaskTo {
  id: string;
  task: TaskDataListTo;
  name?: string;
  /** @format int32 */
  duration: number;
  /** @format date-time */
  dateFrom?: string;
  /** @format date-time */
  dateTo?: string;
  attributes: TaskDataAttributeTo[];
  product: TaskDataProductTo[];
  partfield?: TaskDataPartfieldTo;
  culturalPractice?: TaskDataCulturalPracticeTo;
  device: TaskDataDeviceTo[];
  worker?: TaskDataWorkerTo;
  source: TaskSource;
  /** @format date-time */
  created: string;
}

export interface TaskDataWorkerTo {
  id: string;
  name?: string;
}

export enum TaskAttribute {
  YIELD = "YIELD",
  MOISTURE = "MOISTURE",
  APPLIED_DOSE = "APPLIED_DOSE",
  PRESCRIPTION_DOSE = "PRESCRIPTION_DOSE",
  ACTUAL_SPEED = "ACTUAL_SPEED",
  FUEL_CONSUMPTION_TIME = "FUEL_CONSUMPTION_TIME",
  ENGINE_RPM = "ENGINE_RPM",
  TOTAL_FUEL_CONSUMPTION = "TOTAL_FUEL_CONSUMPTION",
  TOTAL_VOLUME = "TOTAL_VOLUME",
  TOTAL_DISTANCE = "TOTAL_DISTANCE",
  TOTAL_AREA = "TOTAL_AREA",
}

export interface TaskDataTimelineAttributeTo {
  /** @format double */
  value: number;
  unit?: string;
}

export interface TaskDataTimelineTo {
  /** @format date-time */
  time: string;
  /** @format double */
  latitude: number;
  /** @format double */
  longitude: number;
  attribute?: TaskDataTimelineAttributeTo;
}

export interface TaskDataReportAttributeTo {
  /** @format double */
  value?: number;
  unit?: string;
}

export interface TaskDataReportTo {
  report: Record<string, TaskDataReportAttributeTo>;
}

export interface SoilSamplesResponse {
  farmId: string;
  parcelId: string;
  samples: SoilSamplesSample[];
  zones: SoilSamplesZone[];
}

export enum ParcelProductStatus {
  INACTIVE = "INACTIVE",
  ACTIVE = "ACTIVE",
  AWAITING_USER = "AWAITING_USER",
  AWAITING_DATA = "AWAITING_DATA",
  HISTORICAL = "HISTORICAL",
}

export interface ParcelProductTo {
  historyPotentialStatus: ParcelProductStatus;
  biomonitoringStatus: ParcelProductStatus;
  internalBiomonitoringStatus: ParcelProductStatus;
  variableApplicationStatus: ParcelProductStatus;
  managementZonesStatus: ParcelProductStatus;
}

export interface ParcelProductDto {
  parcelId: string;
  productType: PrecisionFarmingProduct;
  /** @format date-time */
  createdDate?: string;
  /** @format date-time */
  enabledDate?: string;
  /** @format date-time */
  receivedDate?: string;
  callbackUrl?: string;
  /** @format date-time */
  stoppedDate?: string;
  /** @format date-time */
  callbackExpiration?: string;
}

export interface SatelliteParcel {
  id: string;
  localName: string;
  blockNr: string;
  /** @format double */
  area: number;
  status: string;
  biomonitoring: boolean;
  internalBiomonitoring: boolean;
  variableApplication: boolean;
  historyPotential: boolean;
  managementZones: boolean;
  soilSamples: boolean;
  activeBiomonitoringIndices: IndexType[];
  products: ParcelProductDto[];
}

export interface ExtentCoordinates {
  minX: string;
  minY: string;
  maxX: string;
  maxY: string;
}

export interface ManagementZoneResponseDto {
  parcelId: string;
  farmId: string;
  /** @format date-time */
  validity: string;
  geotiffUrl: string;
  rawGeotiffUrl?: string;
  rasterUrl: string;
  rasterCoordinates: ExtentCoordinates;
  savi: ZoneDto[];
  /** @format double */
  averageQuality: number;
  /** @format double */
  totalAreaHa: number;
}

export interface CropGrowthVariabilityResponse {
  /** @format double */
  mmuHA?: number;
  /** @format double */
  averageQuality?: number;
  managementZonesImgPath?: string;
  managementZonesTiffPath?: string;
  data: GrowthVariabilityDataResponse[];
  zones: ZoneResponse[];
  crop: SatelliteCropListResponse;
}

export interface GrowthVariabilityDataResponse {
  /** @format date */
  date?: string;
  /** @format double */
  min?: number;
  /** @format double */
  q1?: number;
  /** @format double */
  median?: number;
  /** @format double */
  q3?: number;
  /** @format double */
  max?: number;
}

export interface HistoryPotentialResponse {
  parcelId?: string;
  farmId?: string;
  cropGrowthVariabilities: CropGrowthVariabilityResponse[];
  historySnaps: HistorySnapResponse[];
}

export interface HistorySnapResponse {
  crop: SatelliteCropListResponse;
  involvementImgPath?: string;
  managementZoneImgPath?: string;
  /** @format date */
  date: string;
}

export interface SatelliteCropListResponse {
  /** @format int32 */
  legislativeCode: number;
  name?: string;
}

export interface BiomonitoringIntervalResponse {
  /** @format date */
  dateFrom: string;
  /** @format date */
  dateTo: string;
  crop: SatelliteCropListTo;
  snapshots: BiomonitoringSnapResponse[];
  status?: string;
}

export interface BiomonitoringSnapResponse {
  /** @format double */
  value?: number;
  indexType: string;
  methodologyType?: string;
  imgPath?: string;
  fileUrl: string;
  zonedTiffUrl?: string;
  /** @format double */
  zonedMmuHa?: number;
  zones: ZoneResponse[];
  imgExtent?: EnvelopeResponse;
}

export interface EnvelopeResponse {
  /** @format double */
  minX: number;
  /** @format double */
  minY: number;
  /** @format double */
  maxX: number;
  /** @format double */
  maxY: number;
}

export enum BiomonitoringIndexType {
  SAVI = "SAVI",
  RGB = "RGB",
}

export interface CropIntervalTo {
  crop?: SatelliteCropListTo;
  /** @format date */
  to?: string;
  /** @format date */
  from: string;
}

export interface BiomonitoringIndexRecordDto {
  id: string;
  type: BiomonitoringIndexType;
  /** @format date */
  dateFrom: string;
  /** @format date */
  dateTo: string;
  rasterUrl: string;
  rasterCoordinates?: GeoJsonPolygon;
  imgExtent?: EnvelopeResponse;
  zones: BiomonitoringZoneDto[];
  /** @format double */
  average?: number;
}

export interface BiomonitoringIndexRecordsDto {
  /** @format date */
  validity: string;
  records: BiomonitoringIndexRecordDto[];
}

export interface BiomonitoringZoneDto {
  index: string;
  geometry: GeoJsonMultiPolygon;
  /** @format double */
  areaHa: number;
  color: string;
}

export interface PagingFilter {
  /** @format int32 */
  page?: number;
  /** @format int32 */
  "per-page"?: number;
  "sort-col"?: string;
  "sort-dir"?: string;
  /** @format int32 */
  pageOffset: number;
  /** @format int32 */
  pageSize?: number;
  sortProperty?: string;
  isAscending: boolean;
  language?: string;
  /** @format int32 */
  pageNumber: number;
}

export interface ParcelDataTo {
  parcelId: string;
  farmId: string;
  /** @format double */
  originalArea: number;
  historyPotentialEnabled: boolean;
  managementZonesEnabled: boolean;
  internalBiomonitoringEnabled: boolean;
  biomonitoringEnabled: boolean;
  variableApplicationEnabled: boolean;
  yieldMapsEnabled: boolean;
  name?: string;
  blockNr?: string;
  /** @format double */
  area?: number;
  products?: ParcelProductDto[];
  cropName?: string;
}

export interface FarmStatistics {
  /** @format int32 */
  monitoringGisat: number;
  /** @format double */
  monitoringGisatArea: number;
  /** @format int32 */
  monitoringPlanet: number;
  /** @format double */
  monitoringPlanetArea: number;
  /** @format int32 */
  managementZones: number;
  /** @format double */
  managementZonesArea: number;
  /** @format int32 */
  growthPotential: number;
  /** @format double */
  growthPotentialArea: number;
  /** @format int32 */
  pfParcelsCount: number;
  /** @format double */
  pfParcelsArea: number;
  /** @format int32 */
  allParcelsCount: number;
  /** @format double */
  allParcelsArea: number;
  /** @format int32 */
  yieldMaps: number;
  /** @format double */
  yieldMapsArea: number;
}

export interface ManagementZoneAudit {
  id?: string;
  parcelId: string;
  farmId: string;
  /** @format date-time */
  created?: string;
  /** @format date-time */
  enabled: string;
  /** @format date-time */
  disabled?: string;
  /** @format date-time */
  lastSaviIndex?: string;
  /** @format date-time */
  lastBarrenSoilIndex?: string;
}

export interface IntegrationAdminTo {
  enabled: boolean;
}

export interface InternalCropTo {
  id: string;
  externalId: string;
  name: string;
  pfCode?: string;
}

export interface InternalBiomonitoringAudit {
  id?: string;
  parcelId: string;
  farmId: string;
  /** @format date-time */
  created?: string;
  /** @format date-time */
  enabled: string;
  /** @format date-time */
  disabled?: string;
}

export interface TaskDataDeleteTo {
  task: number[];
}
