import React, {
  Fragment,
  ReactNode,
  useState,
  useEffect,
  useCallback,
} from "react";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Theme } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

import {
  RestrictionTo,
  RestrictionType,
} from "../../../../../generated/api/agroevidence";
import CfWarningCard from "../../../../../shared/components/common/CfWarningCard/CfWarningCard";
import { NewSubtractableAreaDialog } from "../../containers/NewSubractableAreaDialog/NewSubtractableAreaDialog";

import { SubtractionResponse } from "../../../../../shared/api/agroevidence/agroevidence.types";

type Props = {
  children: ReactNode;
  maxValue: number;
  isEditing: boolean;
  subtractableAreas: SubtractionResponse[];
  subtractableAreaType: RestrictionType;
  handleAdd?: (sa: RestrictionTo) => void;
  setSubtractableAreasWarning?: () => void;
  resetSubtractableAreasWarning?: () => void;
  subtractableChecked?: number;
};

const ActionParcelSubtractableAreasSection = ({
  children,
  handleAdd,
  isEditing,
  maxValue,
  resetSubtractableAreasWarning = () => {},
  setSubtractableAreasWarning = () => {},
  subtractableAreaType,
  subtractableAreas,
  subtractableChecked,
}: Props) => {
  const classes = useStyles();
  const [newAreaDialogOpen, setNewAreaDialogOpen] = useState(false);
  const [isSubtractableAreasWarning, setIsSubtractableAreasWarning] =
    useState(false);

  const resetSubtractableAreasWarningCallback = useCallback(() => {
    resetSubtractableAreasWarning();
  }, [resetSubtractableAreasWarning]);

  const setSubtractableAreasWarningCallback = useCallback(() => {
    setSubtractableAreasWarning();
  }, [setSubtractableAreasWarning]);

  useEffect(() => {
    if (subtractableChecked !== undefined && subtractableAreas) {
      const maxSubtractableValue = Math.max(
        ...subtractableAreas
          .filter((area) => area.isMaterial)
          .map((area) => area.value),
      );

      if (subtractableChecked < maxSubtractableValue) {
        setIsSubtractableAreasWarning(true);
        setSubtractableAreasWarningCallback();
      } else {
        setIsSubtractableAreasWarning(false);
        resetSubtractableAreasWarningCallback();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subtractableAreas, subtractableChecked]);

  return (
    <Fragment>
      <div className={classes.row}>
        <div className={classes.heading}>
          <FormattedMessage
            id={`SubtractableAreaDialog.${subtractableAreaType.toLowerCase()}`}
          />
        </div>
        {handleAdd && (
          <IconButton
            aria-label={`add ${subtractableAreaType.toLowerCase()} subtraction`}
            className={classes.button}
            disabled={!isEditing}
            onClick={() => setNewAreaDialogOpen(true)}
            size="large"
          >
            <AddCircleIcon />
          </IconButton>
        )}
      </div>
      {children}
      {isSubtractableAreasWarning && (
        <CfWarningCard customClasses={classes.warningCard}>
          <FormattedMessage id="Eph.parcelsControl.subtractableAreasSectionWarning" />
        </CfWarningCard>
      )}
      {newAreaDialogOpen && (
        <NewSubtractableAreaDialog
          handleClose={() => setNewAreaDialogOpen(false)}
          maxValue={maxValue}
          opened={newAreaDialogOpen}
          subtractableAreas={subtractableAreas}
          subtractableAreaType={subtractableAreaType}
          onAccept={(value: RestrictionTo) => {
            handleAdd && handleAdd(value);
            setNewAreaDialogOpen(false);
          }}
        />
      )}
    </Fragment>
  );
};

export { ActionParcelSubtractableAreasSection };

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    color: theme.palette.grey[400],
  },
  heading: {
    fontSize: 14,
    fontWeight: 500,
    marginLeft: 10,
  },
  row: {
    display: "flex",
    alignItems: "center",
  },
  warningCard: {
    display: "inline-flex",
    justifyContent: "left",
  },
}));
