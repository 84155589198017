import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { HistoryPrevState } from "./types";

const useHistoryPreviousState = () => {
  const location = useLocation<HistoryPrevState>();

  const [previousState, setPreviousState] = useState<HistoryPrevState>({});

  useEffect(() => {
    setPreviousState({
      previousLocationPathname: location.state?.previousLocationPathname,
      previousSeasonId: location.state?.previousSeasonId,
    });
  }, []);

  return previousState;
};

export { useHistoryPreviousState };
