/**
 * Farm module, displays farm-related data
 */

import angular from "angular";
import head from "lodash/head";
import Intercom from "../../shared/services/Intercom.service";

import tpl from "./farm.html";
import FarmController from "./farm.controller";

export default angular
  .module("app.components.farm", [])
  .controller("FarmController", FarmController)
  .config(config).name;

/* @ngInject */
function config($stateProvider, $urlRouterProvider, $urlServiceProvider) {
  $urlRouterProvider.when("/farm/:farmId/", "/farm/:farmId/map");
  $urlRouterProvider.when("/farm/:farmId", "/farm/:farmId/map");

  // https://stackoverflow.com/a/44681965
  $urlServiceProvider.config.strictMode(false);

  $stateProvider
    .state("farm", {
      url: "/farm",
      views: {
        app: {
          template: '<div class="full-height" ui-view="active"></div>',
        },
      },
      resolve: {
        farms(FarmsResource, $state) {
          return FarmsResource.getFarms()
            .then((farms) => farms)
            .catch(() => {
              $state.go("login", { isApiError: true });
            });
        },
      },
      onEnter(farms, $state) {
        if (!farms || _.isEmpty(farms)) {
          return $state.go("signupImport");
        }
      },
      redirectTo(trans) {
        const injector = trans.injector();

        return injector.getAsync("$stateParams").then(($stateParams) =>
          injector.getAsync("farms").then((farms) => {
            if (farms && farms.length && !$stateParams.farmId) {
              return {
                state: "farm.active.map",
                params: { farmId: head(farms).id },
              };
            }
          }),
        );
      },
    })
    .state("farm.active", {
      abstract: true,
      url: "/:farmId",
      views: {
        active: {
          controller: "FarmController as FarmCtrl",
          template: tpl,
        },
      },
      params: {
        isImpersEnabled: false,
      },
      resolve: {
        farmId($stateParams) {
          return $stateParams.farmId;
        },
        farm(farms, farmId, FarmsResource, FarmStorage, $state) {
          const currFarm = _.find(farms, (obj) => obj.id === farmId);
          if (!currFarm) {
            return $state.go("login");
          }

          FarmsResource.getFarmSettings(currFarm.id).then((res) => {
            FarmStorage.setFarmSettings({
              eagriSettingsEnabled: res.eagriSettingsEnabled,
            });
          });

          return FarmsResource.getFarm(currFarm.id).then((res) => {
            const farm = res.plain ? res.plain() : res;

            const {
              id,
              name,
              customer: { schemaName, countryCode, id: customerId },
              code,
            } = farm;

            Intercom.update({
              companies: [
                {
                  company_id: id,
                  name,
                  "Schema Name": schemaName,
                  "Farm Code": code,
                  "Country Code": countryCode,
                  "Customer Id": customerId,
                },
              ],
            });

            FarmStorage.setFarm(farm);
            return farm;
          });
        },
      },
    });
}
