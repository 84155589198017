import React from "react";
import { useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  DashboardParcelTo,
  DashboardSeasonTo,
} from "../../../../../generated/api/satellite";
import { YieldPlaceholder } from "./YieldPlaceholder";
import { YieldInput } from "../../../../components/YieldInput";
import { UseMutationResult } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

type Props = {
  parcel: DashboardParcelTo;
  season: DashboardSeasonTo;
  seasonParcelMutation: UseMutationResult<
    AxiosResponse<DashboardSeasonTo, unknown>,
    Error,
    unknown
  >;
};

const YieldCell = ({ parcel, season, seasonParcelMutation }: Props) => {
  const classes = useYieldInputStyles();

  const [edit, setEdit] = useState(false);

  const handleChange = (e: React.SyntheticEvent, newYield?: number) => {
    e.stopPropagation();

    seasonParcelMutation.mutate({
      cropInternalId: parcel.cropInternalId,
      cropName: parcel.cropName,
      parcelId: parcel.parcelId,
      seasonId: season.seasonId,
      yieldInTonnes: newYield,
    });
  };

  let Jsx = null;
  if (edit) {
    Jsx = (
      <YieldInput
        classes={classes}
        handleChange={handleChange}
        setEdit={setEdit}
        value={parcel.yieldInTonnes}
      />
    );
  } else if (!edit) {
    Jsx = <YieldPlaceholder onClick={() => setEdit(true)} parcel={parcel} />;
  }
  return Jsx;
};

const useYieldInputStyles = makeStyles(() => ({
  formControl: {
    maxWidth: "14ch",
  },
  input: {
    textAlign: "end",
    height: 17,
  },
}));

export { YieldCell };
