import React from "react";

import createHistory from "history/createBrowserHistory";
import moment from "moment";
import { createRoot } from "react-dom/client";

import Localization, {
  LANGUAGES,
} from "../../shared/services/Localization.service";

import NavbarWrapper from "./containers/NavbarWrapper/NavbarWrapper";

/* @ngInject */
export default function NavbarController(
  $element,
  $scope,
  $state,
  $transitions,
  $rootScope,
  BACKEND_OPTIONS,

  Auth,
  UserStorage,
  ResponseErrorInterceptor,
  FarmsStorage,
  FarmStorage,
  PRODUCT_FRUITS_ID,
) {
  let root;
  const props = {
    langId: Localization.getLang(),
    farm: this.farm,
    currState: $state.current.name,
    logout: Auth.logout,
    user: UserStorage.getUser(),
    langs: LANGUAGES,
    farms: FarmsStorage.getFarms(),
    farmSettings: FarmStorage.getFarmSettings(),
    ngSwitchFarm: (farmId) => {
      this.switchFarm({ farmId });
    },
    ngImpersEnable: this.impersEnable,
    productFruitsId: PRODUCT_FRUITS_ID,
    isAdmin: (() => {
      const user = UserStorage.getUser();
      const authorities = user.authorities;
      return authorities.includes("ROLE_ADMIN");
    })(),
  };

  let history = null;

  const unlistenTranslateChange = $rootScope.$on(
    "$translateChangeSuccess",
    () => {
      renderWithLang();
    },
  );

  const deregisterOnTransitionSuccess = $transitions.onSuccess(
    {},
    (transition) => {
      props.currState = transition.to().name;
      renderWithLang();
    },
  );

  $scope.$on("$destroy", () => {
    unlistenTranslateChange();
    deregisterOnTransitionSuccess();
    root.unmount();
  });

  this.$onInit = () => {
    setTimeout(() => {
      history = createHistory();
      renderWithLang();
    }, 0);
  };

  const renderWithLang = () => {
    const langId = Localization.getLang();
    moment.locale(langId);
    render();
  };

  function render() {
    $element[0].click();
    root = createRoot($element[0]);
    root.render(<NavbarWrapper history={history} ngProps={props} />);
  }
}
