import React, { Fragment, useEffect, useState } from "react";

import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import isEmpty from "lodash/isEmpty";
import { FormattedMessage, FormattedDate } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { getStoresMaterialType } from "../../list/selectors/stores.selectors";
import {
  getStoreItemById,
  getStoreFromFilter,
  getStoreToFilter,
  getStoreUnitId,
  getStoreAmount,
  getApiError,
} from "../selectors/store.selectors";

import {
  fetchStore,
  saveTransaction,
  updateTransaction,
  deleteTransaction,
  setStoreFromFilter,
  setStoreToFilter,
  updateStoreViewData,
  exportStore,
} from "../actions/store.actions";

import { resetStores } from "../../../../shared/api/stores/stores/stores.api";
import CfBackButton from "../../../../shared/components/common/CfBackButton/CfBackButton";
import CfDateFilter from "../../../../shared/components/common/CfDateFilter/CfDateFilter";
import CfDialog from "../../../../shared/components/common/CfDialog/CfDialog";
import CfErrorPage from "../../../../shared/components/common/CfErrorPage/CfErrorPage";
import PageHeader from "../../../../shared/components/common/PageHeader/PageHeader";
import { TABS } from "../../list/components/StoresTabs";
import { StoreExport } from "../../shared/components/StoreExport";
import { StoreFabButton } from "../../shared/components/StoreFabButton";
import { StoreMovementDialog } from "../../shared/components/StoreMovementDialog";
import { StoreDetailHeading } from "../components/StoreDetailHeading";
import StoreUnitSwitch from "../components/StoreUnitSwitch";

import { TransactionsTable } from "./TransactionsTable";

const KEYS = {
  DELETE: "delete",
  CREATE: "create",
};

const ACTUAL_TAB = {
  [TABS.fertilizers.materialTypeId]: TABS.fertilizers.url,
  [TABS.chemistries.materialTypeId]: TABS.chemistries.url,
  [TABS.seeds.materialTypeId]: TABS.seeds.url,
};

const Store = ({ ngGoToAction }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { farmId, storeId } = useParams();

  const storeItem = useSelector((state) => getStoreItemById(state, storeId));
  const dateFrom = useSelector(getStoreFromFilter);
  const dateTo = useSelector(getStoreToFilter);
  const amount = useSelector(getStoreAmount);
  const unitId = useSelector(getStoreUnitId);
  const error = useSelector(getApiError);
  const materialTypeId = useSelector(getStoresMaterialType);

  const [state, setState] = useState({
    [KEYS.DELETE]: false,
    [KEYS.CREATE]: false,
    transaction: {},
  });

  useEffect(() => {
    dispatch(fetchStore(farmId, storeId));

    return () => {
      dispatch(resetStores());
    };
  }, [dispatch, farmId]);

  const handleDialogOpen = (key, transaction = {}) => {
    setState({
      [key]: true,
      transaction,
    });
  };

  const handleDialogClose = (key) => {
    setState({
      [key]: false,
      transaction: {},
    });
  };

  const handleDialogAccept = (key, dto = {}) => {
    handleDialogClose(key);
    switch (key) {
      case KEYS.DELETE:
        return dispatch(
          deleteTransaction(state.transaction.id, storeItem.id, farmId),
        );
      case KEYS.CREATE:
        if (!isEmpty(state.transaction)) {
          return dispatch(updateTransaction(dto, storeItem.id, farmId));
        }
        return dispatch(saveTransaction(dto, storeItem.id, farmId));

      default:
        throw new Error("Unrecognized store action");
    }
  };

  return (
    <CfErrorPage error={error}>
      <div className={classes.wrapper}>
        <PageHeader
          classes={{ header: classes.header }}
          actionButtons={
            <Fragment>
              <StoreExport
                storeId={storeItem?.id}
                handleExport={(format, storeId) =>
                  dispatch(exportStore(format, storeId))
                }
              />
              <StoreFabButton callback={() => handleDialogOpen(KEYS.CREATE)} />
            </Fragment>
          }
          backButton={
            <CfBackButton
              translId="Stores.backToStorages"
              onClick={() =>
                history.push(
                  `/farm/${farmId}/stores/${ACTUAL_TAB[materialTypeId]}`,
                )
              }
            />
          }
          heading={
            storeItem && storeItem.material ? (
              <StoreDetailHeading item={storeItem} />
            ) : null
          }
        />
        <div>
          <Grid
            className={classes.filtersContainer}
            container
            justifyContent="center"
            spacing={2}
          >
            <Grid item lg={2} sm={3} xs={12}>
              <StoreUnitSwitch
                amount={amount}
                farmId={farmId}
                item={storeItem}
                unitId={unitId}
                onStoreUnitChange={(
                  amount,
                  fromUnitId,
                  toUnitId,
                  farmId,
                  storeId,
                ) =>
                  dispatch(
                    updateStoreViewData(
                      amount,
                      fromUnitId,
                      toUnitId,
                      farmId,
                      storeId,
                    ),
                  )
                }
              />
            </Grid>
            <Grid item lg={2} sm={3} xs={12}>
              <CfDateFilter
                label={<FormattedMessage id="common.date-from" />}
                name="store-filter-from"
                testId="store-filter-from"
                value={dateFrom}
                onFilterChange={(val) =>
                  dispatch(setStoreFromFilter(val ? val.toISOString() : val))
                }
              />
            </Grid>
            <Grid item lg={2} sm={3} xs={12}>
              <CfDateFilter
                label={<FormattedMessage id="common.date-to" />}
                name="store-filter-to"
                testId="store-filter-to"
                value={dateTo}
                onFilterChange={(val) =>
                  dispatch(
                    setStoreToFilter(
                      val ? val.endOf("day").toISOString() : val,
                    ),
                  )
                }
              />
            </Grid>
          </Grid>
          <div>
            <TransactionsTable
              ngGoToAction={ngGoToAction}
              onDeleteOpen={(tr) => handleDialogOpen(KEYS.DELETE, tr)}
              onUpdateOpen={(tr) => handleDialogOpen(KEYS.CREATE, tr)}
              storeId={storeItem?.id}
            />
            {state.create && (
              <StoreMovementDialog
                handleClose={() => handleDialogClose(KEYS.CREATE)}
                onAccept={(dto) => handleDialogAccept(KEYS.CREATE, dto)}
                opened={state.create}
                storeItem={storeItem}
                transactionItem={state.transaction}
              />
            )}
            {state.delete && (
              <CfDialog
                acceptText={<FormattedMessage id="common.delete" />}
                cancelText={<FormattedMessage id="common.cancel" />}
                dialogHeight="75px"
                onAccept={() => handleDialogAccept(KEYS.DELETE)}
                onCancel={() => handleDialogClose(KEYS.DELETE)}
                onClose={() => handleDialogClose(KEYS.DELETE)}
                opened={state.delete}
                title={
                  <FormattedMessage id="Stores.store-delete-transaction" />
                }
              >
                <FormattedMessage
                  id="Stores.store-delete-transaction-confirm"
                  values={{
                    type: state.transaction.type ? (
                      <FormattedMessage
                        id={`Stores.${state.transaction.type}`}
                      />
                    ) : (
                      ""
                    ),
                    amount: state.transaction.amount,
                    date: state.transaction.date ? (
                      <FormattedDate value={state.transaction.date || ""} />
                    ) : (
                      ""
                    ),
                    unitId: (
                      <FormattedMessage id={`unit.${storeItem?.unitId}`} />
                    ),
                  }}
                />
              </CfDialog>
            )}
          </div>
        </div>
      </div>
    </CfErrorPage>
  );
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: theme.spacing(2),
  },
  header: {
    paddingBottom: theme.spacing(1),
  },
  filtersContainer: {
    marginBottom: "10px",
  },
}));

export { Store };
