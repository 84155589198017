import React, { useState, useEffect, useRef } from "react";
import { TextField, FormControl } from "@mui/material";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import { isEqual } from "lodash";

type Props = {
  setEdit: (val: boolean) => void;
  value?: number;
  handleChange: (e: React.SyntheticEvent, val?: number) => void;
  classes?: Record<string, string>;
};

const YieldInput = ({
  setEdit,
  value,
  handleChange,
  classes: propClasses,
}: Props) => {
  const classes = useStyles();
  const initValue = value?.toString() || "";
  const [inputValue, setInputValue] = useState<string>(initValue);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setInputValue(newValue);
  };

  const submitChanges = (e: React.SyntheticEvent) => {
    const newValue = parseFloat(inputValue.trim());
    const hasChanged = !isEqual(initValue, inputValue);

    if (!isNaN(newValue) && hasChanged && newValue >= 0) {
      handleChange(e, newValue);
    } else {
      setInputValue(initValue);
    }
    setEdit(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      submitChanges(e);
    }
  };

  return (
    <FormControl
      className={classNames(classes.formControl, propClasses?.formControl)}
    >
      <TextField
        inputRef={inputRef}
        variant="standard"
        InputProps={{
          endAdornment: <span style={{ marginLeft: "6px" }}>t/ha</span>,
          classes: { input: propClasses?.input },
        }}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        onBlur={submitChanges}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        value={inputValue ?? ""}
      />
    </FormControl>
  );
};

const useStyles = makeStyles({
  formControl: {
    width: "100%",
  },
});

export { YieldInput };
