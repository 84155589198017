import React from "react";
import { makeStyles } from "@mui/styles";
import CfFormattedNumber from "../../../../shared/components/common/CfFormattedNumber/CfFormattedNumber";
import regionPinIcon from "../../../../assets/img/icons/satellite/precision/dashboard/region-pin_black.svg";
import { useTypedIntl } from "../../../../shared/hooks/useTypedIntl";

type Props = {
  totalArea: number;
};

const TotalArea = ({ totalArea }: Props) => {
  const classes = useStyles();
  const intl = useTypedIntl();

  return (
    <span className={classes.wrapper}>
      <div className={classes.regionPinIcon}>
        <img alt="combine" src={regionPinIcon} />
      </div>
      <span>
        {intl.formatMessage({
          id: "PrecisionServices.totalArea",
        })}{" "}
        <span className={classes.totalArea}>
          {" "}
          <CfFormattedNumber decimalDigits={1} value={totalArea} /> ha
        </span>
      </span>
    </span>
  );
};

const useStyles = makeStyles(() => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
    gap: 12,
    backgroundColor: "#EEEEEE",
    borderRadius: 8,
    padding: 12,
  },
  regionPinIcon: {
    height: 24,
    width: 24,
    display: "inline-block",
  },
  totalArea: {
    fontWeight: 500,
  },
}));

export { TotalArea };
