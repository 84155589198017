import React from "react";

import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import PageHeading from "../../../shared/components/common/PageHeading/PageHeading";

import { IrrigationAreasList } from "./areas/IrrigationAreasList";
import { IrrigationDevicesList } from "./devices/IrrigationDevicesList";

const TABS = {
  areas: {
    id: 0,
    label: <FormattedMessage id="Irrigation.areas.title" />,
    url: "./areas",
    testId: "irrigation-areas",
  },
  devices: {
    id: 1,
    label: <FormattedMessage id="Irrigation.devices.title" />,
    url: "./devices",
    testId: "irrigation-unit",
  },
};

const styles = (theme) => ({
  heading: {
    display: "block",
    width: "100%",
    padding: "10px 0",
    marginBottom: 8,
  },
  container: {
    display: "flex",
    position: "relative",
    justifyContent: "center",
    backgroundColor: "#fff",
    flexWrap: "wrap",
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
  },
  fabContainer: {
    position: "absolute",
    top: 15,
    right: 15,
  },
  fab: {
    color: "#fff",
  },
});

const IrrigationList = ({ areas, classes, devices, ...props }) => {
  const handleCreateNewArea = () => {
    props.history.push("./area/create");
  };

  return (
    <>
      <Box className={classes.container}>
        <div className={classes.heading}>
          <PageHeading value={<FormattedMessage id="common.irrigation" />} />
          {areas && (
            <Box className={classes.fabContainer}>
              <Fab
                aria-label="add"
                className={classes.fab}
                color="secondary"
                onClick={handleCreateNewArea}
              >
                <AddIcon />
              </Fab>
            </Box>
          )}
        </div>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          value={areas ? TABS.areas.id : TABS.devices.id}
        >
          {Object.keys(TABS).map((key) => (
            <Tab
              data-test={TABS[key].testId}
              key={key}
              label={TABS[key].label}
              onClick={() => props.history.push(TABS[key].url)}
            />
          ))}
        </Tabs>
      </Box>
      {areas && (
        <IrrigationAreasList
          farmId={props.farmId}
          history={props.history}
          {...props}
        />
      )}
      {devices && <IrrigationDevicesList {...props} />}
    </>
  );
};

IrrigationList.propTypes = {
  areas: PropTypes.bool,
  devices: PropTypes.bool,
};

IrrigationList.defaultProps = {
  areas: false,
  devices: false,
};

export default withStyles(styles)(IrrigationList);
