import { useEffect } from "react";

import { useField, useFormikContext } from "formik";
import { flatten, map } from "lodash";

import {
  ApplicationBufferType,
  RestrictionType,
  SubtractionResponse,
} from "../../../../generated/api/agroevidence";
import { AnyTodo } from "../../../../types";
import { driftClassValue } from "../actionEph.services";

import { InitialParcelToAdd } from "../../ActionOthers/actionOther.types";
import {
  ActionEphFormValues,
  EphPlantProtectionsType,
} from "../actionEph.types";

const useEphRestrictions = (isEditing = false) => {
  const { setFieldValue } = useFormikContext<ActionEphFormValues>();

  const [parcels] = useField(`parcels`);
  const [plantProtectionsFormValue] = useField(`plantProtections`);
  const plantProtections =
    plantProtectionsFormValue.value as EphPlantProtectionsType[];

  const extractBuffers = (predicate: (b: AnyTodo) => boolean, mapFn: AnyTodo) =>
    flatten(
      plantProtections.map((por) =>
        flatten(
          por.pests?.map(
            (pest) =>
              pest.buffer
                ?.filter(predicate)
                .map((bufferItem) => mapFn(bufferItem, por.name)) ?? [],
          ),
        ),
      ),
    );

  const buffersWithDriftReduction = flatten(
    plantProtections.map((por) =>
      flatten(
        por.pests?.map((pest) =>
          pest.buffer
            ?.filter(
              (b) =>
                b.driftReduction ===
                driftClassValue[
                  (por.driftClass as AnyTodo)
                    ?.code as keyof typeof driftClassValue
                ],
            )
            .map((bufferItem) => ({
              type: bufferItem?.type,
              value: bufferItem?.buffer,
              isMaterial: true,
              isAdded: true,
              name: por.name,
            })),
        ) ?? [],
      ),
    ),
  );

  const waterProtectionZonesBuffers = extractBuffers(
    (b) =>
      b.type === RestrictionType.SurfaceWaterProtectionZones ||
      b.type === RestrictionType.GroundWaterProtectionZones,
    (bufferItem: AnyTodo, name: string) => ({
      type: bufferItem?.type,
      value: bufferItem?.protectionLevel,
      isMaterial: true,
      isAdded: true,
      name,
    }),
  );

  const populationProtectionZonesBuffers = extractBuffers(
    (b) => b.type === RestrictionType.PopulationProtectionZones,
    (bufferItem: AnyTodo, name: string) => ({
      type: bufferItem?.type,
      value: bufferItem?.buffer,
      isMaterial: true,
      isAdded: true,
      name,
    }),
  );

  const waterRestrictions = buffersWithDriftReduction.filter(
    (buffer) => buffer?.type === RestrictionType.Water,
  );
  const boundaryRestrictions = buffersWithDriftReduction.filter(
    (buffer) => buffer?.type === RestrictionType.Boundary,
  );
  const surfaceWaterProtectionZones = waterProtectionZonesBuffers.filter(
    (buffer) => buffer?.type === RestrictionType.SurfaceWaterProtectionZones,
  );
  const groundWaterProtectionZones = waterProtectionZonesBuffers.filter(
    (buffer) => buffer?.type === RestrictionType.GroundWaterProtectionZones,
  );

  const pestsArray = flatten(map(plantProtections, "pests"));

  interface mappedBuffer {
    type: ApplicationBufferType;
    value: number | undefined;
    isMaterial: boolean;
    isAdded: boolean;
    name: string;
  }

  const getMaxMaterialValue = (
    areas?: (mappedBuffer | SubtractionResponse)[],
  ) =>
    areas?.reduce((maxValue, sa) => {
      if (sa.isMaterial && sa.value && sa.value > maxValue) {
        return sa.value;
      } else {
        return maxValue;
      }
    }, 0);

  useEffect(() => {
    Promise.all(
      parcels.value.map(async (parcel: InitialParcelToAdd, index: number) => {
        const defaultBoundaryBuffers =
          parcel?.subtractableAreas?.boundary.filter(
            (buffer: AnyTodo) => !buffer?.isAdded,
          );

        const defaultWaterBuffers = parcel?.subtractableAreas?.water.filter(
          (buffer: AnyTodo) => !buffer?.isAdded,
        );

        const defaultSurfaceWaterProtectionZones =
          parcel?.subtractableAreas?.surfaceWaterProtectionZones?.filter(
            (buffer: AnyTodo) => !buffer?.isAdded,
          ) ?? [];

        const defaultGroundWaterProtectionZones =
          parcel?.subtractableAreas?.groundWaterProtectionZones?.filter(
            (buffer: AnyTodo) => !buffer?.isAdded,
          ) ?? [];

        const defaultPopulationProtectionZones =
          parcel?.subtractableAreas?.populationProtectionZones?.filter(
            (buffer: AnyTodo) => !buffer?.isAdded,
          ) ?? [];

        await setFieldValue(`parcels[${index}]`, {
          ...parcel,
          subtractableAreas: {
            ...parcel.subtractableAreas,
            boundary: [...defaultBoundaryBuffers, ...boundaryRestrictions],
            water: [...defaultWaterBuffers, ...waterRestrictions],
            surfaceWaterProtectionZones: [
              ...defaultSurfaceWaterProtectionZones,
              ...surfaceWaterProtectionZones,
            ],
            groundWaterProtectionZones: [
              ...defaultGroundWaterProtectionZones,
              ...groundWaterProtectionZones,
            ],
            populationProtectionZones: [
              ...defaultPopulationProtectionZones,
              ...populationProtectionZonesBuffers,
            ],
          },
        });
      }),
    );
  }, [parcels?.value?.length, JSON.stringify(plantProtections)]);

  useEffect(() => {
    if (isEditing) {
      Promise.all(
        parcels.value.map(async (parcel: InitialParcelToAdd, index: number) => {
          const defaultBoundaryBuffers =
            parcel?.subtractableAreas?.boundary.filter(
              (buffer: AnyTodo) => !buffer?.isAdded,
            );

          const defaultWaterBuffers = parcel?.subtractableAreas?.water.filter(
            (buffer: AnyTodo) => !buffer?.isAdded,
          );

          const defaultSurfaceWaterProtectionZones =
            parcel?.subtractableAreas?.surfaceWaterProtectionZones?.filter(
              (buffer: AnyTodo) => !buffer?.isAdded,
            ) ?? [];

          const defaultGroundWaterProtectionZones =
            parcel?.subtractableAreas?.groundWaterProtectionZones?.filter(
              (buffer: AnyTodo) => !buffer?.isAdded,
            ) ?? [];

          await setFieldValue(`parcels[${index}]`, {
            ...parcel,
            subtractableAreas: {
              ...parcel.subtractableAreas,
              boundary: [...defaultBoundaryBuffers, ...boundaryRestrictions],
              boundaryChecked:
                getMaxMaterialValue([
                  ...defaultBoundaryBuffers,
                  ...boundaryRestrictions,
                ]) ?? 0,
              water: [...defaultWaterBuffers, ...waterRestrictions],
              waterChecked:
                getMaxMaterialValue([
                  ...defaultWaterBuffers,
                  ...waterRestrictions,
                ]) ?? 0,
              surfaceWaterProtectionZones: [
                ...defaultSurfaceWaterProtectionZones,
                ...surfaceWaterProtectionZones,
              ],
              surfaceWaterProtectionZonesChecked:
                getMaxMaterialValue([
                  ...defaultSurfaceWaterProtectionZones,
                  ...surfaceWaterProtectionZones,
                ]) ?? 0,
              groundWaterProtectionZones: [
                ...defaultGroundWaterProtectionZones,
                ...groundWaterProtectionZones,
              ],
              groundWaterProtectionZonesChecked:
                getMaxMaterialValue([
                  ...defaultGroundWaterProtectionZones,
                  ...groundWaterProtectionZones,
                ]) ?? 0,
            },
          });
        }),
      );
    }
  }, [JSON.stringify(pestsArray)]);
};

export { useEphRestrictions };
