import React, { useEffect, useState, useMemo } from "react";
import { makeStyles } from "@mui/styles";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import debounce from "lodash/debounce";

import { useTypedIntl } from "../../../shared/hooks/useTypedIntl";

type Props = {
  translId?: string;
  initialValue?: string;
  name?: string;
  disableUnderline?: boolean;
  variant?: TextFieldProps["variant"];
  autoFocus?: boolean;
  style?: React.CSSProperties;
  onChange: (inputValue: string) => void;
};

const CfTextFieldFilter = ({
  autoFocus = false,
  disableUnderline = false,
  variant = "outlined",
  initialValue = "",
  name = "text-filter",
  onChange,
  style: propStyle = undefined,
  translId,
}: Props) => {
  const { formatMessage } = useTypedIntl();
  const styles = useStyles();
  const [value, setValue] = useState(initialValue);

  const debouncedFnc = useMemo(
    () =>
      debounce((inputValue) => {
        onChange(inputValue);
      }, 500),
    [onChange],
  );

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(
    () => () => {
      debouncedFnc.cancel();
    },
    [debouncedFnc],
  );

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    debouncedFnc(e.target.value);
  };

  const onIconResetClick = () => {
    setValue("");
    onChange("");
  };

  const icon = value.length ? (
    <IconButton
      aria-label="Text filter"
      color="default"
      onClick={onIconResetClick}
    >
      <ClearIcon htmlColor="#00A179" />
    </IconButton>
  ) : (
    <span style={{ display: "grid", padding: "12px 12px 12px 0" }}>
      <SearchIcon htmlColor="#00A179" />
    </span>
  );

  const translationId = translId
    ? formatMessage({
        id: translId,
      })
    : undefined;

  return (
    <div className={styles.wrapper}>
      <TextField
        autoFocus={autoFocus}
        variant={variant}
        name={name}
        onChange={onInputChange}
        placeholder={translationId}
        value={value}
        InputProps={{
          disableUnderline,
          endAdornment: <InputAdornment position="end">{icon}</InputAdornment>,
          sx: {
            "& .MuiOutlinedInput-input": {
              padding: "12.5px",
            },
            "&.MuiOutlinedInput-root": {
              paddingRight: "3px",
              borderRadius: "8px",
            },
          },
        }}
        style={
          propStyle || {
            width: "100%",
          }
        }
      />
    </div>
  );
};

const useStyles = makeStyles({
  wrapper: {
    position: "relative",
    fontWeight: 400,
  },
});

export default CfTextFieldFilter;
