import React, { useContext, useMemo } from "react";

import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Form, Formik, FormikErrors } from "formik";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { RSAAResultAction } from "redux-api-middleware";

import {
  createPlantProtectionApplication,
  fetchPlantProtectionApplications,
  updatePlantProtectionApplication,
} from "../../../../actions/catalogues.actions";

import {
  CropTo,
  OrganismTo,
  PlantProtectionApplicationTo,
} from "../../../../../generated/api/agroevidence";
import CfAutocomplete from "../../../../../shared/components/common/CfAutocomplete/CfAutocomplete";
import CfDialog from "../../../../../shared/components/common/CfDialog/CfDialog";
import { SnackbarContext } from "../../../../../shared/containers/SnackbarProvider/SnackbarProvider";
import UnitService from "../../../../../shared/services/Unit.service";
import { WaterProtectionZoneRadioGroup } from "../../../fertilizers/FertilizerDetail/components/WaterProtectionZoneRadioGroup";
import CropsSelector from "../../../shared/CropsSelector";
import FormikTextField from "../../../shared/FormikTextField";
import NumericField from "../../../shared/NumericField";
import { OrganismsSelector } from "../../PlantProtectionAdvancedFilter/OrganismsSelector/OrganismsSelector";
import {
  initialEmptyValuesApplication,
  mapInitialValuesApplication,
  mapRequestBodyCreateUpdateApplication,
} from "../PlantProtectionDetail.services";

import {
  ApplicationBuffersType,
  PlantProtectionApplicationFormValues,
} from "../PlantProtectionDetail.types";

const handleValidate = (values: PlantProtectionApplicationFormValues) => {
  const errors: FormikErrors<PlantProtectionApplicationFormValues> = {};
  if (!values.crop) {
    errors.crop = "validation.required";
  }

  if (!values.organism) {
    errors.organism = "validation.required";
  }

  return errors;
};

type Props = {
  data?: PlantProtectionApplicationTo;
  isNew: boolean;
  handleClose: () => void;
  opened: boolean;
};

export const ApplicationEditDialog = ({
  data,
  handleClose,
  isNew,
  opened,
}: Props) => {
  const classes = useStyles();
  const showSnackbar = useContext(SnackbarContext);
  const { plantProtectionId } = useParams<{ plantProtectionId: string }>();

  const dispatch = useDispatch();

  const initialValues: Partial<PlantProtectionApplicationTo> = useMemo(() => {
    if (isNew || !data) return initialEmptyValuesApplication;

    return mapInitialValuesApplication(data);
  }, [data, isNew]);

  const handleSubmit = (values: PlantProtectionApplicationFormValues) => {
    const params = mapRequestBodyCreateUpdateApplication(values);
    if (isNew) {
      (
        dispatch(
          createPlantProtectionApplication(plantProtectionId, params),
        ) as unknown as Promise<unknown>
      ).then((res: RSAAResultAction) => handleResponse(res, true));
    } else if (data) {
      (
        dispatch(
          updatePlantProtectionApplication(plantProtectionId, data.id, params),
        ) as unknown as Promise<unknown>
      ).then((res: RSAAResultAction) => handleResponse(res));
    }
    handleClose();
  };

  const handleResponse = (res: RSAAResultAction, newAction = false) => {
    if (!res.error) {
      showSnackbar({
        message: (
          <FormattedMessage
            id={`Catalogues.plantProtection.applications.${
              newAction ? "add.success" : "edit.success"
            }`}
          />
        ),
        isSuccess: true,
      });
      dispatch(fetchPlantProtectionApplications(plantProtectionId));
      return;
    }
    showSnackbar({
      message: (
        <FormattedMessage
          id={`Catalogues.plantProtection.applications.${
            newAction ? "add.error" : "edit.error"
          }`}
        />
      ),
      isError: true,
    });
  };

  const renderSectionHeading = (messageId: string) => (
    <Grid data-test="sectionHeading" item xs={12}>
      <Typography className={classes.sectionHeading} variant="h6">
        <FormattedMessage id={messageId} />
      </Typography>
    </Grid>
  );

  const renderBufferSection = (
    sectionTitleId: string,
    bufferType: "waterBuffers" | "boundaryBuffers",
  ) => {
    type BufferKeys = keyof ApplicationBuffersType;
    const bufferFields: BufferKeys[] = [
      "withoutReduction",
      "reduction50",
      "reduction75",
      "reduction90",
    ];
    return (
      <Grid container item xs={12}>
        {renderSectionHeading(sectionTitleId)}
        {bufferFields.map((type, index) => (
          <Grid item key={index} xs={6}>
            <NumericField
              isEditing
              isWider={true}
              label={`Catalogues.plantProtection.application.editDialog.${type}`}
              name={`${bufferType}.${type}`}
              unit="m"
            />
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <Formik<Partial<PlantProtectionApplicationFormValues>>
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={handleValidate}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ errors, resetForm, setFieldValue, submitForm, values }) => {
        const handleChangeCrop = (crop: CropTo) => {
          setFieldValue("crop", crop);
        };

        const handleChangeOrganism = (organism: OrganismTo) => {
          setFieldValue("organism", organism);
        };

        const handleChangeUnit = (value: { id: string; name: string }) => {
          setFieldValue("unit", value.name);
        };

        const handleChangeSurfaceProtectionZone = (
          event: React.ChangeEvent<HTMLInputElement>,
        ) => {
          setFieldValue(
            "surfaceWaterProtectionZone",
            event.target.defaultValue,
          );
        };

        const handleChangeGroundProtectionZone = (
          event: React.ChangeEvent<HTMLInputElement>,
        ) => {
          setFieldValue("groundWaterProtectionZone", event.target.defaultValue);
        };

        return (
          <CfDialog
            acceptText={<FormattedMessage id="common.save" />}
            cancelText={<FormattedMessage id="common.close" />}
            onAccept={submitForm}
            opened={opened}
            onCancel={() => {
              resetForm();
              handleClose();
            }}
            title={
              <FormattedMessage id="Catalogues.plantProtection.applications.add" />
            }
          >
            <Form>
              <Grid container direction="column" spacing={2}>
                <Grid container direction="column" item spacing={2} xs={12}>
                  <Grid className={classes.selectField} item xs={12}>
                    <CropsSelector
                      defaultValues={values.crop}
                      error={!!errors.crop}
                      label={<FormattedMessage id="common.crop" />}
                      onChange={handleChangeCrop}
                    />
                  </Grid>
                  <Grid className={classes.selectField} item xs={12}>
                    <OrganismsSelector
                      defaultValues={values.organism}
                      error={!!errors.organism}
                      onChange={handleChangeOrganism}
                      validOnly={true}
                      label={
                        <FormattedMessage id="Catalogues.table.plantProtection.column.organismus" />
                      }
                    />
                  </Grid>
                  <Grid container item spacing={2} xs={12}>
                    <Grid item xs={4}>
                      <NumericField
                        fullWidth
                        isEditing
                        label="Catalogues.plantProtection.application.editDialog.minDose"
                        name="minDose"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <NumericField
                        fullWidth
                        isEditing
                        label="Catalogues.plantProtection.application.editDialog.maxDose"
                        name="maxDose"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <CfAutocomplete
                        disableClearable
                        id="catalogues-unit"
                        label={<FormattedMessage id="common.unit" />}
                        onChange={handleChangeUnit}
                        suggestions={unitsPerHectare}
                        testId="dose-unit-selector"
                        defaultValues={
                          data?.unit
                            ? unitsPerHectare.find(
                                (unit) => unit.name === data?.unit,
                              )
                            : unitsPerHectare[3]
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid container item xs={12}>
                    <Grid item xs={6}>
                      <NumericField
                        isEditing
                        isWider={true}
                        label="Catalogues.plantProtection.application.editDialog.minDoseWater"
                        name="minDoseWater"
                        unit="l/ha"
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <NumericField
                        isEditing
                        isWider={true}
                        label="Catalogues.plantProtection.application.editDialog.maxDoseWater"
                        name="maxDoseWater"
                        unit="l/ha"
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <NumericField
                      isEditing
                      isWider={true}
                      label="Catalogues.plantProtection.application.editDialog.protectionPeriod"
                      name="protectionPeriod"
                      unit={
                        <FormattedMessage id="Catalogues.plantProtection.application.editDialog.dayUnit" />
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container item xs={12}>
                  <Grid item xs={6}>
                    <NumericField
                      isEditing
                      isWider={true}
                      label="Catalogues.plantProtection.application.editDialog.minBbch"
                      name="minBbch"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <NumericField
                      isEditing
                      isWider={true}
                      label="Catalogues.plantProtection.application.editDialog.maxBbch"
                      name="maxBbch"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <NumericField
                      isEditing
                      isWider={true}
                      label="Catalogues.plantProtection.application.editDialog.allowedApplications"
                      name="allowedApplications"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <NumericField
                      isEditing
                      isWider={true}
                      label="Catalogues.plantProtection.application.editDialog.interval"
                      name="minInterval"
                      unit={
                        <FormattedMessage id="Catalogues.plantProtection.application.editDialog.dayUnit" />
                      }
                    />
                  </Grid>
                </Grid>
                {renderBufferSection(
                  "Catalogues.plantProtection.application.editDialog.distanceEdge",
                  "boundaryBuffers",
                )}
                {renderBufferSection(
                  "Catalogues.plantProtection.application.editDialog.distanceWater",
                  "waterBuffers",
                )}
                <WaterProtectionZoneRadioGroup
                  defaultValues={values.surfaceWaterProtectionZone ?? 0}
                  name="surfaceWaterProtectionZone"
                  onChange={handleChangeSurfaceProtectionZone}
                  label={
                    <FormattedMessage id="Catalogues.plantProtection.application.editDialog.surfaceProtectionZone" />
                  }
                />
                <WaterProtectionZoneRadioGroup
                  defaultValues={values.groundWaterProtectionZone ?? 0}
                  name="groundWaterProtectionZone"
                  onChange={handleChangeGroundProtectionZone}
                  label={
                    <FormattedMessage id="Catalogues.plantProtection.application.editDialog.groundProtectionZone" />
                  }
                />

                <Grid container item xs={12}>
                  <Grid data-test="sectionHeading" item xs={12}>
                    <Typography className={classes.sectionHeading} variant="h6">
                      <FormattedMessage id="Catalogues.plantProtection.application.editDialog.populationProtectionZones" />
                    </Typography>
                  </Grid>
                  <NumericField
                    isEditing
                    isWider={true}
                    label="Catalogues.plantProtection.application.editDialog.populationProtectionZones"
                    name="populationProtectionZones"
                    unit="m"
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormikTextField
                    fullWidth={true}
                    isEditing
                    label="common.note"
                    name="notes"
                  />
                </Grid>
              </Grid>
            </Form>
          </CfDialog>
        );
      }}
    </Formik>
  );
};

const unitsPerHectare = UnitService.getUnits()
  .filter((item: { id: string; name: string }) => item.id !== "vj")
  .map((unit) => ({ id: unit.id, name: `${unit.name}/ha` }));

const useStyles = makeStyles(() => ({
  dialog: {
    width: "400px",
  },
  sectionHeading: {
    margin: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    textAlign: "center",
  },
  selectField: {
    marginBottom: 20,
    marginRight: 20,
  },
  unitDropdown: {
    marginBottom: 20,
  },
}));
