import React, { useState } from "react";

import { Button, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useQuery } from "@tanstack/react-query";
import SeasonOverviewIcon from "../../../assets/img/icons/satellite/precision/services/season-overview.svg";
import { LANGUAGE_ID } from "../../../shared/lang/lang.constants";
import { FormattedMessage } from "react-intl";
import { SeasonTo } from "../../../generated/api/satellite";
import { precisionSeasonsQuery } from "../dashboard/ParcelDashboard.api";
import CfErrorPage from "../../../shared/components/common/CfErrorPage/CfErrorPage";
import CfLoader from "../../../shared/components/common/CfLoader/CfLoader";
import { SelectObject } from "../../../shared/components/form/FormSelect/types";
import useWidth from "../../../shared/hooks/useWidth";
import { RsaaApiError } from "../../../types";
import { useIsPrecisionFarmingActive } from "../../../core/precision/hooks/useIsPrecisionFarmingActive";
import { Link } from "react-router-dom";
import NoPrecision from "../../../core/precision/containers/NoPrecision";
import Table from "./table/Table";
import { usePrecisionListStyles } from "../../../core/precision/containers/styles";
import { AdvancedFilter } from "../../components/advancedFilter/AdvancedFilter";

import { useCropOptions } from "./hooks/useCropOptions";
import { SeasonDropdown } from "./components/SeasonDropdown";
import { TotalArea } from "./components/TotalArea";
import { AdvancedFilterType, HistoryPrevState } from "../../types";
import CfTextFieldFilter from "../../../common/components/CfTextFieldFilter/CfTextFieldFilter";

import { PRECISION_URLS } from "../../../core/precision/precision.constants";
import { useHistory } from "react-router-dom";
import { useSeason } from "./hooks/useSeason";
import { isNil } from "lodash";
import { getPrevLocationPathname } from "../../helpers";

type Props = {
  error?: RsaaApiError;
  farmId: string;
  langId: LANGUAGE_ID;
};

const PrecisionServicesNew = ({ error, farmId, langId }: Props) => {
  const pzListClasses = usePrecisionListStyles();
  const classes = useStyles();

  const history = useHistory();
  const width = useWidth();
  const isMobile = width === "xs" || width === "sm";

  const { crops, cropsOptions } = useCropOptions();

  const [totalArea, setTotalArea] = useState(0);

  const [textFilter, setTextFilter] = useState("");
  const [advancedFilter, setAdvancedFilter] = useState<AdvancedFilterType>({});

  const hasPrecision = useIsPrecisionFarmingActive({ isPageInitCall: false });

  const seasons = useQuery(
    precisionSeasonsQuery({
      farmIds: farmId,
      "sort-dir": "desc",
    }),
  );
  const fetchedSeasons = seasons?.data?.data;
  const seasonsOptions: SelectObject<SeasonTo>[] =
    fetchedSeasons?.map((s) => ({
      key: s.id,
      value: s.name,
      obj: s,
    })) ?? [];

  const { season, handleSeasonChange } = useSeason({
    isInit: !!(seasons.isSuccess && seasonsOptions.length),
    seasonsOptions,
    farmId,
  });

  let Jsx = <CfLoader />;

  if (hasPrecision !== undefined && !hasPrecision) {
    Jsx = <NoPrecision langId={langId} />;
  }

  if (hasPrecision && !isNil(season) && !isNil(season.obj)) {
    Jsx = (
      <div>
        <div className={classes.toolbar}>
          <div className={classes.season}>
            <SeasonDropdown
              seasonsOptions={seasonsOptions}
              selected={season}
              setSelected={handleSeasonChange}
            />
          </div>
          <div className={classes.toolbarSubrow}>
            <div className={classes.toolbarSubrowLeft}>
              <TotalArea totalArea={totalArea} />
              <CfTextFieldFilter
                initialValue={textFilter}
                name="precision-list-text-filter"
                onChange={setTextFilter}
                translId="PrecisionServices.table.textFilter"
                style={
                  !isMobile
                    ? {
                        width: "100%",
                        minWidth: 340,
                      }
                    : undefined
                }
              />
              <AdvancedFilter
                crops={crops}
                advancedFilter={advancedFilter}
                setAdvancedFilter={setAdvancedFilter}
              />
            </div>
            <Button
              component={Link}
              to={{
                pathname: `/farm/${farmId}/${PRECISION_URLS.seasonsDashboard()}`,
                state: {
                  previousLocationPathname: getPrevLocationPathname(
                    window.location.href,
                  ),
                  previousSeasonId: season.key,
                } as HistoryPrevState,
              }}
              color="secondary"
              size="large"
              variant="outlined"
              classes={{
                root: classes.seasonOverview,
              }}
            >
              <span className={classes.seasonOverviewBody}>
                <img alt="season overview" src={SeasonOverviewIcon} />
                <span style={{ lineHeight: 1.25, marginTop: 2 }}>
                  <FormattedMessage id="PrecisionFarming.seasons.backToDashboard" />
                </span>
              </span>
            </Button>
          </div>
        </div>
        <Table
          advancedFilter={advancedFilter}
          farmId={farmId}
          season={season.obj}
          setTotalArea={setTotalArea}
          textFilter={textFilter}
          cropsOptions={cropsOptions}
        />
      </div>
    );
  }

  return (
    <CfErrorPage error={error}>
      <div className={pzListClasses.wrapper}>{Jsx}</div>
    </CfErrorPage>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  toolbar: {
    paddingBottom: theme.spacing(2),
    flexWrap: "wrap",
    flexGrow: 1,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 10,
      width: "100%",
    },
  },
  toolbarSubrow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "stretch",
    gap: 16,
    flexWrap: "wrap",
  },
  toolbarSubrowLeft: {
    display: "flex",
    alignItems: "stretch",
    gap: 16,
    flexWrap: "wrap",
  },
  seasonOverview: {
    textTransform: "unset",
    padding: "11px 16px",
    fontSize: 16,
    borderRadius: "8px",
    "&.MuiButton-root": {
      color: theme.palette.secondary.main,
      borderColor: theme.palette.secondary.main,
      outline: 0,
    },
  },
  seasonOverviewBody: {
    display: "flex",
    alignItems: "center",
    gap: 8,
  },
  season: {
    marginBottom: 20,
  },
}));

export { PrecisionServicesNew };
