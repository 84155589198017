import React, { useMemo } from "react";

import ClearIcon from "@mui/icons-material/Clear";
import { IconButton, Radio, Theme } from "@mui/material";
import Grid from "@mui/material/Grid";
import RadioGroup from "@mui/material/RadioGroup";
import { makeStyles } from "@mui/styles";
import { FieldInputProps } from "formik";
import { FormattedMessage } from "react-intl";

import { RestrictionType } from "../../../../../generated/api/agroevidence";
import { SubtractableArea } from "../SubtractableArea/SubtractableArea";

import { SubtractionResponse } from "../../../../../shared/api/agroevidence/agroevidence.types";
import { SubtractableAreaFromMaterialType } from "../../../ActionOthers/actionOther.types";

interface SubtractableAreaWithRadioButtonProps {
  field: FieldInputProps<SubtractionResponse>;
  handleSubtractionDelete?: (type: string, area: number) => void;
  isEditing: boolean;
  options: SubtractionResponse[];
  subtractableAreaType: RestrictionType;
}

const SubtractableAreaWithRadioButton = ({
  field,
  handleSubtractionDelete,
  isEditing = false,
  options,
  subtractableAreaType,
}: SubtractableAreaWithRadioButtonProps) => {
  const classes = useStyles();

  // Sorts and filters options, keeping only unique values and preferring objects with isMaterial: true.
  const sortedOptions = options
    .reduce(
      (
        acc: SubtractableAreaFromMaterialType[],
        curr: SubtractableAreaFromMaterialType,
      ) => {
        const existingOptionIndex = acc.findIndex(
          (opt: SubtractableAreaFromMaterialType) =>
            opt.value === curr.value && !opt.isMaterial,
        );

        if (existingOptionIndex === -1) {
          // If an object with the same value has not been encountered yet, add it to the array.
          acc.push(curr);
        } else if (curr.isMaterial) {
          // If the current object has isMaterial: true, replace the existing object in the array with it.
          acc[existingOptionIndex] = curr;
        }
        // Otherwise, ignore the current object since isMaterial: false.
        return acc;
      },
      [],
    )
    .sort((a, b) => {
      if (a.value === undefined) return 1;
      if (b.value === undefined) return -1;
      return a.value - b.value;
    });
  // Combines objects with the same value and isMaterial, concatenating their names with a comma.
  const combinedOptions = sortedOptions.reduce(
    (
      acc: SubtractableAreaFromMaterialType[],
      curr: SubtractableAreaFromMaterialType,
    ) => {
      const existingIndex = acc.findIndex(
        (opt) => opt.value === curr.value && opt.isMaterial === curr.isMaterial,
      );

      if (existingIndex !== -1) {
        acc[existingIndex].name = acc[existingIndex].name
          ? `${acc[existingIndex].name}, ${curr.name}`
          : curr.name;
      } else {
        acc.push({ ...curr });
      }

      return acc;
    },
    [],
  );

  // Adds a "None" option with value: 0 at the beginning of the sortedOptions array.
  const optionsWithNone: SubtractableAreaFromMaterialType[] = useMemo(
    () => [
      {
        type: subtractableAreaType,
        value: 0,
        area: 0,
        isMaterial: false,
        isUsed: false,
      },
      ...combinedOptions,
    ],
    [combinedOptions, subtractableAreaType],
  );

  return (
    <RadioGroup data-test="area-subtraction-list" {...field} name={field.name}>
      <Grid container>
        {optionsWithNone.map((option) => {
          const isChecked =
            field.value?.toString() === option.value?.toString();
          const isMaterial = option?.isMaterial;
          return (
            <Grid
              item
              key={`radio-${option.type}-${option.value}-${option.isMaterial}`}
              md={3}
              sm={4}
              xs={12}
            >
              <span
                className={classes.saRow}
                data-test={`${option?.type?.toLowerCase()}-radio-subtraction-item`}
              >
                <Radio
                  checked={isChecked}
                  color="primary"
                  disabled={!isEditing}
                  value={option.value}
                />
                {option.value === 0 && !option.isMaterial ? (
                  <FormattedMessage id="SubtractableArea.none" />
                ) : (
                  <SubtractableArea sa={option} />
                )}
                {option.value !== 0 &&
                  isEditing &&
                  !isMaterial &&
                  handleSubtractionDelete && (
                    <IconButton
                      aria-label="Remove item"
                      className={classes.removeButton}
                      disabled={!isEditing}
                      size="small"
                      onClick={() => {
                        handleSubtractionDelete(
                          option.type,
                          option.value as number,
                        );
                      }}
                    >
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  )}
              </span>
            </Grid>
          );
        })}
      </Grid>
    </RadioGroup>
  );
};

export { SubtractableAreaWithRadioButton };

const useStyles = makeStyles((theme: Theme) => ({
  saRow: {
    display: "flex",
    alignItems: "center",
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
      "& $removeButton": {
        opacity: 1,
      },
    },
  },
  removeButton: {
    height: 26,
    width: 26,
    padding: 0,
    marginLeft: 6,
    opacity: 0,
  },
}));
