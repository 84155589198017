import React, { useMemo, useContext } from "react";

import { Field, Formik, Form, FieldProps } from "formik";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";

import { fetchAccountFarms } from "../actions/farms.actions";

import {
  AdminFarmTo,
  InternalFarmImportSource,
} from "../../../../generated/api/agroevidence";
import { updateAdminFarmSettingsApi } from "../../../../shared/api/agroevidence/farms/admin/farmsAdmin.api";
import CfDialog from "../../../../shared/components/common/CfDialog/CfDialog";
import CfFormikSwitch from "../../../../shared/components/form/CfFormikSwitch/CfFormikSwitch";
import CfFormikTextField from "../../../../shared/components/form/CfFormikTextField/CfFormikTextField";
import { SnackbarContext } from "../../../../shared/containers/SnackbarProvider/SnackbarProvider";

import useEditDialogStyles from "./styles/editDialog.styles";

interface EditFormValues {
  farmName: string;
  updateLpis: boolean;
}

const validate = (values: EditFormValues) => {
  const errors: Record<string, string | React.ReactNode> = {};
  if (!values?.farmName) {
    errors.farmName = (
      <FormattedMessage id="TelematicsAdmin.edit.dialog.requiredField" />
    );
  }
  return errors;
};

// in global scope to avoid redeclaration (prevent losing focus on input)
const renderExternalIdField = (fieldProps: FieldProps) => (
  <CfFormikTextField {...fieldProps} useDefaultHelperText />
);

interface Props {
  farm: AdminFarmTo;
  handleClose: () => void;
  opened: boolean;
}

const EditDialog = ({ farm, handleClose, opened }: Props) => {
  const classes = useEditDialogStyles();
  const showSnackbar = useContext(SnackbarContext);
  const dispatch = useDispatch();

  const initialValues = useMemo(
    () => ({
      farmName: farm.name ?? "",
      updateLpis: farm.settings.eagriSettingsEnabled ?? false,
    }),
    [farm],
  );

  const handleSubmit = async (values: EditFormValues) => {
    const { farmName, updateLpis } = values;

    try {
      await (dispatch(
        updateAdminFarmSettingsApi(farm.id, {
          name: farmName,
          settings: {
            eagriSettingsEnabled: updateLpis,
          },
        }),
      ) as unknown as Promise<unknown>);

      dispatch(fetchAccountFarms());
      showSnackbar({
        message: <FormattedMessage id="TelematicsAdmin.edit.dialog.success" />,
        isSuccess: true,
      });
    } catch (error) {
      showSnackbar({
        message: <FormattedMessage id="TelematicsAdmin.dialog.error" />,
        isError: true,
      });
    } finally {
      handleClose();
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={validate}
    >
      {({ resetForm, submitForm }) => {
        const closeForm = () => {
          resetForm();
          handleClose();
        };

        return (
          <CfDialog
            acceptText={<FormattedMessage id="common.save" />}
            cancelText={<FormattedMessage id="common.cancel" />}
            maxWidth="xs"
            onAccept={submitForm}
            onCancel={closeForm}
            opened={opened}
            title={<FormattedMessage id="TelematicsAdmin.edit.dialog.title" />}
          >
            <Form className={classes.form}>
              <Field
                component={renderExternalIdField}
                customClasses={{ root: classes.field }}
                fullWidth
                id="farmName"
                name="farmName"
                label={
                  <FormattedMessage id="TelematicsFarmsAdmin.edit.dialog.name" />
                }
              />
              <div className={classes.farmInfo}>
                <span className={classes.farmIdLabel}>
                  <FormattedMessage id="TelematicsFarmsAdmin.edit.dialog.id" />
                </span>
                <p className={classes.farmId}>{farm.id}</p>
              </div>
              {farm.source === InternalFarmImportSource.EAGRI && (
                <Field
                  component={CfFormikSwitch}
                  label={<FormattedMessage id="FarmsAdmin.updateLpis" />}
                  name="updateLpis"
                />
              )}
            </Form>
          </CfDialog>
        );
      }}
    </Formik>
  );
};

export { EditDialog };
