import React from "react";

import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

import { AnyTodo } from "../../../../types";

interface Props {
  item: AnyTodo;
  redirectToAction: () => void;
}

const StoreActionLink = ({ item, redirectToAction }: Props) => {
  const classes = useStyles();

  return item.type === "CONSUMPTION" ? (
    <a
      className={classes.actionLink}
      onClick={redirectToAction}
      role="button"
      tabIndex={0}
    >
      <FormattedMessage id={`Stores.${item.type}`} />
    </a>
  ) : (
    <span className={classes.actionLink}>
      <FormattedMessage id={`Stores.${item.type}`} />
    </span>
  );
};

export { StoreActionLink };

const useStyles = makeStyles({
  actionLink: {
    padding: "0 15px",
  },
});
