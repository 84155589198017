import { AnyAction } from "redux";

import * as types from "./countries.constants";

import { CountriesState } from "./countriesTypes.types";

const initialState: CountriesState = {
  isFetchingCountries: false,
  errorCountries: {},
  items: [],
};

export default () =>
  (state = initialState, action: AnyAction) => {
    switch (action.type) {
      case types.GET_COUNTRIES_REQUEST:
        return {
          ...state,
          isFetchingCountries: true,
          errorCountries: {},
        };
      case types.GET_COUNTRIES_SUCCESS:
        return {
          ...state,
          isFetchingCountries: false,
          items: action.payload,
        };
      case types.GET_COUNTRIES_ERROR:
        return {
          ...state,
          isFetchingCountries: false,
          errorCountries: action.payload,
        };
      case types.RESET_COUNTRIES:
        return {
          ...state,
          items: [],
        };
      default:
        return state;
    }
  };
