import React from "react";

import { Theme } from "@mui/material";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import { Field, FieldArray } from "formik";
import { FormattedMessage } from "react-intl";

import {
  RestrictionTo,
  RestrictionType,
} from "../../../../../generated/api/agroevidence";
import { ActionParcelSubtractableAreasSection } from "../ActionParcelSubtractableAreasSection/ActionParcelSubtractableAreasSection";
import { SubtractableAreaWithCheckbox } from "../SubtractableAreaWithCheckbox/SubtractableAreaWithCheckbox";

import { SubtractionResponse } from "../../../../../shared/api/agroevidence/agroevidence.types";

type Props = {
  isEditing: boolean;
  maxValue: number;
  onParcelSubtractionDelete: (type: string, value: number) => void;
  parcelIndex: number;
};

const AbsoluteParcelSubtractableAreas = ({
  isEditing,
  maxValue,
  onParcelSubtractionDelete,
  parcelIndex,
}: Props) => {
  const classes = useStyles();
  return (
    <FieldArray name={`parcels.${parcelIndex}.subtractableAreas.absolute`}>
      {({ form, push, remove }) => {
        const handleSubtractionDelete = (type: string, value: number) => {
          const index = form.values.parcels[
            parcelIndex
          ].subtractableAreas.absolute.findIndex(
            (area: SubtractionResponse) => area.value === value,
          );
          remove(index);
          onParcelSubtractionDelete(type, value);
        };

        const handleAddSubtractableArea = (sa: RestrictionTo) => {
          push({ ...sa, isUsed: true });
        };

        return (
          <ActionParcelSubtractableAreasSection
            handleAdd={handleAddSubtractableArea}
            isEditing={isEditing}
            maxValue={maxValue}
            subtractableAreaType={RestrictionType.Absolute}
            subtractableAreas={
              form.values.parcels[parcelIndex].subtractableAreas.absolute
            }
          >
            <Grid container data-test="area-subtraction-list">
              {form.values.parcels[parcelIndex].subtractableAreas.absolute
                .length ? (
                form.values.parcels[parcelIndex].subtractableAreas.absolute.map(
                  (sa: SubtractionResponse, index: number) => (
                    <Grid
                      data-test="absolute-subtraction-item"
                      item
                      key={`absolute-${sa.value}`}
                      md={3}
                      sm={4}
                      xs={12}
                    >
                      <Field
                        component={SubtractableAreaWithCheckbox}
                        handleSubtractionDelete={handleSubtractionDelete}
                        isEditing={isEditing}
                        name={`parcels.${parcelIndex}.subtractableAreas.absolute[${index}]`}
                        parcelIndex={parcelIndex}
                      />
                    </Grid>
                  ),
                )
              ) : (
                <div className={classes.noAreas}>
                  <FormattedMessage id="SubtractableAreaDialog.noSubtractableAreas" />
                </div>
              )}
            </Grid>
          </ActionParcelSubtractableAreasSection>
        );
      }}
    </FieldArray>
  );
};

export { AbsoluteParcelSubtractableAreas };

const useStyles = makeStyles((theme: Theme) => ({
  noAreas: {
    color: theme.palette.text.secondary,
    fontSize: 13,
    margin: "0px 0px 10px 10px",
  },
}));
