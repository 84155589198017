import React, { Fragment, useEffect } from "react";

import EditIcon from "@mui/icons-material/Edit";
import { Theme } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import {
  getSentinelAdminPage,
  getSentinelAdminOrder,
  getSentinelAdminOrderBy,
  getSentinelAdminRowsPerPage,
  getSentinelAdminTextFilter,
  getSentinelAdminTotalCount,
  getActiveSentinelParcels,
  isFetchingActiveSentinelParcels,
  getSentinelAdminFarm,
  getSelected,
  getSelectedOnPage,
  getSentinelAdminAdvancedFilter,
} from "../../selectors/sentinel.selectors";

import { fetchActiveParcels } from "../../actions/sentinel.actions";

import { NAMESPACE as namespace } from "../../reducer/sentinelAdmin.reducer";

import CfTableFooter from "../../../../../common/components/CfTableFooter/CfTableFooter";
import { useCfTableFooter } from "../../../../../common/components/CfTableFooter/useCfTableFooter";
import CfTableHead from "../../../../../common/components/CfTableHead/CfTableHead";
import { useCfTableHead } from "../../../../../common/components/CfTableHead/useCfTableHead";
import {
  PrecisionFarmingProduct,
  ParcelDataTo,
} from "../../../../../generated/api/satellite";
import CfFormattedNumber from "../../../../../shared/components/common/CfFormattedNumber/CfFormattedNumber";
import CfTableBodyEmpty from "../../../../../shared/components/tables/CfTableBodyEmpty/CfTableBodyEmpty";
import CfTableBodyLoader from "../../../../../shared/components/tables/CfTableBodyLoader/CfTableBodyLoader";
import CfTableCell from "../../../../../shared/components/tables/CfTableCell/CfTableCell";
import CfTableCellNumber from "../../../../../shared/components/tables/CfTableCellNumber/CfTableCellNumber";
import CfTableRowToolButton from "../../../../../shared/components/tables/CfTableRowToolButton/CfTableRowToolButton";
import CfTableRowTools from "../../../../../shared/components/tables/CfTableRowTools/CfTableRowTools";
import CfTableWrapper from "../../../../../shared/components/tables/CfTableWrapper/CfTableWrapper";
import CfTableCheckbox from "../../../../../shared/containers/CfTableCheckbox/CfTableCheckbox";
import { getColDesc } from "../../../../../shared/misc/helper";
import { COLOR_GREY } from "../../../../../theme";
import SentinelStatus from "../../components/SentinelStatus/SentinelStatus";
import { ParcelWithServices } from "../SentinelAdmin/useSentinelAdmin";

import { StatusWrapper } from "./StatusWrapper";

const columns = {
  localName: getColDesc(
    true,
    <div>
      <div style={{ paddingLeft: 15 }}>
        <FormattedMessage id="SentinelAdminTable.localName" />
      </div>
      <div style={{ paddingLeft: 15, color: COLOR_GREY[400] }}>
        <FormattedMessage id="TelematicsAdmin.columns.id" />
      </div>
    </div>,
  ),
  blockNr: getColDesc(
    true,
    <FormattedMessage id="SentinelAdminTable.blockNumber" />,
  ),
  cropName: getColDesc(false, <FormattedMessage id="common.crop" />),
  area: getColDesc(
    true,
    <div>
      <div>
        <FormattedMessage id="SentinelAdminTable.currentArea" />
      </div>
      <div style={{ color: COLOR_GREY[400] }}>
        <FormattedMessage id="SentinelAdminTable.originalArea" />
      </div>
    </div>,
    { textAlign: "right", paddingRight: 40 },
    "right",
  ),
  biomonitoring: getColDesc(
    false,
    <FormattedMessage id="SentinelAdmin.monitoringGisat" />,
  ),
  internalBiomonitoringEnabled: getColDesc(
    false,
    <FormattedMessage id="SentinelAdmin.internalBiomonitoringEnabled" />,
  ),
  managementZonesEnabled: getColDesc(
    false,
    <FormattedMessage id="SentinelAdmin.managementZonesEnabled" />,
  ),
  yieldMapsEnabled: getColDesc(
    false,
    <FormattedMessage id="SentinelAdmin.yieldMapsEnabled" />,
  ),
};
const columnsLength = Object.keys(columns).length + 1;

export const getParcelProduct = (
  parcel: ParcelDataTo | Partial<ParcelWithServices>,
  productType: PrecisionFarmingProduct,
) =>
  parcel?.products
    ? parcel.products.find((product) => product.productType === productType)
    : undefined;

const SentinelAdminTable = ({
  onRowClick,
}: {
  onRowClick: (parcel: ParcelDataTo) => void;
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const farm = useSelector(getSentinelAdminFarm);
  const page = useSelector(getSentinelAdminPage);
  const order = useSelector(getSentinelAdminOrder);
  const orderBy = useSelector(getSentinelAdminOrderBy);
  const rowsPerPage = useSelector(getSentinelAdminRowsPerPage);
  const textFilter = useSelector(getSentinelAdminTextFilter);
  const count = useSelector(getSentinelAdminTotalCount);
  const activeParcels = useSelector(getActiveSentinelParcels);
  const isFetching = useSelector(isFetchingActiveSentinelParcels);
  const selected = useSelector(getSelected);
  const selectedOnPage = useSelector(getSelectedOnPage);

  const { onPageChange, onRowsPerPageChange } = useCfTableFooter(namespace);

  const advancedFilter = useSelector(getSentinelAdminAdvancedFilter);

  const { onSelect, onSort } = useCfTableHead(namespace);

  useEffect(() => {
    if (farm) {
      dispatch(fetchActiveParcels());
    }
  }, [
    page,
    rowsPerPage,
    order,
    orderBy,
    textFilter,
    farm,
    dispatch,
    advancedFilter,
  ]);

  const isSelected = (id: string) => selected && selected.indexOf(id) !== -1;

  return (
    <CfTableWrapper>
      <CfTableHead
        bulkMode
        columns={columns}
        itemId="parcelId"
        items={activeParcels}
        onSelect={onSelect}
        onSort={onSort}
        order={order}
        orderBy={orderBy}
        selected={selected}
        selectedOnPage={selectedOnPage}
      />
      {farm ? (
        <Fragment>
          {isFetching && <CfTableBodyLoader columns={columns} />}
          {activeParcels.length > 0 ? (
            <TableBody>
              {activeParcels.map((parcel) => (
                <TableRow
                  className={classes.tableRow}
                  key={parcel.parcelId}
                  selected={isSelected(parcel.parcelId)}
                >
                  <CfTableCheckbox
                    id={parcel.parcelId}
                    namespace={namespace}
                    selected={selected}
                  />
                  <CfTableCell name="localName">
                    <div
                      className={classes.nameTableCell}
                      style={{ fontWeight: 500 }}
                    >
                      {parcel.name}
                    </div>
                    <div
                      className={classes.nameTableCell}
                      style={{ color: COLOR_GREY[400] }}
                    >
                      {parcel.parcelId}
                    </div>
                  </CfTableCell>
                  <CfTableCell name="blockNr">
                    {parcel.blockNr ?? "-"}
                  </CfTableCell>
                  <CfTableCell name="cropName">
                    {parcel.cropName ?? "-"}
                  </CfTableCell>
                  <CfTableCell
                    classes={{ root: classes.paddingNumber }}
                    name="area"
                  >
                    <span>
                      <CfTableCellNumber>
                        <CfFormattedNumber
                          decimalDigits={2}
                          value={parcel.area ?? 0}
                        />
                        <div className={classes.originalArea}>
                          <CfFormattedNumber
                            decimalDigits={2}
                            value={parcel.originalArea}
                          />
                        </div>
                      </CfTableCellNumber>
                    </span>
                  </CfTableCell>
                  <CfTableCell name="biomonitoring">
                    <StatusWrapper>
                      <SentinelStatus
                        active={parcel.biomonitoringEnabled}
                        product={getParcelProduct(
                          parcel,
                          PrecisionFarmingProduct.GISAT_BIOPHYSICAL_MONITORING,
                        )}
                      />
                    </StatusWrapper>
                  </CfTableCell>
                  <CfTableCell name="internalBiomonitoringEnabled">
                    <StatusWrapper
                      product={getParcelProduct(
                        parcel,
                        PrecisionFarmingProduct.PLANET_BIOMONITORING_SAVI,
                      )}
                    >
                      <SentinelStatus
                        active={parcel.internalBiomonitoringEnabled}
                        product={getParcelProduct(
                          parcel,
                          PrecisionFarmingProduct.PLANET_BIOMONITORING_SAVI,
                        )}
                      />
                    </StatusWrapper>
                  </CfTableCell>
                  <CfTableCell name="managementZonesEnabled">
                    <StatusWrapper>
                      <SentinelStatus
                        active={parcel.managementZonesEnabled}
                        product={getParcelProduct(
                          parcel,
                          PrecisionFarmingProduct.SENTINEL_HUB_MANAGEMENT_ZONES_SAVI,
                        )}
                      />
                    </StatusWrapper>
                  </CfTableCell>
                  <CfTableCell name="yieldMapsEnabled">
                    <StatusWrapper>
                      <SentinelStatus
                        active={parcel.yieldMapsEnabled}
                        product={getParcelProduct(
                          parcel,
                          PrecisionFarmingProduct.INTERNAL_YIELD_MAPS,
                        )}
                      />
                    </StatusWrapper>
                  </CfTableCell>
                  <CfTableRowTools
                    toolsClass={classes.tableRowTools}
                    withoutGradient
                  >
                    <CfTableRowToolButton
                      icon={<EditIcon />}
                      onClick={() => onRowClick(parcel)}
                      tooltipMessage={
                        <FormattedMessage id="SentinelAdminTable.editServices" />
                      }
                    />
                  </CfTableRowTools>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <CfTableBodyEmpty
              colLength={columnsLength}
              translId="SentinelAdminTable.noActiveServices"
            />
          )}
        </Fragment>
      ) : (
        <CfTableBodyEmpty
          colLength={columnsLength}
          translId="SentinelAdminTable.noFarmSelected"
        />
      )}
      <CfTableFooter
        count={count}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
      />
    </CfTableWrapper>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  nameTableCell: {
    paddingLeft: 15,
  },
  tableRow: {
    transform: "scale(1)",
    "&:hover $tableRowTools": {
      display: "grid",
      placeItems: "center",
      padding: "0px 10px 0px 0px",
    },
  },
  tableRowTools: {
    display: "none",
  },
  originalArea: {
    color: theme.palette.grey[500],
  },
  paddingNumber: {
    paddingRight: 40,
  },
}));

export { SentinelAdminTable };
