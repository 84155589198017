import React, { Fragment, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Box, Button, FormLabel } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

type Props = {
  labelId: string;
  onChange: (count: number) => void;
  defaultValue: number;
  unit?: string;
  countStep?: number;
};

const Counter = ({
  countStep = 1,
  defaultValue,
  labelId,
  onChange,
  unit,
}: Props) => {
  const classes = useStyles();
  const [count, setCount] = useState(defaultValue);

  const handleIncrement = () => {
    setCount((prevCount) => {
      const newCount = prevCount + countStep;
      onChange(newCount);
      return newCount;
    });
  };

  const handleDecrement = () => {
    setCount((prevCount) => {
      const newCount = prevCount - countStep;
      onChange(newCount);
      return newCount;
    });
  };

  return (
    <Fragment>
      <FormLabel>
        <FormattedMessage id={labelId} />
      </FormLabel>
      <Box
        alignItems="center"
        className={classes.counterWapper}
        display="flex"
        justifyContent="space-between"
      >
        <Button
          className={classes.counterButton}
          color="primary"
          disabled={count <= 0}
          onClick={handleDecrement}
          variant="contained"
        >
          <RemoveIcon />
        </Button>
        <div className={classes.counetText}>
          {count} {unit}
        </div>
        <Button
          className={classes.counterButton}
          color="primary"
          onClick={handleIncrement}
          variant="contained"
        >
          <AddIcon />
        </Button>
      </Box>
    </Fragment>
  );
};

const useStyles = makeStyles({
  counterWapper: {
    width: "100%",
    height: 48,
    border: "1px solid #ccc",
    borderRadius: 8,
    overflow: "hidden",
  },
  counterButton: {
    height: "100%",
    borderRadius: 0,
    minWidth: 48,
  },
  counetText: {
    textAlign: "center",
    flexGrow: 1,
    fontWeight: 500,
  },
});

export { Counter };
