import React, { useEffect } from "react";

import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import { Theme } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@mui/styles";
import { FormattedMessage, FormattedDate } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { getIsFetching as getIsFetchingStore } from "../../../../shared/api/stores/stores/stores.selectors";
import {
  getIsFetchingTransactions,
  getTotalCount,
  getTransactions,
} from "../../../../shared/api/stores/transactions/transactions.selectors";
import {
  getStorePage,
  getStoreRowsPerPage,
  getStoreFromFilter,
  getStoreToFilter,
  getStoreUnitId,
} from "../selectors/store.selectors";

import { fetchTransactions } from "../actions/store.actions";

import { NAMESPACE as namespace } from "../reducer/store.reducer";

import CfTableFooter from "../../../../common/components/CfTableFooter/CfTableFooter";
import { useCfTableFooter } from "../../../../common/components/CfTableFooter/useCfTableFooter";
import CfTableHead from "../../../../common/components/CfTableHead/CfTableHead";
import { useCfTableHead } from "../../../../common/components/CfTableHead/useCfTableHead";
import { resetTransactions } from "../../../../shared/api/stores/transactions/transactions.api";
import CfFormattedNumber from "../../../../shared/components/common/CfFormattedNumber/CfFormattedNumber";
import CfTableBodyEmpty from "../../../../shared/components/tables/CfTableBodyEmpty/CfTableBodyEmpty";
import CfTableBodyLoader from "../../../../shared/components/tables/CfTableBodyLoader/CfTableBodyLoader";
import CfTableCell from "../../../../shared/components/tables/CfTableCell/CfTableCell";
import CfTableCellNumber from "../../../../shared/components/tables/CfTableCellNumber/CfTableCellNumber";
import { CfTableIconButton } from "../../../../shared/components/tables/CfTableIconButton/CfTableIconButton";
import CfTableWrapper from "../../../../shared/components/tables/CfTableWrapper/CfTableWrapper";
import { getColDesc } from "../../../../shared/misc/helper";
import { AnyTodo } from "../../../../types";
import { StoreActionLink } from "../components/StoreActionLink";

const columns = {
  type: getColDesc(
    false,
    <span style={{ paddingLeft: 15 }}>
      <FormattedMessage id="common.type" />
    </span>,
  ),
  date: getColDesc(false, <FormattedMessage id="common.date" />),
  amount: getColDesc(false, <FormattedMessage id="common.amount" />, {
    textAlign: "right",
  }),
  dosePerHa: getColDesc(
    false,
    <FormattedMessage id="common.dosePerHectare" />,
    {
      textAlign: "right",
    },
  ),
  before: getColDesc(
    false,
    <FormattedMessage id="Stores.state-before-move" />,
    { textAlign: "right" },
  ),
  after: getColDesc(false, <FormattedMessage id="Stores.state-after-move" />, {
    textAlign: "right",
  }),
  additional: getColDesc(
    false,
    <FormattedMessage id="Stores.additional-info" />,
  ),
  editation: getColDesc(false, <FormattedMessage id="common.edit2" />),
  delete: getColDesc(false, <FormattedMessage id="common.delete" />),
};

type Props = {
  ngGoToAction: (actionId?: string) => void;
  onDeleteOpen: (val: unknown) => void;
  onUpdateOpen: (val: unknown) => void;
  storeId: string;
};

const TransactionsTable = ({
  ngGoToAction,
  onDeleteOpen,
  onUpdateOpen,
  storeId,
}: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const transactions: AnyTodo[] = useSelector(getTransactions);
  const isFetchingTransactions = useSelector(getIsFetchingTransactions);
  const isFetchingStore = useSelector(getIsFetchingStore);
  const count = useSelector(getTotalCount);
  const page = useSelector(getStorePage);
  const rowsPerPage = useSelector(getStoreRowsPerPage);
  const from = useSelector(getStoreFromFilter);
  const to = useSelector(getStoreToFilter);
  const unitId = useSelector(getStoreUnitId);

  const { onPageChange, onRowsPerPageChange } = useCfTableFooter(namespace);
  const { onSelect, onSort } = useCfTableHead(namespace);

  useEffect(() => {
    if (storeId && unitId) {
      dispatch(fetchTransactions(storeId));
    }
  }, [storeId, page, rowsPerPage, from, to, unitId, dispatch]);

  useEffect(
    () => () => {
      dispatch(resetTransactions());
    },
    [dispatch],
  );

  return (
    <CfTableWrapper>
      <CfTableHead columns={columns} onSelect={onSelect} onSort={onSort} />
      {(isFetchingTransactions || isFetchingStore) && (
        <CfTableBodyLoader columns={columns} />
      )}

      {transactions.length ? (
        <TableBody>
          {transactions.map((item) => {
            const balanceAfter = item.balanceBefore + item.amount;
            return (
              <TableRow key={item.id}>
                <CfTableCell name="type">
                  <StoreActionLink
                    item={item}
                    redirectToAction={() => ngGoToAction(item.actionId)}
                  />
                </CfTableCell>
                <CfTableCell name="date">
                  <FormattedDate value={item.date} />
                </CfTableCell>
                <CfTableCell
                  className={item.amount < 0 ? classes.negative : undefined}
                  name="amount"
                >
                  <CfTableCellNumber negative={item.amount < 0}>
                    <CfFormattedNumber decimalDigits={4} value={item.amount} />
                  </CfTableCellNumber>
                </CfTableCell>
                <CfTableCell name="dosePerHa">
                  <CfTableCellNumber>
                    {item.dosePerHa ? (
                      <CfFormattedNumber
                        decimalDigits={4}
                        value={item.dosePerHa}
                      />
                    ) : (
                      "-"
                    )}
                  </CfTableCellNumber>
                </CfTableCell>
                <CfTableCell name="before">
                  <CfTableCellNumber negative={item.balanceBefore < 0}>
                    <CfFormattedNumber
                      decimalDigits={4}
                      value={item.balanceBefore}
                    />
                  </CfTableCellNumber>
                </CfTableCell>
                <CfTableCell name="after">
                  <CfTableCellNumber negative={balanceAfter < 0}>
                    <CfFormattedNumber decimalDigits={4} value={balanceAfter} />
                  </CfTableCellNumber>
                </CfTableCell>
                <CfTableCell name="additional">
                  <span>{item.note}</span>
                </CfTableCell>
                <CfTableIconButton
                  disabled={!item.editable}
                  onClick={() => {
                    onUpdateOpen(item);
                  }}
                  tooltipTitle={
                    !item.editable ? (
                      <FormattedMessage id="Stores.store-edit-transaction-unable" />
                    ) : (
                      ""
                    )
                  }
                >
                  <CreateIcon />
                </CfTableIconButton>
                <CfTableIconButton
                  disabled={!item.editable}
                  onClick={() => {
                    onDeleteOpen(item);
                  }}
                  tooltipTitle={
                    !item.editable ? (
                      <FormattedMessage id="Stores.store-delete-transaction-unable" />
                    ) : (
                      ""
                    )
                  }
                >
                  <DeleteIcon />
                </CfTableIconButton>
              </TableRow>
            );
          })}
        </TableBody>
      ) : (
        <CfTableBodyEmpty colLength={Object.keys(columns).length + 1} />
      )}
      <CfTableFooter
        count={count}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
      />
    </CfTableWrapper>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  editIcon: {
    color: theme.palette.action.active,
  },
  negative: {
    color: theme.palette.error.main,
  },
}));

export { TransactionsTable };
