import React from "react";

import { makeStyles } from "@mui/styles";
import { FieldArrayRenderProps, useFormikContext, useField } from "formik";
import { FormattedMessage } from "react-intl";

import { ActiveSubstanceTo } from "../../../../../generated/api/agroevidence";
import ActiveSubstancesSelector from "../../PlantProtectionAdvancedFilter/ActiveSubstancesSelector/ActiveSubstancesSelector";

import { ActiveSubstancesList } from "./ActiveSubstancesList";

import { PlantProtectionDetailFormValues } from "../PlantProtectionDetail.types";

type Props = {
  arrayHelpers: FieldArrayRenderProps;
  name: string;
  isEditing: boolean;
};

export const ActiveSubstancesControl = ({
  arrayHelpers,
  isEditing,
  name,
}: Props) => {
  const classes = useStyles();

  const { setFieldValue } = useFormikContext<PlantProtectionDetailFormValues>();
  const [field] = useField(name);

  const handleItemRemove = (index: number) => {
    arrayHelpers.remove(index);
  };

  const handleChangeActiveSubstances = (activeSubstance: ActiveSubstanceTo) => {
    const activeSubstancesIndFields = field.value || [];
    if (activeSubstance && !isActiveSubstanceInList(activeSubstance)) {
      setFieldValue("activeSubstances", [
        ...activeSubstancesIndFields,
        activeSubstance,
      ]);
    }
  };

  const isActiveSubstanceInList = (activeSubstanceToCheck: ActiveSubstanceTo) =>
    field.value.some(
      (activeSubstance: ActiveSubstanceTo) =>
        activeSubstance.id === activeSubstanceToCheck.id,
    );

  return (
    <div className={classes.container}>
      {isEditing && (
        <ActiveSubstancesSelector
          defaultValues={null}
          disabled={!isEditing}
          onChange={handleChangeActiveSubstances}
          label={
            <FormattedMessage id="Catalogues.plantProtection.detail.activeSubstancesControl" />
          }
        />
      )}
      <ActiveSubstancesList
        isEditing={isEditing}
        items={field.value}
        onItemRemove={handleItemRemove}
      />
    </div>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    marginLeft: 0,
    marginRight: "auto",
  },
}));
