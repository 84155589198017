import React from "react";

const useTableFooter = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);

  const handleRowsPerPageChange = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  return {
    handleRowsPerPageChange,
    setPage,
    page,
    rowsPerPage,
  };
};

export { useTableFooter };
