import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import { Theme } from "@mui/material";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import { FormikProps, useField } from "formik";
import { isEqual } from "lodash";
import differenceBy from "lodash/differenceBy";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { getCrops } from "../../../../../shared/api/agroevidence/catalogues/crops/crops.selectors";

import {
  fetchParcelHistorySubtractions,
  getAllParcelsInZone,
  updateParcelsSubtractionsArea,
} from "../../actions/actions.actions";

import {
  CropTo,
  ParcelAreaTo,
  ParcelTo,
  RestrictionType,
  SubtractionResponse,
} from "../../../../../generated/api/agroevidence";
import {
  getEphTargetSeedApplicationApi,
  resetEphTargetSeedApplicationApi,
} from "../../../../../shared/api/agroevidence/actions/actions.api";
import { getShortDateString } from "../../../../../shared/misc/timeHelpers";
import { mapEphSubtractableAreasFrom } from "../../../ActionEph/actionEph.services";
import { mapOtherSubtractableAreasFrom } from "../../../ActionOthers/actionOther.services";
import { SplitActionsWarningMessage } from "../../components/SplitActions/SplitActionsWarningMessage";
import TotalActivityArea from "../../components/TotalActivityArea/TotalActivityArea";
import { resolveTargetCrop } from "../../misc/action.helpers";
import {
  getTotalArea,
  initialWaterProtectionZones,
} from "../../services/Actions.services";
import { mapRequestBodyParcelsSubtractionArea } from "../../services/ParcelSubtractionMapper.services";
import { ActionDetailContext } from "../ActionDetail/ActionDetail";
import ParcelsZoneAutocompleteSelector from "../ParcelsZoneAutocompleteSelector/ParcelsZoneAutocompleteSelector";

import { ParcelsList } from "./ParcelsList";

import { ActionEphFormValues } from "../../../ActionEph/actionEph.types";
import {
  InitialParcelToAdd,
  SelectedParcelType,
  SelectedZoneType,
} from "../../../ActionOthers/actionOther.types";

type Props = {
  allMustBeSown: boolean;
  existingActionId?: string;
  form: FormikProps<ActionEphFormValues>;
  formName: string;
  formType: string;
  handleInsert: (value: InitialParcelToAdd) => void;
  handleRemove: (index: number) => void;
  hasDuplicateParcelIds: boolean;
  isDraft: boolean;
  isEditing: boolean;
  isExisting: boolean;
  setActionTotalArea: React.Dispatch<React.SetStateAction<number>>;
  handleChangeCrop?: (crop?: CropTo) => void;
};

const ParcelsControl = ({
  allMustBeSown,
  existingActionId,
  form,
  formName,
  formType,
  handleChangeCrop,
  handleInsert,
  handleRemove,
  hasDuplicateParcelIds,
  isDraft,
  isEditing,
  isExisting,
  setActionTotalArea,
}: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [isInitialized, setIsInitialized] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const [targetCrop] = useField("targetCrop");

  const [actionDate] = useField("date");
  const crops = useSelector(getCrops);

  const { handleSetIsSplittingError, isSplitting, numberOfCheckedItems } =
    useContext(ActionDetailContext);

  const isEphAction = formType === "EPH";

  const updateParcelsActionArea = useCallback(
    (parcelsInForm: InitialParcelToAdd[], isDeleting?: boolean) => {
      const requestParam = mapRequestBodyParcelsSubtractionArea(parcelsInForm);
      (
        dispatch(
          updateParcelsSubtractionsArea(requestParam),
        ) as unknown as Promise<unknown>
      ).then((updatedParcelsArea: ParcelAreaTo[]) => {
        updatedParcelsArea.forEach((item) => {
          const index = parcelsInForm.findIndex(
            (parcel) => parcel.id === item.parcelId,
          );

          parcelsInForm[index].actionParcelTotalArea = item.area;

          form.setFieldValue(
            `parcels.${index}.actionParcelTotalArea`,
            item.area,
          );
        });
        if (isDeleting) setDeleting(false);
      });
    },
    [dispatch, form],
  );

  const parcelsInForm = form.values.parcels;
  const prevParcelsRef = useRef<{ parcelsInForm: InitialParcelToAdd[] }>({
    parcelsInForm,
  });

  useEffect(() => {
    const actionDateValue = getShortDateString(actionDate.value);
    const parcelIds = parcelsInForm.map((parcel) => parcel.id);

    const shouldFetchActionTargetSeedApplication =
      isEphAction && targetCrop.value && parcelsInForm.length > 0;

    // skip first render for existing actions
    if (!isInitialized && isExisting) {
      if (shouldFetchActionTargetSeedApplication) {
        dispatch(
          getEphTargetSeedApplicationApi(
            actionDateValue,
            targetCrop.value.id,
            parcelIds,
            existingActionId,
          ),
        );
      }
      setIsInitialized(true);
      prevParcelsRef.current = { parcelsInForm };
      return;
    }

    const parcelsChanged =
      parcelsInForm.length !== prevParcelsRef.current.parcelsInForm.length;

    if (parcelsInForm && parcelsChanged && isEditing) {
      if (shouldFetchActionTargetSeedApplication) {
        dispatch(
          getEphTargetSeedApplicationApi(
            actionDateValue,
            targetCrop.value.id,
            parcelIds,
            existingActionId,
          ),
        );
      }

      const parcelsAdded = differenceBy(
        parcelsInForm,
        prevParcelsRef.current.parcelsInForm,
        "id",
      );

      const parcelsRemove = differenceBy(
        prevParcelsRef.current.parcelsInForm,
        parcelsInForm,
        "id",
      );

      const fetchPromises: Promise<void>[] = [];

      if (parcelsAdded.length > 0) {
        parcelsAdded.forEach((parcel) => {
          const index = parcelsInForm.findIndex(
            (option) => option.id === parcel.id,
          );
          const fetchPromise = (
            dispatch(
              fetchParcelHistorySubtractions(parcel.id, parcelIds),
            ) as unknown as Promise<unknown>
          ).then((predefinedSas: SubtractionResponse[]) => {
            const sortedPredefinedSas = predefinedSas.sort((a, b) => {
              if (a.value === undefined) return 1;
              if (b.value === undefined) return -1;
              return a.value - b.value;
            });
            const mappedSubtractableAreas = isEphAction
              ? mapEphSubtractableAreasFrom(sortedPredefinedSas)
              : mapOtherSubtractableAreasFrom(sortedPredefinedSas);
            parcelsInForm[index].subtractableAreas = mappedSubtractableAreas;
          });
          fetchPromises.push(fetchPromise);
        });

        form.setFieldValue("parcels", parcelsInForm);

        if (!hasDuplicateParcelIds) {
          Promise.all(fetchPromises).then(() => {
            updateParcelsActionArea(parcelsInForm);
          });
        }
      }

      if (parcelsRemove.length > 0) {
        setDeleting(true);
        form.setFieldValue("parcels", parcelsInForm);
        updateParcelsActionArea(parcelsInForm, true);
      }
    }

    // for EPH form pre-select the TargetCrop according to the first selected parcel
    const resolvedTargetCrop = resolveTargetCrop(parcelsInForm, crops);

    if (
      isEphAction &&
      handleChangeCrop &&
      targetCrop.value === undefined &&
      resolvedTargetCrop &&
      isEditing
    ) {
      handleChangeCrop(resolvedTargetCrop);
    }

    if (
      isEphAction &&
      handleChangeCrop &&
      parcelsInForm.length === 0 &&
      prevParcelsRef.current.parcelsInForm.length > 0
    ) {
      handleChangeCrop(undefined);
      dispatch(resetEphTargetSeedApplicationApi());
    }

    prevParcelsRef.current = { parcelsInForm };
  }, [
    actionDate.value,
    dispatch,
    existingActionId,
    form,
    handleChangeCrop,
    hasDuplicateParcelIds,
    isEditing,
    isEphAction,
    isExisting,
    isInitialized,
    parcelsInForm,
    targetCrop.value,
    updateParcelsActionArea,
    JSON.stringify(form.values.plantProtections),
  ]);

  const prevTargetCrop = useRef(targetCrop.value);
  const prevActionDate = useRef(actionDate.value);

  useEffect(() => {
    if (isEphAction) {
      const targetCropChanged = !isEqual(
        targetCrop.value,
        prevTargetCrop.current,
      );
      const actionDateChanged = !actionDate.value.isSame(
        prevActionDate.current,
      );

      if (
        targetCrop.value &&
        parcelsInForm.length > 0 &&
        (targetCropChanged || actionDateChanged)
      ) {
        const actionDateValue = getShortDateString(actionDate.value);
        const parcelIds = parcelsInForm.map((parcel) => parcel.id);
        dispatch(
          getEphTargetSeedApplicationApi(
            actionDateValue,
            targetCrop.value.id,
            parcelIds,
            existingActionId,
          ),
        );
      }
      prevTargetCrop.current = targetCrop.value;
      prevActionDate.current = actionDate.value;
    }
  }, [
    actionDate,
    dispatch,
    parcelsInForm,
    form.values.plantProtections,
    isEphAction,
    targetCrop,
    existingActionId,
  ]);

  const parcelsArea = getTotalArea(parcelsInForm, formType, isExisting);

  useEffect(() => {
    setActionTotalArea(Number(parcelsArea.toFixed(5)));
  }, [parcelsArea, setActionTotalArea]);

  const handleSuggestionSelected = (
    selected: SelectedParcelType | SelectedZoneType,
  ) => {
    const isZone = "parcelCount" in selected;
    if (isZone) {
      (
        dispatch(getAllParcelsInZone(selected)) as unknown as Promise<unknown>
      ).then((parcelsInZone: ParcelTo[]) => {
        if (parcelsInZone.length) {
          const parcelsInForm = form.values.parcels;
          const parcelsInFormIds = parcelsInForm.map((p) => p.id);

          const checkedParcels = checkSelectedParcelsAgainstParcelsInZone(
            parcelsInZone,
            parcelsInFormIds,
          );
          form.setFieldValue(
            "parcels",
            parcelsInForm.concat(
              checkedParcels.map((p: ParcelTo) => initializeParcel(p)),
            ),
          );
        }
      });
    } else {
      handleInsert(initializeParcel(selected));
    }
  };

  const checkSelectedParcelsAgainstParcelsInZone = (
    parcelsInZone: ParcelTo[],
    parcelsInFormIds: string[],
  ) =>
    parcelsInZone.filter(
      (parcelInZone: ParcelTo) =>
        !parcelsInFormIds.some((id: string) => parcelInZone.id === id),
    );

  // call predefined subtractions when parcel is added and map them
  const initializeParcel = (parcel: ParcelTo) => ({
    ...parcel,
    subtractableAreas: initializeSubtractableAreas(),
    restrictedArea: 0,
    actionParcelTotalArea: parcel.area,
  });

  const initializeSubtractableAreas = () => ({
    absolute: [],
    boundary: [],
    water: [],
    populationProtectionZones: [],
    surfaceWaterProtectionZones: initialWaterProtectionZones(
      RestrictionType.SurfaceWaterProtectionZones,
    ),
    groundWaterProtectionZones: initialWaterProtectionZones(
      RestrictionType.GroundWaterProtectionZones,
    ),
    boundaryChecked: 0,
    waterChecked: 0,
    surfaceWaterProtectionZonesChecked: 0,
    groundWaterProtectionZonesChecked: 0,
    populationProtectionZonesChecked: 0,
  });

  const parcelsFieldError = form.errors.parcels === "error";
  const isParcelSelected = parcelsInForm.length > 0;

  return (
    <Fragment>
      {!isEditing && (
        <div className={classes.parcelsLabel}>
          <FormattedMessage id="common.parcels" />
        </div>
      )}
      {isEditing && !hasDuplicateParcelIds && (
        <div className={classes.parcelSelector}>
          <ParcelsZoneAutocompleteSelector
            formName={formName}
            formType={formType}
            label={<FormattedMessage id="ParcelZoneSelector.placeholder" />}
            onChange={handleSuggestionSelected}
            parcels={parcelsInForm}
          />
        </div>
      )}
      {parcelsFieldError && (
        <div className={classes.error}>
          <FormattedMessage id="ParcelControl.chooseParcel" tagName="span" />
        </div>
      )}
      <div id="action-parcel-list">
        <ParcelsList
          actionDate={getShortDateString(actionDate.value)}
          allMustBeSown={allMustBeSown}
          form={form}
          formType={formType}
          handleRemove={handleRemove}
          isDeleting={deleting}
          isDraft={isDraft}
          isEditing={isEditing}
          isEphAction={isEphAction}
          isExisting={isExisting}
          parcelsInForm={parcelsInForm}
          updateParcelsActionArea={updateParcelsActionArea}
        />
      </div>
      <SplitActionsWarningMessage
        isSplitting={isSplitting}
        numberOfCheckedItems={numberOfCheckedItems}
        numberOfParcels={parcelsInForm?.length}
        setIsSplittingError={handleSetIsSplittingError}
      />

      {hasDuplicateParcelIds && isEditing && (
        <Grid
          alignItems="center"
          className={classes.errorDuplicatedParcels}
          container
          justifyContent="left"
        >
          <FormattedMessage id="action.hasDuplicateParcelIds" />
        </Grid>
      )}
      {isParcelSelected && (
        <Grid container justifyContent="center" spacing={2}>
          <Grid item lg={6} md={7} sm={8} xs={12}>
            <TotalActivityArea parcelsArea={parcelsArea} />
          </Grid>
        </Grid>
      )}
    </Fragment>
  );
};

export { ParcelsControl };

const useStyles = makeStyles((theme: Theme) => ({
  parcelsLabel: {
    color: theme.palette.grey[500],
  },
  parcelSelector: {
    marginBottom: 15,
  },
  error: {
    color: theme.palette.error.main,
    fontSize: "12px",
  },
  errorDuplicatedParcels: {
    color: theme.palette.error.main,
    fontSize: "16px",
  },
}));
