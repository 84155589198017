import React from "react";

import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

import { ActiveSubstanceTo } from "../../../../../generated/api/agroevidence";
import SelectionItem from "../../../../../shared/components/common/SelectionItem/SelectionItem";

type Props = {
  items: ActiveSubstanceTo[];
  onItemRemove: (val: number) => void;
  isEditing: boolean;
};

export const ActiveSubstancesList = ({
  isEditing,
  items = [],
  onItemRemove,
}: Props) => {
  const classes = useStyles({ isEditing });

  const renderIntegratedProductionItem = (
    integratedProductionId: string,
    checkParam?: boolean,
  ) => (
    <div className={classes.integratedProductionItem}>
      {checkParam ? <CheckIcon color="primary" /> : <ClearIcon color="error" />}
      <FormattedMessage
        id={`Catalogues.plantProtection.detail.integratedProduction.${integratedProductionId}`}
      />
    </div>
  );

  const renderSubstanceItem = (field: ActiveSubstanceTo) => (
    <div className={classes.row} key={field.id}>
      <div className={classes.name}>{field.name}</div>
      <div className={classes.integratedProduction}>
        {renderIntegratedProductionItem(
          "vineyard",
          field.isIntegratedProductionVineyard,
        )}
        {renderIntegratedProductionItem(
          "orchard",
          field.isIntegratedProductionOrchard,
        )}
        {renderIntegratedProductionItem(
          "vegetable",
          field.isIntegratedProductionVegetable,
        )}
      </div>
    </div>
  );

  return (
    <div className={classes.activeSubstances} id="active-substances-list">
      {items.map((field, index) =>
        isEditing ? (
          <SelectionItem
            editing={isEditing}
            key={field.id}
            onRemoveItem={() => onItemRemove(index)}
          >
            {renderSubstanceItem(field)}
          </SelectionItem>
        ) : (
          renderSubstanceItem(field)
        ),
      )}
    </div>
  );
};

const useStyles = makeStyles<Theme, { isEditing: boolean }>((theme) => ({
  activeSubstances: ({ isEditing }) =>
    isEditing ? { marginTop: 25, marginBottom: 35 } : {},
  name: ({ isEditing }) => ({
    fontSize: isEditing ? 16 : 14,
    marginRight: 10,
    [theme.breakpoints.down("md")]: {
      marginBottom: 10,
    },
    fontWeight: isEditing ? 400 : 500,
  }),
  row: ({ isEditing }) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: isEditing ? 0 : 20,
    "&:last-child": {
      marginBottom: 0,
    },
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      alignItems: "center",
    },
  }),
  integratedProduction: ({ isEditing }) => ({
    display: "flex",
    justifyContent: "space-between",
    marginRight: 20,
    color: isEditing ? theme.palette.common.black : theme.palette.grey[500],
  }),
  integratedProductionItem: {
    display: "flex",
    alignItems: "center",
    marginRight: 20,
    fontSize: 14,
    fontWeight: 500,
  },
}));
