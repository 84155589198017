import React, { PropsWithChildren } from "react";

import Button, { ButtonProps } from "@mui/material/Button";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";

type Props = {
  id?: ButtonProps["id"];
  variant?: ButtonProps["variant"];
  color?: ButtonProps["color"];
  size?: ButtonProps["size"];
  classes?: Record<string, string>;
  isActive: boolean;
} & ButtonProps;

const MainButton = ({
  children,
  classes: propClasses = {},
  color = "primary",
  id = "switcher-button",
  isActive,
  size = "large",
  variant = "outlined",
  ...rest
}: PropsWithChildren<Props>) => {
  const classes = useStyles();

  return (
    <Button
      color={color}
      id={id}
      size={size}
      variant={variant}
      {...rest}
      classes={{
        root: classNames({
          [classes.root]: true,
          [propClasses.custom]: !!propClasses.custom,
          [classes.isActive]: isActive,
        }),
      }}
      sx={{
        textTransform: "unset",
      }}
    >
      {children}
    </Button>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: "11px 16px",
    borderRadius: 8,
  },
  isActive: {
    width: "max-content",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
}));

export { MainButton };
