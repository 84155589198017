import React from "react";

import { Theme } from "@mui/material";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import { makeStyles } from "@mui/styles";
import { Formik, Form, Field } from "formik";
import moment, { Moment } from "moment";
import { FormattedMessage } from "react-intl";

import CfAutocomplete from "../../../../shared/components/common/CfAutocomplete/CfAutocomplete";
import CfDialog from "../../../../shared/components/common/CfDialog/CfDialog";
import CfFormControl from "../../../../shared/components/form/CfFormControl/CfFormControl";
import CfFormikDatePicker from "../../../../shared/components/form/CfFormikDatePicker/CfFormikDatePicker";
import CfFormikNumericTextField from "../../../../shared/components/form/CfFormikNumericTextField/CfFormikNumericTextField";
import CfFormikTextField from "../../../../shared/components/form/CfFormikTextField/CfFormikTextField";
import * as validators from "../../../../shared/misc/validators";
import UnitService from "../../../../shared/services/Unit.service";
import { AnyTodo } from "../../../../types";
import StoresService, { transactionTypes } from "../services/StoresService";

import {
  StoreTransactionTypeSelector,
  TransactionType,
} from "./StoreTransactionTypeSelector";

export interface MovementFormValues {
  currAmount: number;
  currUnit: string;
  expense: AnyTodo;
  type: TransactionType;
  amount?: number;
  unit: { id: string; name: string };
  date: Moment;
  note: string;
  transactionId?: number;
}

interface Props {
  storeItem: AnyTodo;
  transactionItem?: AnyTodo;
  onAccept: (values: MovementFormValues) => void;
  handleClose: () => void;
  opened: boolean;
}

const StoreMovementDialog = ({
  handleClose,
  onAccept,
  opened,
  storeItem,
  transactionItem,
}: Props) => {
  const classes = useStyles();

  const compatibleUnits = UnitService.getCompatibleUnits(storeItem.unitId);
  const storeUnit = compatibleUnits.find(
    (unit) => unit.id === storeItem.unitId,
  );

  const initialValues: MovementFormValues = {
    // store related props
    currAmount: storeItem.currentBalance,
    currUnit: storeItem.unitId,
    expense: storeItem.material,
    unit: storeUnit || compatibleUnits[0],
    // transaction related props
    transactionId: transactionItem?.id || undefined,
    amount: transactionItem?.amount || undefined,
    type:
      StoresService.getTransactionType(transactionItem?.type) ||
      transactionTypes[0],
    date: moment(transactionItem?.date) || moment(),
    note: transactionItem?.note || "",
  };

  const handleSubmit = (values: MovementFormValues) => {
    const data = StoresService.getTransactionDto(values, transactionItem);
    onAccept(data as AnyTodo);
  };

  return (
    <Formik<MovementFormValues>
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ resetForm, setFieldValue, submitForm, values }) => {
        const setTransactionType = (transactionType: TransactionType) => {
          setFieldValue(`type`, transactionType);
        };

        const setUnit = (value: { id: string; name: string }) => {
          setFieldValue(`unit`, value);
        };

        return (
          <CfDialog
            acceptText={<FormattedMessage id="Stores.stock" />}
            cancelText={<FormattedMessage id="common.cancel" />}
            onAccept={submitForm}
            onClose={resetForm}
            opened={opened}
            onCancel={() => {
              resetForm();
              handleClose();
            }}
            title={
              <FormattedMessage
                id="Stores.transaction"
                values={{
                  material:
                    storeItem && storeItem.material
                      ? ` - ${storeItem.material.name}`
                      : "",
                }}
              />
            }
          >
            <Form>
              <CfFormControl>
                <Grid container spacing={2}>
                  <Grid item sm={8} xs={6}>
                    <CfFormControl
                      classes={{ formControl: classes.customFormControl }}
                    >
                      <Field
                        component={CfFormikNumericTextField}
                        disabled
                        name="currAmount"
                        label={
                          <FormattedMessage id="Stores.currently-on-store" />
                        }
                      />
                    </CfFormControl>
                  </Grid>
                  <Grid item sm={4} xs={6}>
                    <CfFormControl
                      classes={{ formControl: classes.customFormControl }}
                    >
                      <Field
                        component={CfFormikTextField}
                        disabled
                        label={<FormattedMessage id="common.unit" />}
                        name="currUnit"
                      />
                    </CfFormControl>
                  </Grid>
                </Grid>
              </CfFormControl>
              <CfFormControl>
                <StoreTransactionTypeSelector
                  defaultValues={values.type}
                  onChange={setTransactionType}
                  transactionTypes={transactionTypes}
                />
              </CfFormControl>
              <CfFormControl>
                <Grid container spacing={2}>
                  <Grid item sm={8} xs={6}>
                    <CfFormControl
                      classes={{ formControl: classes.customFormControl }}
                    >
                      <Field
                        component={CfFormikNumericTextField}
                        label={<FormattedMessage id="common.amount" />}
                        name="amount"
                        validate={validators.required}
                        validateOnBlur
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {values.type.sign}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </CfFormControl>
                  </Grid>
                  <Grid item sm={4} xs={6}>
                    <CfFormControl
                      classes={{ formControl: classes.customFormControl }}
                    >
                      <CfAutocomplete
                        defaultValues={storeUnit}
                        disableClearable
                        id="unit"
                        label={<FormattedMessage id="common.unit" />}
                        onChange={setUnit}
                        suggestions={compatibleUnits}
                        testId="unit-selector"
                      />
                    </CfFormControl>
                  </Grid>
                </Grid>
              </CfFormControl>
              <CfFormControl>
                <Field
                  component={CfFormikDatePicker}
                  label={<FormattedMessage id="Stores.store-date" />}
                  name="date"
                  validate={validators.formikDateValidRequired}
                />
              </CfFormControl>
              <CfFormControl>
                <Field
                  component={CfFormikTextField}
                  label={<FormattedMessage id="common.note" />}
                  name="note"
                />
              </CfFormControl>
            </Form>
          </CfDialog>
        );
      }}
    </Formik>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  customFormControl: {
    margin: "5px 0px 5px 0px",
  },
  popoverPaper: {
    overflow: "hidden",
    padding: 10,
  },
  selectedFilter: {
    width: "max-content",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

export { StoreMovementDialog };
