import { AnyTodo } from "../../../../types";

import { DeviceConfigTo } from "../../../../shared/api/irrigation/devices/devices.types";

export type DeviceConfigFormInitType = {
  pulseLengthMs: number;
  outputVoltage: number;
  valveConfig: {
    valveNumber: number;
    forceRepetitions: number;
    forceIntervalMs: number;
    enabled: boolean;
    notes: string;
  }[];
};

export const mapDeviceConfigFormInitialValues = (
  deviceConfig: DeviceConfigTo,
) => ({
  pulseLengthMs: deviceConfig.pulseLengthMs,
  outputVoltage: deviceConfig.outputVoltage,
  valveConfig: deviceConfig.valveConfig
    .map((valve) => ({
      valveNumber: valve.valveNumber,
      forceRepetitions: valve.forceOnRepetitions ?? 0,
      forceIntervalMs: valve.forceOnIntervalMs ?? 0,
      enabled: valve.enabled,
      notes: valve.notes ?? "",
    }))
    .sort((a: AnyTodo, b: AnyTodo) => a.valveNumber - b.valveNumber),
});

// ==================================================================
// ======================== MAP REQUEST BODY ========================
// ==================================================================

export const mapRequestBodyDeviceConfigTo = (
  deviceId: string,
  values: DeviceConfigFormInitType,
) => ({
  deviceId,
  pulseLengthMs: values.pulseLengthMs,
  outputVoltage: values.outputVoltage,
  valveConfig: values.valveConfig.map((valve) => ({
    valveNumber: valve.valveNumber,
    forceOffRepetitions: valve.forceRepetitions,
    forceOffIntervalMs: valve.forceIntervalMs,
    forceOnRepetitions: valve.forceRepetitions,
    forceOnIntervalMs: valve.forceIntervalMs,
    enabled: valve.enabled,
    notes: valve.notes,
  })),
});
