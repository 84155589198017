import React from "react";

import {
  Button,
  Theme,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import TableCell from "@mui/material/TableCell";
import MuiTableFooter from "@mui/material/TableFooter";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@mui/styles";
import { COLOR_PRIMARY } from "../../theme";

type Props = {
  count?: number;
  rowsPerPage: number;
  page: number;
  setRowsPerPage: (val: number) => void;
  setPage: (val: number) => void;
};

const TableFooter = ({
  count = 0,
  page,
  rowsPerPage,
  setPage,
  setRowsPerPage,
}: Props) => {
  const classes = useStyles();

  const totalPages = Math.ceil(count / rowsPerPage);

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const getPagination = () => {
    const pages = [];
    const maxVisiblePages = 7; // Fixed number of buttons to display

    if (totalPages <= maxVisiblePages) {
      for (let i = 0; i < totalPages; i += 1) {
        pages.push(i);
      }
    } else {
      const startEllipsis = page > 2;
      const endEllipsis = page < totalPages - 3;

      pages.push(0); // Always include the first page

      if (startEllipsis) {
        pages.push("...");
      }

      let startPage = Math.max(1, page - 1);
      let endPage = Math.min(totalPages - 2, page + 1);

      if (page <= 2) {
        startPage = 1;
        endPage = 3;
      } else if (page >= totalPages - 3) {
        startPage = totalPages - 4;
        endPage = totalPages - 2;
      }

      for (let i = startPage; i <= endPage; i += 1) {
        pages.push(i);
      }

      if (endEllipsis) {
        pages.push("...");
      }

      pages.push(totalPages - 1); // Always include the last page
    }

    return pages;
  };

  const onRowsPerPageChange = (event: SelectChangeEvent<number>) => {
    setRowsPerPage(event.target.value as number);
  };

  return (
    <div className={classes.footerContainer}>
      <MuiTableFooter>
        <TableRow>
          <TableCell className={classes.cell}>
            <div className={classes.cellContent}>
              <div aria-label="padding-placeholder" style={{ width: 60 }} />
              <div>
                {getPagination().map((pageNum, index) => (
                  <Button
                    disabled={typeof pageNum !== "number"}
                    key={index}
                    variant={page === pageNum ? "contained" : "outlined"}
                    className={`${classes.button} ${
                      page === pageNum ? classes.selectedButton : ""
                    }`}
                    onClick={() =>
                      typeof pageNum === "number" &&
                      page !== pageNum &&
                      handlePageChange(pageNum)
                    }
                  >
                    {pageNum === "..." ? "..." : Number(pageNum) + 1}
                  </Button>
                ))}
              </div>
              <div>
                <Select
                  variant="outlined"
                  className={classes.rowsPerPageSelect}
                  onChange={onRowsPerPageChange}
                  value={rowsPerPage}
                >
                  {[10, 25, 50, 100].map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
          </TableCell>
        </TableRow>
      </MuiTableFooter>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  cellContent: {
    display: "flex",
    flexWrap: "wrap",
    gap: 10,
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
  },
  button: {
    minWidth: 40,
    border: "none",
    margin: "0 4px",
    "&.MuiButton-contained": {
      backgroundColor: COLOR_PRIMARY.cleverfarm.main,
      color: theme.palette.common.white,
    },
    "&.MuiButton-outlined": {
      backgroundColor: theme.palette.common.white,
      color: COLOR_PRIMARY.cleverfarm.main,
    },
    "&:disabled": {
      backgroundColor: "#f0f0f0",
      color: "#a0a0a0",
      border: "none",
    },
  },
  selectedButton: {
    backgroundColor: COLOR_PRIMARY.cleverfarm.main,
    color: theme.palette.common.white,
  },
  cell: {
    textAlign: "center",
    padding: "16px 0",
    width: "100vw",
    paddingLeft: 0,
    paddingRight: 0,
    borderBottom: "none",
  },
  rowsPerPageSelect: {
    marginLeft: 16,

    "& .MuiInputBase-input": {
      paddingTop: "8.5px",
      paddingBottom: "8.5px",
    },
    "& .MuiSelect-icon": {
      color: COLOR_PRIMARY.cleverfarm.main,
    },
  },
  footerContainer: {
    width: "100%",
    position: "relative",
    bottom: 0,
    display: "flex",
  },
}));

export { TableFooter };
