import React, { ReactNode, useEffect } from "react";

import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import {
  getOrganisms,
  getIsFetchingOrganisms,
} from "../../../../../shared/api/agroevidence/catalogues/plantProtection/plantProtection.selectors";

import { fetchOrganisms } from "../../../../actions/catalogues.actions";

import { OrganismTo } from "../../../../../generated/api/agroevidence";
import { resetOrganismsApi } from "../../../../../shared/api/agroevidence/catalogues/plantProtection/plantProtection.api";
import CfAutocomplete from "../../../../../shared/components/common/CfAutocomplete/CfAutocomplete";

type Props = {
  defaultValues?: OrganismTo | OrganismTo[];
  disabled?: boolean;
  error?: boolean;
  isMultiple?: boolean;
  label: ReactNode;
  isUsed?: boolean;
  validOnly?: boolean;
  onChange: (items: OrganismTo | OrganismTo[]) => void;
};

export const OrganismsSelector = ({
  defaultValues,
  disabled = false,
  error = false,
  isMultiple = false,
  isUsed = false,
  label,
  onChange,
  validOnly = false,
}: Props) => {
  const dispatch = useDispatch();

  const organisms = useSelector(getOrganisms);
  const isFetching = useSelector(getIsFetchingOrganisms);

  useEffect(() => {
    dispatch(fetchOrganisms("", isUsed, validOnly));

    return () => {
      dispatch(resetOrganismsApi());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CfAutocomplete
      defaultValues={defaultValues}
      disabled={disabled}
      error={error}
      helperText={error ? <FormattedMessage id="validation.required" /> : ""}
      id="organisms-selector"
      isFetching={isFetching}
      isMultiple={isMultiple}
      label={label}
      onChange={onChange}
      suggestions={organisms}
      testId="organisms-selector"
      loadOptions={(search: string) =>
        dispatch(fetchOrganisms(search, isUsed, validOnly))
      }
    />
  );
};
