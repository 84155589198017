import React from "react";

import { Theme } from "@mui/material";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import { makeStyles } from "@mui/styles";
import { Formik, Form, Field, FormikErrors } from "formik";
import moment, { Moment } from "moment";
import { FormattedMessage } from "react-intl";

import CfAutocomplete from "../../../../shared/components/common/CfAutocomplete/CfAutocomplete";
import CfDialog from "../../../../shared/components/common/CfDialog/CfDialog";
import CfFormControl from "../../../../shared/components/form/CfFormControl/CfFormControl";
import CfFormikDatePicker from "../../../../shared/components/form/CfFormikDatePicker/CfFormikDatePicker";
import CfFormikNumericTextField from "../../../../shared/components/form/CfFormikNumericTextField/CfFormikNumericTextField";
import CfFormikTextField from "../../../../shared/components/form/CfFormikTextField/CfFormikTextField";
import * as validators from "../../../../shared/misc/validators";
import { AnyTodo } from "../../../../types";
import {
  StoreTransactionTypeSelector,
  TransactionType,
} from "../../shared/components/StoreTransactionTypeSelector";
import StoresService, {
  transactionTypes,
} from "../../shared/services/StoresService";

import { StoreMaterialSelector } from "./StoreMaterialSelector";

export interface NewMovementFormValues {
  expense?: AnyTodo;
  type: TransactionType;
  amount?: number;
  unit: { id: string; name: string };
  date: Moment;
  note: string;
}

interface Props {
  materialTypeId: string;
  onAccept: (values: NewMovementFormValues) => void;
  handleClose: () => void;
  opened: boolean;
}

const StoreNewMovementDialog = ({
  handleClose,
  materialTypeId,
  onAccept,
  opened,
}: Props) => {
  const classes = useStyles();

  const allowedUnits = StoresService.getAllowedUnits(materialTypeId);

  const initialValues: NewMovementFormValues = {
    expense: undefined,
    type: transactionTypes[0],
    amount: undefined,
    unit: allowedUnits[0],
    date: moment(),
    note: "",
  };

  const handleValidate = (values: NewMovementFormValues) => {
    const errors: FormikErrors<NewMovementFormValues> = {};

    if (!values.expense) {
      errors.expense = "Required";
    }

    return errors;
  };

  const resolvePlaceholder = () => {
    switch (materialTypeId) {
      case "FR":
        return <FormattedMessage id="Stores.store-new-fertilizer" />;
      case "CH":
        return <FormattedMessage id="Stores.store-new-product" />;
      case "SD":
        return <FormattedMessage id="Stores.store-new-seed" />;
      default:
        return <FormattedMessage id="Stores.store-new-material" />;
    }
  };

  const handleSubmit = (values: NewMovementFormValues) => {
    const dtto = StoresService.getTransactionDto(values);
    onAccept(dtto as AnyTodo);
  };

  return (
    <Formik<NewMovementFormValues>
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={handleValidate}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ errors, resetForm, setFieldValue, submitForm, values }) => {
        const setMaterial = (suggestion: AnyTodo) => {
          setFieldValue("expense", suggestion);
        };

        const setTransactionType = (transactionType: TransactionType) => {
          setFieldValue(`type`, transactionType);
        };

        const setUnit = (value: { id: string; name: string }) => {
          setFieldValue(`unit`, value);
        };

        return (
          <CfDialog
            acceptText={<FormattedMessage id="Stores.stock" />}
            cancelText={<FormattedMessage id="common.cancel" />}
            onAccept={submitForm}
            onClose={resetForm}
            opened={opened}
            title={resolvePlaceholder()}
            onCancel={() => {
              resetForm();
              handleClose();
            }}
          >
            <Form>
              <CfFormControl>
                <StoreMaterialSelector
                  error={!!errors.expense}
                  materialTypeId={materialTypeId}
                  onChange={setMaterial}
                />
              </CfFormControl>
              <CfFormControl>
                <StoreTransactionTypeSelector
                  defaultValues={values.type}
                  onChange={setTransactionType}
                  transactionTypes={transactionTypes}
                />
              </CfFormControl>
              <CfFormControl>
                <Grid container spacing={2}>
                  <Grid item sm={8} xs={6}>
                    <CfFormControl
                      classes={{ formControl: classes.customFormControl }}
                    >
                      <Field
                        component={CfFormikNumericTextField}
                        label={<FormattedMessage id="common.amount" />}
                        name="amount"
                        validate={validators.required}
                        validateOnBlur
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {values.type.sign}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </CfFormControl>
                  </Grid>
                  <Grid item sm={4} xs={6}>
                    <CfFormControl
                      classes={{ formControl: classes.customFormControl }}
                    >
                      <CfAutocomplete
                        defaultValues={allowedUnits[0]}
                        disableClearable
                        id="unit"
                        label={<FormattedMessage id="common.unit" />}
                        onChange={setUnit}
                        suggestions={allowedUnits}
                        testId="unit-selector"
                      />
                    </CfFormControl>
                  </Grid>
                </Grid>
              </CfFormControl>
              <CfFormControl>
                <Field
                  component={CfFormikDatePicker}
                  label={<FormattedMessage id="Stores.store-date" />}
                  name="date"
                  validate={validators.formikDateValidRequired}
                />
              </CfFormControl>
              <CfFormControl>
                <Field
                  component={CfFormikTextField}
                  label={<FormattedMessage id="common.note" />}
                  name="note"
                />
              </CfFormControl>
            </Form>
          </CfDialog>
        );
      }}
    </Formik>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  customFormControl: {
    margin: "5px 0px 5px 0px",
  },
  popoverPaper: {
    overflow: "hidden",
    padding: 10,
  },
  selectedFilter: {
    width: "max-content",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

export { StoreNewMovementDialog };
