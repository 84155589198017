import React, { Fragment, useEffect, useState } from "react";

import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import isEmpty from "lodash/isEmpty";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { getError } from "../../../../shared/api/stores/stores/stores.selectors";
import {
  getStoresMaterialType,
  getStoresTextFilter,
  getStoresShowNullValues,
} from "../selectors/stores.selectors";

import { setTextFilter } from "../../../../shared/actions/filter.actions";
import {
  setStoreShowNullValues,
  saveNewStore,
  deleteStore,
  exportStores,
} from "../actions/stores.actions";

import { NAMESPACE as namespace } from "../reducer/stores.reducer";

import { resetStores } from "../../../../shared/api/stores/stores/stores.api";
import CfDialog from "../../../../shared/components/common/CfDialog/CfDialog";
import CfErrorPage from "../../../../shared/components/common/CfErrorPage/CfErrorPage";
import PageHeader from "../../../../shared/components/common/PageHeader/PageHeader";
import PageHeading from "../../../../shared/components/common/PageHeading/PageHeading";
import { StoreExport } from "../../shared/components/StoreExport";
import { StoreFabButton } from "../../shared/components/StoreFabButton";
import { StoreMovementDialog } from "../../shared/components/StoreMovementDialog";
import { StoreDisplayNull } from "../components/StoreDisplayNull";
import { StoreNewMovementDialog } from "../components/StoreNewMovementDialog";
import { StoresTabs } from "../components/StoresTabs";
import { StoreTextFilter } from "../components/StoreTextFilter";

import { StoresTable } from "./StoresTable";

const KEYS = {
  DELETE: "delete",
  CREATE: "create",
  UPDATE: "update",
};

const Stores = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const showNullValues = useSelector(getStoresShowNullValues);
  const materialTypeId = useSelector(getStoresMaterialType);
  const textFilter = useSelector(getStoresTextFilter);
  const error = useSelector(getError);

  const { farmId } = useParams();

  const [state, setState] = useState({
    [KEYS.CREATE]: false,
    [KEYS.DELETE]: false,
    [KEYS.UPDATE]: false,
    item: {},
  });

  useEffect(
    () => () => {
      dispatch(resetStores());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleDialogOpen = (key, item = {}) => {
    setState({
      [key]: true,
      item,
    });
  };

  const handleDialogClose = (key) => {
    setState({
      [key]: false,
      item: {},
    });
  };

  const handleDialogAccept = (key, dto = {}) => {
    const { item } = state;

    handleDialogClose(key);
    switch (key) {
      case KEYS.DELETE:
        return dispatch(deleteStore(farmId, item.id));
      case KEYS.CREATE:
      case KEYS.UPDATE:
        return dispatch(saveNewStore(dto));
      default:
        throw new Error("Unrecognized store action");
    }
  };

  const setStoreShowNullValuesHandler = (value) => {
    dispatch(setStoreShowNullValues(value));
  };

  return (
    <CfErrorPage error={error}>
      <div>
        <div className={classes.headerWithTabs}>
          <div className={classes.headerWrapper}>
            <PageHeader
              actionButtons={
                <Fragment>
                  <StoreExport
                    handleExport={(format) => dispatch(exportStores(format))}
                  />
                  <StoreFabButton
                    callback={() => handleDialogOpen(KEYS.CREATE)}
                  />
                  {state.create && (
                    <StoreNewMovementDialog
                      handleClose={() => handleDialogClose(KEYS.CREATE)}
                      materialTypeId={materialTypeId}
                      onAccept={(dto) => handleDialogAccept(KEYS.CREATE, dto)}
                      opened={state.create}
                    />
                  )}
                </Fragment>
              }
              heading={
                <PageHeading
                  dataTest="stores-heading"
                  translationId="common.stores"
                />
              }
            />
          </div>
          <StoresTabs />
        </div>
        <div className={classes.bodyWrapper}>
          <Grid container spacing={0}>
            <Grid item lg={4} md={5} sm={7} xl={3} xs={12}>
              <StoreTextFilter
                materialTypeId={materialTypeId}
                namespace={namespace}
                textFilter={textFilter}
                handleTextFilterReset={() =>
                  dispatch(setTextFilter("", namespace))
                }
              />
            </Grid>
            <Grid item lg={8} md={7} sm={5} xl={9} xs={12}>
              <StoreDisplayNull
                setStoreShowNullValues={setStoreShowNullValuesHandler}
                showNullValues={showNullValues}
              />
            </Grid>
          </Grid>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <StoresTable
                farmId={farmId}
                onDeleteStoreClick={(storeItem) =>
                  handleDialogOpen(KEYS.DELETE, storeItem)
                }
                onUpdateStoreClick={(storeItem) =>
                  handleDialogOpen(KEYS.UPDATE, storeItem)
                }
              />
              {state.update && (
                <StoreMovementDialog
                  handleClose={() => handleDialogClose(KEYS.UPDATE)}
                  onAccept={(dto) => handleDialogAccept(KEYS.UPDATE, dto)}
                  opened={state.update}
                  storeItem={state.item}
                />
              )}
              {state.delete && (
                <CfDialog
                  acceptText={<FormattedMessage id="common.delete" />}
                  cancelText={<FormattedMessage id="common.cancel" />}
                  dialogHeight="75px"
                  onAccept={() => handleDialogAccept(KEYS.DELETE)}
                  onCancel={() => handleDialogClose(KEYS.DELETE)}
                  onClose={() => handleDialogClose(KEYS.DELETE)}
                  opened={state.delete}
                  title={<FormattedMessage id="Stores.store-delete-material" />}
                >
                  <FormattedMessage
                    id="Stores.store-delete-material-confirm"
                    values={{
                      material: !isEmpty(state.item)
                        ? state.item.material.name
                        : "",
                    }}
                  />
                </CfDialog>
              )}
            </Grid>
          </Grid>
        </div>
      </div>
    </CfErrorPage>
  );
};

const useStyles = makeStyles((theme) => ({
  bodyWrapper: {
    padding: theme.spacing(2),
  },
  tableContainer: {
    padding: "8px",
  },
  headerWithTabs: {
    backgroundColor: theme.palette.common.white,
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
  },
  headerWrapper: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
}));

export { Stores };
