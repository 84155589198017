const PREFIX = "AGROEVIDENCE_FARMS";
const PREFIX_LEGACY = "CORE_FARMS";

export const GET_FARMS_REQUEST = `${PREFIX}/GET_FARMS_REQUEST`;
export const GET_FARMS_SUCCESS = `${PREFIX}/GET_FARMS_SUCCESS`;
export const GET_FARMS_ERROR = `${PREFIX}/GET_FARMS_ERROR`;
export const RESET_FARMS = `${PREFIX}/RESET_FARMS`;

export const GET_FARM_REQUEST = `${PREFIX}/GET_FARM_REQUEST `;
export const GET_FARM_SUCCESS = `${PREFIX}/GET_FARM_SUCCESS`;
export const GET_FARM_ERROR = `${PREFIX}/GET_FARM_ERROR`;
export const RESET_FARM = `${PREFIX}/RESET_FARM`;

export const GET_FARM_EAGRI_AUTH_DATA_REQUEST = `${PREFIX}/GET_FARM_EAGRI_AUTH_DATA_REQUEST`;
export const GET_FARM_EAGRI_AUTH_DATA_SUCCESS = `${PREFIX}/GET_FARM_EAGRI_AUTH_DATA_SUCCESS`;
export const GET_FARM_EAGRI_AUTH_DATA_ERROR = `${PREFIX}/GET_FARM_EAGRI_AUTH_DATA_ERROR`;

export const UPDATE_FARM_EAGRI_AUTH_DATA_REQUEST = `${PREFIX}/UPDATE_FARM_EAGRI_AUTH_DATA_REQUEST`;
export const UPDATE_FARM_EAGRI_AUTH_DATA_SUCCESS = `${PREFIX}/UPDATE_FARM_EAGRI_AUTH_DATA_SUCCESS`;
export const UPDATE_FARM_EAGRI_AUTH_DATA_ERROR = `${PREFIX}/UPDATE_FARM_EAGRI_AUTH_DATA_ERROR`;

export const GET_COUNTRIES_REQUEST = `${PREFIX_LEGACY}/GET_COUNTRIES_REQUEST`;
export const GET_COUNTRIES_SUCCESS = `${PREFIX_LEGACY}/GET_COUNTRIES_SUCCESS`;
export const GET_COUNTRIES_ERROR = `${PREFIX_LEGACY}/GET_COUNTRIES_ERROR`;
export const RESET_COUNTRIES = `${PREFIX}/RESET_COUNTRIES`;

export const CREATE_EMPTY_FARM_REQUEST = `${PREFIX}/CREATE_EMPTY_FARM_REQUEST`;
export const CREATE_EMPTY_FARM_SUCCESS = `${PREFIX}/CREATE_EMPTY_FARM_SUCCESS`;
export const CREATE_EMPTY_FARM_ERROR = `${PREFIX}/CREATE_EMPTY_FARM_ERROR`;

export const CREATE_EAGRI_FARM_REQUEST = `${PREFIX}/CREATE_EAGRI_FARM_REQUEST`;
export const CREATE_EAGRI_FARM_SUCCESS = `${PREFIX}/CREATE_EAGRI_FARM_SUCCESS`;
export const CREATE_EAGRI_FARM_ERROR = `${PREFIX}/CREATE_EAGRI_FARM_ERROR`;

export const VALIDATE_FARM_FILE_REQUEST = `${PREFIX}/VALIDATE_FARM_FILE_REQUEST`;
export const VALIDATE_FARM_FILE_SUCCESS = `${PREFIX}/VALIDATE_FARM_FILE_SUCCESS`;
export const VALIDATE_FARM_FILE_ERROR = `${PREFIX}/VALIDATE_FARM_FILE_ERROR`;

export const CREATE_FARM_BY_FILE_REQUEST = `${PREFIX}/CREATE_FARM_BY_FILE_REQUEST`;
export const CREATE_FARM_BY_FILE_SUCCESS = `${PREFIX}/CREATE_FARM_BY_FILE_SUCCESS`;
export const CREATE_FARM_BY_FILE_ERROR = `${PREFIX}/CREATE_FARM_BY_FILE_ERROR`;

export const PUT_LPIS_UPDATE_REQUEST = `${PREFIX}/PUT_LPIS_UPDATE_REQUEST `;
export const PUT_LPIS_UPDATE_SUCCESS = `${PREFIX}/PUT_LPIS_UPDATE_SUCCESS`;
export const PUT_LPIS_UPDATE_ERROR = `${PREFIX}/PUT_LPIS_UPDATE_ERROR`;

export const GET_LPIS_UPDATE_RESULTS_REQUEST = `${PREFIX}/GET_LPIS_UPDATE_RESULTS_REQUEST `;
export const GET_LPIS_UPDATE_RESULTS_SUCCESS = `${PREFIX}/GET_LPIS_UPDATE_RESULTS_SUCCESS`;
export const GET_LPIS_UPDATE_RESULTS_ERROR = `${PREFIX}/GET_LPIS_UPDATE_RESULTS_ERROR`;
export const RESET_LPIS_UPDATE_RESULTS = `${PREFIX}/RESET_LPIS_UPDATE_RESULTS`;

export const GET_LPIS_AVAILABLE_UPDATES_REQUEST = `${PREFIX}/GET_LPIS_AVAILABLE_UPDATES_REQUEST`;
export const GET_LPIS_AVAILABLE_UPDATES_SUCCESS = `${PREFIX}/GET_LPIS_AVAILABLE_UPDATES_SUCCESS`;
export const GET_LPIS_AVAILABLE_UPDATES_ERROR = `${PREFIX}/GET_LPIS_AVAILABLE_UPDATES_ERROR`;
export const RESET_LPIS_AVAILABLE_UPDATES_RESULTS = `${PREFIX}/RESET_LPIS_AVAILABLE_UPDATES_RESULTS`;

export const CREATE_LPIS_AVAILABLE_UPDATES_REQUEST = `${PREFIX}/CREATE_LPIS_AVAILABLE_UPDATES_REQUEST`;
export const CREATE_LPIS_AVAILABLE_UPDATES_SUCCESS = `${PREFIX}/CREATE_LPIS_AVAILABLE_UPDATES_SUCCESS`;
export const CREATE_LPIS_AVAILABLE_UPDATES_ERROR = `${PREFIX}/CREATE_LPIS_AVAILABLE_UPDATES_ERROR`;
