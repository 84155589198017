import React, { useContext } from "react";

import CloseIcon from "@mui/icons-material/Clear";
import {
  alpha,
  Button,
  Drawer,
  FormLabel,
  Grid,
  IconButton,
  Paper,
  TextField,
  Theme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Field, Form, Formik } from "formik";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { getDeviceConfig } from "../../../../shared/api/irrigation/devices/devices.selectors";

import { updateDeviceConfigApi } from "../../../../shared/api/irrigation/devices/devices.api";
import { Counter } from "../../../../shared/components/common/Counter/Counter";
import { SnackbarContext } from "../../../../shared/containers/SnackbarProvider/SnackbarProvider";

import {
  DeviceConfigFormInitType,
  mapDeviceConfigFormInitialValues,
  mapRequestBodyDeviceConfigTo,
} from "./deviceConfigForm.services";
import { OutputVoltageRadioGroup } from "./OutputVoltageRadioGroup";
import { ValveConfigAccordion } from "./ValveConfigAccordion";

type Props = {
  deviceId: string;
  deviceName: string;
  onCloseClick: () => void;
  open: boolean;
};

const DeviceConfigForm = ({
  deviceId,
  deviceName,
  onCloseClick,
  open,
}: Props) => {
  const classes = useStyles();
  const showSnackbar = useContext(SnackbarContext);

  const dispatch = useDispatch();
  const deviceConfig = useSelector(getDeviceConfig);

  const handleSubmit = async (values: DeviceConfigFormInitType) => {
    const data = mapRequestBodyDeviceConfigTo(deviceId, values);
    try {
      await dispatch(updateDeviceConfigApi(deviceId, data));
      showSnackbar({
        message: <FormattedMessage id="common.changesSaved.success" />,
        isSuccess: true,
      });
    } catch (error) {
      showSnackbar({
        message: <FormattedMessage id="common.changesSaved.success" />,
        isError: true,
      });
    } finally {
      onCloseClick();
    }
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      variant="persistent"
      ModalProps={{
        hideBackdrop: true,
        disableEnforceFocus: true,
      }}
      PaperProps={{
        sx: {
          height: "calc(100% - 60px)",
          top: "unset",
          bottom: 0,
          borderTop: `1px solid ${alpha("#000000", 0.12)}`,
          maxWidth: {
            xs: "100vw",
            sm: 500,
          },
          overflow: "hidden",
          zIndex: 1201,
        },
      }}
    >
      <aside className={classes.drawerForm} role="presentation">
        <div className={classes.sizeWrapper}>
          <div className={classes.header}>
            <IconButton
              aria-label="close"
              onClick={onCloseClick}
              size="small"
              style={{ marginRight: "auto" }}
            >
              <CloseIcon />
            </IconButton>
            <span className={classes.headerText}>
              <FormattedMessage id="Irrigation.deviceDetail.editDevice" />
            </span>
          </div>
          <Formik
            enableReinitialize
            initialValues={mapDeviceConfigFormInitialValues(deviceConfig)}
            onSubmit={handleSubmit}
            validateOnBlur={false}
            validateOnChange={false}
          >
            {({ setFieldValue, values }) => {
              const handleChangeOutputVoltage = (
                event: React.ChangeEvent<HTMLInputElement>,
              ) => {
                setFieldValue(
                  "outputVoltage",
                  Number(event.target.defaultValue),
                );
              };

              const handleChangePulseLength = (count: number) => {
                setFieldValue("pulseLengthMs", count);
              };

              return (
                <Form className={classes.form}>
                  <Grid container direction="column" spacing={3}>
                    <Grid item>
                      <Paper className={classes.paper}>
                        <Grid container justifyContent="space-between">
                          <Grid item>ID</Grid>
                          <Grid className={classes.boldText} item>
                            {deviceId}
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                    <Grid item>
                      <FormLabel>
                        <FormattedMessage id="Irrigation.createArea.form.deviceName" />
                      </FormLabel>
                      <Paper className={classes.paper}>
                        <span className={classes.boldText}>{deviceName}</span>
                      </Paper>
                    </Grid>
                    <Grid item>
                      <OutputVoltageRadioGroup
                        defaultValues={values.outputVoltage}
                        onChange={handleChangeOutputVoltage}
                      />
                    </Grid>
                    <Grid item>
                      <Counter
                        countStep={50}
                        defaultValue={values.pulseLengthMs}
                        labelId="Irrigation.deviceDetail.config.pulseLength"
                        onChange={handleChangePulseLength}
                        unit="ms"
                      />
                    </Grid>
                    <Grid item>
                      {values.valveConfig.map((valve, index) => {
                        const handleChangeValveForceRepetitions = (
                          count: number,
                        ) => {
                          setFieldValue(
                            `valveConfig[${index}].forceRepetitions`,
                            count,
                          );
                        };

                        const handleChangeValveForceIntervalMs = (
                          count: number,
                        ) => {
                          setFieldValue(
                            `valveConfig[${index}].forceIntervalMs`,
                            count,
                          );
                        };

                        const handleChangeValveSwitch = (checked: boolean) => {
                          setFieldValue(
                            `valveConfig[${index}].enabled`,
                            checked,
                          );
                        };

                        const handleChangeValveNotes = (
                          event: React.ChangeEvent<HTMLInputElement>,
                        ) => {
                          setFieldValue(
                            `valveConfig[${index}].notes`,
                            event.target.value,
                          );
                        };

                        return (
                          <ValveConfigAccordion
                            checked={valve.enabled}
                            key={index}
                            onChangeSwitch={handleChangeValveSwitch}
                            header={
                              <span>
                                <FormattedMessage id="IrrigationList.valve" />{" "}
                                {valve.valveNumber}
                              </span>
                            }
                          >
                            <Grid container direction="column" spacing={3}>
                              <Grid item>
                                <Counter
                                  defaultValue={valve.forceRepetitions}
                                  labelId="Irrigation.deviceDetail.config.forceRepetitions"
                                  onChange={handleChangeValveForceRepetitions}
                                />
                              </Grid>
                              <Grid item>
                                <Counter
                                  countStep={50}
                                  defaultValue={valve.forceIntervalMs}
                                  labelId="Irrigation.deviceDetail.config.forceIntervalMs"
                                  onChange={handleChangeValveForceIntervalMs}
                                  unit="ms"
                                />
                              </Grid>
                              <Grid container direction="column" item>
                                <FormLabel>
                                  <FormattedMessage id="common.note" />
                                </FormLabel>
                                <Field
                                  component={TextField}
                                  defaultValue={valve.notes}
                                  maxRows={4}
                                  multiline={true}
                                  onChange={handleChangeValveNotes}
                                  variant="outlined"
                                />
                              </Grid>
                            </Grid>
                          </ValveConfigAccordion>
                        );
                      })}
                    </Grid>
                  </Grid>
                  <Button
                    color="primary"
                    id="update"
                    type="submit"
                    variant="contained"
                  >
                    <FormattedMessage id="common.save" />
                  </Button>
                </Form>
              );
            }}
          </Formik>
        </div>
      </aside>
    </Drawer>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  drawerForm: {
    width: 500,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    background: "#F7F7F7",
  },
  sizeWrapper: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    maxWidth: "100vw",
  },
  header: {
    background: theme.palette.common.white,
    display: "flex",
    alignItems: "center",
  },
  headerText: {
    fontSize: 24,
    fontWeight: 500,
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    justifyContent: "space-between",
    padding: 24,
    overflow: "scroll",
  },
  paper: {
    padding: 15,
    borderRadius: 8,
  },
  boldText: {
    fontWeight: 500,
  },
}));

export { DeviceConfigForm };
