import React from "react";

import { makeStyles } from "@mui/styles";
import { useIntl } from "react-intl";

import {
  FarmUpdateChangeRequestStatus,
  FarmUpdateChangesResponse,
} from "../../../generated/api/agroevidence";
import CfLoader from "../../../shared/components/common/CfLoader/CfLoader";

interface IUpdateLpisAvailableUpdatesDialogProps {
  availableUpdates: FarmUpdateChangesResponse | null;
}
export const UpdateLpisRenderStatusAvailableUpdates = ({
  availableUpdates,
}: IUpdateLpisAvailableUpdatesDialogProps) => {
  const intl = useIntl();
  const classes = useStyles();
  if (!availableUpdates) return null;
  if (
    availableUpdates &&
    availableUpdates.status === FarmUpdateChangeRequestStatus.IN_PROGRESS
  ) {
    return (
      <>
        <div className={classes.loading}>
          <CfLoader />
        </div>
        <div>
          {intl.formatMessage({
            id: "UpdateLPIS.availableUpdate.inProgress",
          })}
        </div>
      </>
    );
  }

  return null;
};

const useStyles = makeStyles(() => ({
  loading: {
    margin: "10px 0px",
  },
}));
