import React from "react";
import { makeStyles } from "@mui/styles";

import {
  DashboardParcelTo,
  DashboardSeasonTo,
  InternalCropTo,
} from "../../../../../generated/api/satellite";

import { SelectObject } from "../../../../../shared/components/form/FormSelect/types";
import CfLoader from "../../../../../shared/components/common/CfLoader/CfLoader";
import { useUpdateSeasonParcelMutation } from "../../../../routes/dashboard/ParcelDashboard.api";
import { SeasonCellIconBar } from "./SeasonCellIconBar";

import { useDelayedLoader } from "../../../../hooks/useDelayedLoader";
import { CropCell } from "../crops/CropCell";
import { YieldCell } from "../yield/YieldCell";

type Props = {
  parcel: DashboardParcelTo;
  cropsOptions: SelectObject<InternalCropTo>[];
  season: DashboardSeasonTo;
  hasAssignedParcel: boolean;
  queryKey: (string | number | string[] | undefined)[];
};

const SeasonCell = ({
  cropsOptions,
  parcel,
  season,
  hasAssignedParcel,
  queryKey,
}: Props) => {
  const classes = useStyles();
  const loaderClasses = useLoaderStyles();

  const seasonParcelMutation = useUpdateSeasonParcelMutation({
    queryKey,
    parcelId: parcel.parcelId,
    seasonId: season.seasonId,
  });

  const { isDelayed } = useDelayedLoader(seasonParcelMutation.isPending, 600);

  let Jsx = null;

  if (isDelayed) {
    Jsx = <CfLoader size={32} classes={loaderClasses} />;
  } else {
    Jsx = (
      <>
        <CropCell
          seasonParcelMutation={seasonParcelMutation}
          parcel={parcel}
          season={season}
          cropsOptions={cropsOptions}
        />
        <YieldCell
          seasonParcelMutation={seasonParcelMutation}
          parcel={parcel}
          season={season}
        />
      </>
    );
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>{Jsx}</div>
      {hasAssignedParcel && (
        <SeasonCellIconBar data={parcel} products={parcel.products ?? []} />
      )}
    </div>
  );
};

const useStyles = makeStyles(() => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
  },
  header: {
    width: "100%",
  },
}));

const useLoaderStyles = makeStyles(() => ({
  wrapper: {
    justifyContent: "flex-start",
  },
}));

export { SeasonCell };
