import React, { ReactNode } from "react";

import { Theme } from "@mui/material";
import Table from "@mui/material/Table";
import { makeStyles } from "@mui/styles";
import classnames from "classnames";

interface Props {
  children: ReactNode;
  customClasses?: Record<string, string>;
  minWidth?: number;
  testId?: string;
}

const CfTableWrapperV2 = ({
  children,
  customClasses,
  minWidth = -1,
  testId = "table",
}: Props) => {
  const classes = useStyles({ minWidth });

  return (
    <div className={classnames(classes.wrapper, customClasses?.wrapper)}>
      <Table
        className={classnames(classes.table, customClasses?.table)}
        data-test={testId}
      >
        {children}
      </Table>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    overflowX: "auto",
  },
  table: {
    minWidth: ({ minWidth }: { minWidth: number }) =>
      minWidth >= 0 ? minWidth : `${theme.breakpoints.values.sm}px`,
    position: "relative",
    borderCollapse: "initial",
  },
}));

export { CfTableWrapperV2 };
